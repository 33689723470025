import {
  MutationTrigger,
  UseMutation,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';

import { useGetInvestorDataMutation, useGetSchemeDetailsMutation } from '../../slice';
import { DetailType, Filter } from './types';

interface Details {
  getHook(): UseMutation<any>;
  getResponse(api: MutationTrigger<any>, requestData: any): Promise<any>;
  getTableData(data: any, columns: string[], filter: Filter): [any[], Filter[]];
  createSubmissionData(apiData: any, ids: (string | number)[]): any;
}

class SchemeDetails implements Details {
  getHook(): UseMutation<any> {
    return useGetSchemeDetailsMutation;
  }

  async getResponse(schemeApi: MutationTrigger<any>, requestData: any) {
    return schemeApi({ requestData });
  }

  getTableData(data: any, columns: string[], filter: Filter): [any[], Filter[]] {
    const tableData = [];
    const emailPhoneCombination = new Set<string>();
    if (data?.length) {
      for (const scheme of data) {
        if (!scheme.idcwChangeAllowed) continue;
        if (filter.schemeOption && scheme.schemeOption !== filter.schemeOption) {
          continue;
        }
        emailPhoneCombination.add(
          JSON.stringify({
            email: scheme.email,
            mobile: scheme.mobile,
          }),
        );
        if (
          filter.amc &&
          !scheme.amcName.toLowerCase().includes(filter.amc.toLowerCase())
        ) {
          continue;
        } else if (
          !(
            (!filter.email || scheme.email === filter.email) &&
            (!filter.mobile || scheme.mobile === filter.mobile)
          )
        ) {
          continue;
        }
        const folio: any = {};
        folio['id'] = `${scheme.folio}_${scheme.schemeCode}`;
        if (columns.includes('Folio Number')) {
          folio['Folio Number'] = scheme.folio || 'NA';
        }
        if (columns.includes('Fund House')) {
          folio['Fund House'] = scheme.amcName;
        }
        if (columns.includes('Primary Holder')) {
          folio['Primary Holder'] = scheme.investorName || 'NA';
        }
        if (columns.includes('Mobile Number')) {
          folio['Mobile Number'] = scheme.mobile || 'NA';
        }
        // if (columns.includes('Nominee')) {
        //     rowData['Mode of Holding'] = investorDetail.nominees
        // }
        tableData.push(folio);
      }
    }
    const options: Filter[] = [];
    for (const emailPhone of emailPhoneCombination.values()) {
      options.push(JSON.parse(emailPhone));
    }
    return [tableData, options];
  }

  createSubmissionData(apiData: any, ids: (string | number)[]) {
    const data: any = [];
    for (const scheme of apiData) {
      if (ids.includes(`${scheme.folio}_${scheme.schemeCode}`)) data.push(scheme);
    }
    return data;
  }
}

class InvestorDetails implements Details {
  getHook(): UseMutation<any> {
    return useGetInvestorDataMutation;
  }

  async getResponse(investorApi: MutationTrigger<any>, requestData: any) {
    return investorApi({ requestData });
  }

  getTableData(data: any, columns: string[], filter: Filter): [any[], Filter[]] {
    const tableData = [];
    const emailPhoneCombination = new Set<string>();
    if (data?.length) {
      for (const amc of data) {
        if (
          filter.amc &&
          !amc.amcName?.toLowerCase().includes(filter.amc.toLowerCase())
        ) {
          continue;
        }
        for (const investorDetail of amc.investorDetails) {
          if (filter) {
            if (
              !(
                (!filter.email || investorDetail.email === filter.email) &&
                (!filter.mobile || investorDetail.mobile === filter.mobile)
              )
            ) {
              continue;
            }
          }
          const folio: any = {};
          folio['id'] = investorDetail.folio;
          if (columns.includes('Folio Number')) {
            folio['Folio Number'] = investorDetail.folio || 'Na';
          }
          if (columns.includes('Fund House')) {
            folio['Fund House'] = amc.amcName || 'NA';
          }
          if (columns.includes('Branch')) {
            folio['Branch'] =
              investorDetail.banks.filter((bank: any) => bank.default === 'Y')[0]
                ?.branch || 'NA';
          }
          if (columns.includes('Primary Holder')) {
            folio['Primary Holder'] = investorDetail.investorName;
          }
          if (columns.includes('Bank Name') || columns.includes('Default Bank Name')) {
            folio['Bank Name'] =
              investorDetail.banks.filter((bank: any) => bank.default === 'Y')[0]?.name ||
              'NA';
          }
          if (columns.includes('Account No.')) {
            folio['Account No.'] =
              investorDetail.banks.filter((bank: any) => bank.default === 'Y')[0]
                ?.accountNo || 'NA';
          }
          if (columns.includes('IFSC') || columns.includes('IFSC Code')) {
            folio['IFSC'] =
              investorDetail.banks
                .filter((bank: any) => bank.default === 'Y')[0]
                ?.ifsc?.toUpperCase() || 'NA';
          }
          if (columns.includes('Mobile Number')) {
            folio['Mobile Number'] = investorDetail.mobile || 'NA';
          }
          if (columns.includes('Mode of Holding')) {
            folio['Mode of Holding'] =
              investorDetail.modeOfHolding === '1' ? 'Single' : 'Any';
          }
          if (columns.includes('Holding')) {
            folio['Holding'] = investorDetail.modeOfHolding === '1' ? 'Single' : 'Any';
          }
          if (columns.includes('Email ID')) {
            folio['Email ID'] = investorDetail.email || 'NA';
          }
          if (columns.includes('Nominee')) {
            folio['Nominee'] = investorDetail.nominees?.length ? 'Yes' : 'No';
            folio['additionalData'] = {
              nominees: investorDetail.nominees,
              dropDownColumn: 'Nominee',
            };
          }
          tableData.push(folio);
          emailPhoneCombination.add(
            JSON.stringify({
              email: investorDetail.email,
              mobile: investorDetail.mobile,
            }),
          );
        }
      }
    }
    const options: Filter[] = [];
    for (const emailPhone of emailPhoneCombination.values()) {
      options.push(JSON.parse(emailPhone));
    }
    return [tableData, options];
  }

  createSubmissionData(apiData: any, ids: (string | number)[]) {
    const data: any = [];
    for (const amc of apiData) {
      for (const investorDetail of amc.investorDetails) {
        if (ids.includes(investorDetail.folio))
          data.push({ amcName: amc.amcName, amcId: amc.amc, ...investorDetail });
      }
    }
    return data;
  }
}

class InvestorBankDetails implements Details {
  getHook(): UseMutation<any> {
    return useGetInvestorDataMutation;
  }

  async getResponse(investorApi: MutationTrigger<any>, requestData: any) {
    return investorApi({ requestData });
  }

  getTableData(data: any, columns: string[], filter: Filter): [any[], Filter[]] {
    const tableData = [];
    const emailPhoneCombination = new Set<string>();
    if (data?.length) {
      for (const amc of data) {
        if (filter.amc && !amc.amcName.toLowerCase().includes(filter.amc.toLowerCase())) {
          continue;
        }
        for (const investorDetail of amc.investorDetails) {
          if (filter) {
            if (
              !(
                (!filter.email || investorDetail.email === filter.email) &&
                (!filter.mobile || investorDetail.mobile === filter.mobile)
              )
            ) {
              continue;
            }
          }
          for (const bank of investorDetail.banks || []) {
            if (filter.ifsc && bank.ifsc) {
              continue;
            }
            const folio: any = {};
            folio['id'] = `${investorDetail.folio}_${bank.accountNo}`;
            if (columns.includes('Folio Number')) {
              folio['Folio Number'] = investorDetail.folio || 'NA';
            }
            if (columns.includes('Fund House')) {
              folio['Fund House'] = amc.amcName || 'NA';
            }
            if (columns.includes('Branch')) {
              folio['Branch'] = bank.branch || 'NA';
            }
            if (columns.includes('Primary Holder')) {
              folio['Primary Holder'] = investorDetail.investorName || 'NA';
            }
            if (columns.includes('Bank Name') || columns.includes('Default Bank Name')) {
              folio['Bank Name'] = bank.name || 'NA';
            }
            if (columns.includes('IFSC')) {
              folio['IFSC'] =
                bank.ifsccode?.toUpperCase() || bank.ifsc?.toUpperCase() || 'NA';
            }
            if (columns.includes('Account No.')) {
              folio['Account No.'] = bank.accountNo || 'NA';
            }
            if (columns.includes('Mobile Number')) {
              folio['Mobile Number'] = investorDetail.mobile || 'NA';
            }
            if (columns.includes('Mode of Holding')) {
              folio['Mode of Holding'] =
                investorDetail.modeOfHolding === '1' ? 'Single' : 'Any';
            }
            if (columns.includes('Holding')) {
              folio['Holding'] = investorDetail.modeOfHolding === '1' ? 'Single' : 'Any';
            }
            if (columns.includes('Email ID')) {
              folio['Email ID'] = investorDetail.email || 'NA';
            }
            tableData.push(folio);
          }
          emailPhoneCombination.add(
            JSON.stringify({
              email: investorDetail.email,
              mobile: investorDetail.mobile,
            }),
          );
        }
      }
    }
    const options: Filter[] = [];
    for (const emailPhone of emailPhoneCombination.values()) {
      options.push(JSON.parse(emailPhone));
    }
    return [tableData, options];
  }

  createSubmissionData(apiData: any, ids: (string | number)[]) {
    const data: any = [];
    for (const amc of apiData) {
      for (const investorDetail of amc.investorDetails) {
        for (const bank of investorDetail.banks || []) {
          if (ids.includes(`${investorDetail.folio}_${bank.accountNo}`))
            data.push({
              amcName: amc.amcName,
              amcId: amc.amc,
              folio: investorDetail.folio,
              bank,
            });
        }
      }
    }
    return data;
  }
}

export class DetailsFactory {
  createDetail(type: DetailType): Details {
    switch (type) {
      case DetailType.Scheme:
        return new SchemeDetails();
      case DetailType.Investor:
        return new InvestorDetails();
      case DetailType.InvestorBank:
        return new InvestorBankDetails();
      default:
        throw new Error('Invalid Detail Type');
    }
  }
}
