export const APL_MODE_TYPES = [
  { label: 'Lumpsum', value: 'Lumpsum' },
  { label: 'SIP', value: 'SIP' },
  { label: 'Lumpsum + SIP', value: 'LumpsumAndSIP' },
];

function convertDate(dateString: string): string {
  return new Date(dateString).toISOString().split('T')[0];
}
export function filterMandatesForLumpsum(sipAmount: number, mandate: any): boolean {
  if (!mandate) return false;
  const { amount, fromDate, toDate } = mandate;

  if (amount < sipAmount) return false;

  return (
    new Date(convertDate(fromDate)).getTime() <= new Date().getTime() &&
    new Date(convertDate(toDate)).getTime() > new Date().getTime()
  );
}

export const filterPaymentModesForLumpsum = (allPaymentOptions: any) => {
  return allPaymentOptions.filter((method: any) => method.value !== 'ISIP');
};

export const initialRegistrationModesForSIP = (
  registrationModes: any[],
  isPanBasedAOTM: boolean,
) => {
  const data = registrationModes
    .filter(({ value }: { value: string }) => {
      if (isPanBasedAOTM) {
        return true;
      }
      // For folio 'NA'
      return value !== 'AOTM';
    })
    .filter(({ value }: { value: string }) => {
      return value !== 'netBanking' && value !== 'upi';
    });
  return data;
};
// For confirm page when registration mode is preselected
export const filterRegistrationMethodsSIP = (
  registrationModes: any[],
  isPanBasedAOTM: boolean,
  selectedRegistrationMode: string,
  isNri: boolean,
) => {
  return registrationModes
    .filter(({ value }: { value: string }) => {
      if (isPanBasedAOTM) {
        return true;
      }
      // For folio 'NA'
      return value !== 'AOTM';
    })
    .filter(({ value }: { value: string }) => {
      return value !== 'netBanking' && value !== 'upi';
    })
    .filter(({ value }: { value: string }) => {
      if (isNri) return true;
      if (selectedRegistrationMode === 'ISIP') return true;
      if (selectedRegistrationMode.toLowerCase() === value) {
        return true;
      } else {
        return false;
      }
    });
};

export const removeKotmFromPaymentModes = (paymentModes: any[]) => {
  return paymentModes.filter(({ value }: { value: string }) => {
    return value !== 'kotm';
  });
};
export const areAllTheValuesEmpty = (values: any) =>
  !(values.sipDay && values.installments && values.frequency);
