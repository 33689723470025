export interface AssetWiseBreakup {
  type: string;
  percentage: number;
  aum: number;
}

export interface AmcWiseBreakup {
  fund: string;
  percentage: number | null;
  aum: number | null;
  fundName: string;
}

export interface AllAumDetails {
  aum: number;
  numberOfSchemes: number;
  totalInvestors: number;
  amcWiseBreakup: AmcWiseBreakup[];
  assetWiseBreakup: AssetWiseBreakup;
}

export interface Data {
  id: number;
  name: string;
  age: number;
  email: string;
}
export enum COLUMNS_AMC_WISE {
  fundName = 'AMC',
  percentage = 'Percentage %',
  aum = 'INR',
}
