import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Investorcard } from '../auth/login/styles/styledComponents';
import { PageNavigator } from '../common/BackToHomePage';
import { startLoading, stopLoading } from '../common/Loader/loaderSlice';
import { useRootContext } from '../data/root.context';
import { useLazyAllFaqsQuery } from './slice';

interface Faq {
  question: string;
  answer: string;
}

const cleanHtml = (html: string): string => {
  const cleanedHtml = html?.replace(/<p>/g, '')?.replace(/<\/p>/g, '');
  const parts = cleanedHtml?.split(/(<\/?ol>)/);
  let result = '';
  let isList = false;
  let counter = 0;

  parts?.forEach(part => {
    if (part === '<ol>') {
      isList = true;
      counter = 0;
      result += '<div class="custom-list">';
    } else if (part === '</ol>') {
      isList = false;
      result += '</div>';
    } else if (isList) {
      result += part
        ?.replace(/<li>/g, () => {
          const label = String.fromCharCode(97 + counter++);
          return `<div class="custom-list-item"><span class="custom-list-label">${label}.</span> `;
        })
        ?.replace(/<\/li>/g, '</div>');
    } else {
      result += part;
    }
  });

  return result;
};

const accordionStyles = (theme: Theme, expanded: boolean) => ({
  'position': 'relative',
  'p': { xs: '5px 5px 0px 5px', sm: '10px' },
  'paddingLeft': expanded ? '0px' : '5px',
  'borderLeft': expanded ? `5px solid ${theme.palette.primary.main}` : 'unset',
  'backgroundColor': expanded ? `${theme.palette.text.boxColor}` : 'common.white',
  '&:not(:last-child)': {
    borderBottom: `0.5px solid ${theme.palette.text.borderColorLight}`,
  },
  '&:before': {
    display: 'none',
  },
  'margin': 0,
  '&.Mui-expanded': {
    margin: 0,
  },
  '& .MuiTypography-root': {
    color: expanded ? `${theme.palette.primary.main}` : `${theme.palette.text.primary}`,
  },
  '&:first-of-type': {
    borderTopLeftRadius: expanded ? '0px' : '8px',
    borderTopRightRadius: expanded ? '0px' : '8px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: expanded ? '0px' : '8px',
    borderBottomRightRadius: expanded ? '0px' : '8px',
    mb: 2,
  },
  '& .MuiAccordionDetails-root': {
    fontWeight: 400,
    fontSize: '14px',
    color: `common.black`,
  },
});

function Faqs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useRootContext();
  const [expanded, setExpanded] = useState<number | false>(0);
  const [faqsData, setFaqsData] = useState<Faq[]>([]);

  const [getAllFaqs, faqsList] = useLazyAllFaqsQuery();

  useEffect(() => {
    try {
      dispatch(startLoading());
      getAllFaqs('');
    } catch (error: any) {
      showToast(error?.data?.message, 'error');
    }
  }, []);

  useEffect(() => {
    if (!faqsList.isFetching) {
      dispatch(stopLoading());
    }
    if (faqsList.data) {
      setFaqsData(faqsList.data);
    }
  }, [faqsList.data]);

  const handleBackClick = () => {
    navigate('/transaction-dashboard/dashboard');
  };

  const handleAccordionChange =
    (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? index : false);
    };

  return (
    <>
      <PageNavigator
        title='FAQs'
        backHandler={handleBackClick}
      />
      <Investorcard sx={{ border: 'none', position: 'relative' }}>
        {faqsData?.map((item: Faq, idx: number) => (
          <Accordion
            elevation={0}
            key={idx}
            expanded={expanded === idx}
            onChange={handleAccordionChange(idx)}
            sx={(theme: Theme) => accordionStyles(theme, expanded === idx)}
          >
            <AccordionSummary
              aria-controls={`panel${idx + 1}-content`}
              id={`panel${idx + 1}-header`}
              sx={{
                '& .MuiAccordionSummary-content': { fontSize: '13px', fontWeight: 600 },
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>
                {' '}
                {idx + 1}. {item?.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ ml: '14px', fontSize: '13px', fontWeight: 500 }}
              dangerouslySetInnerHTML={{ __html: cleanHtml(item?.answer) }}
            />
          </Accordion>
        ))}
      </Investorcard>
    </>
  );
}

export default Faqs;
