import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FormControl,
  FormHelperText,
  // FormLabel,
  InputBaseProps,
  InputLabel,
  Theme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useField, useFormikContext } from 'formik';
import { ReactElement } from 'react';

import CalenderIconTmage from '../../../assets/calendar-icon.svg';
import { BootstrapInput } from '../FormTextInput';
function CalendarIcon() {
  return (
    <img
      src={CalenderIconTmage}
      alt='calendar icon'
    />
  );
}

function FormDateInput({
  name,
  label,
  required,
  shouldDisableDate,
  disableFuture,
  disablePast,
  minDate,
  maxDate,
  defaultCalendarMonth,
  viewOnly = false,
  ...rest
}: InputBaseProps & {
  name: string;
  label?: string | ReactElement;
  shouldDisableDate?: (date: Date) => boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  minDate?: Date;
  maxDate?: Date;
  defaultCalendarMonth?: Date;
  viewOnly?: boolean;
}): JSX.Element {
  const formikContext = useFormikContext<any>();
  const {
    isSubmitting: disabled,
    setFieldValue,
    values,
    setFieldTouched,
  } = formikContext;
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          inputFormat='DD/MM/YYYY'
          value={field.value}
          shouldDisableDate={shouldDisableDate}
          // disableFuture={name === 'swpDate' ? false : true}
          disableFuture={disableFuture}
          disabled={viewOnly}
          disablePast={disablePast}
          minDate={minDate}
          maxDate={maxDate}
          defaultCalendarMonth={dayjs(defaultCalendarMonth)}
          onChange={(val: any) => {
            if (val?.$d) {
              // Check if the value is valid
              setFieldValue(name, val?.$d);
              rest.onChange?.(val?.$d);
            } else {
              setFieldValue(name, '');
            }
          }}
          renderInput={({ inputRef, inputProps, InputProps }: any) => (
            <FormControl
              error={hasError}
              variant='filled'
              fullWidth
              margin='dense'
            >
              <InputLabel
                sx={(theme: Theme) => ({
                  'color': 'text.labelColor',
                  'fontSize': '14px',
                  'fontWeight': '500',
                  'top': '2px',
                  'left': '6px',
                  '&.Mui-focused': {
                    color: 'text.labelColor',
                  },
                  [theme.breakpoints.between(768, 1200)]: {
                    fontSize: '13px',
                  },
                })}
              >
                {label}
                {required && <>&nbsp;*</>}
              </InputLabel>
              <BootstrapInput
                sx={{
                  'border': '1.5px solid',
                  'borderColor': 'text.borderColorLight',
                  'borderRadius': '8px',
                  'paddingRight': '16px',
                  'backgroundColor': 'common.white',
                  '&.Mui-disabled': {
                    bgcolor: 'text.lightGray',
                  },
                  '& .MuiInputBase-input': {
                    border: 'none',
                    padding: '16px 16px 0px 16px',
                  },
                }}
                disabled={rest.disabled || disabled}
                onBlur={async () => setFieldTouched(name, true, true)}
                ref={inputRef}
                id={`${name}-input`}
                autoComplete='off'
                {...inputProps}
                {...InputProps}
                {...rest}
              />
              <FormHelperText
                style={{ color: 'error.main', fontSize: '11px', fontWeight: '400' }}
              >
                {hasError && (
                  <InfoOutlinedIcon
                    sx={{ fontSize: '16px', verticalAlign: 'middle', mr: 0.5 }}
                    color='error'
                  />
                )}
                {errorText}
              </FormHelperText>
            </FormControl>
          )}
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
        />
      </LocalizationProvider>
    </>
  );
}

export default FormDateInput;
