export function capitalizeFirstLetter(string: string) {
  if (!string) return string; // Return the original string if it's falsy (null or undefined)
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function removeSpaces(str: string) {
  return str.replace(/\s+/g, '');
}

export const capitalizeWords = (input: any): string => {
  if (typeof input !== 'string' || !input) {
    return '';
  }
  return input.replace(/\b\w/g, char => char.toUpperCase());
};
