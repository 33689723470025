import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TablePagination,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SearchIconMobile } from '../../../../../assets/search-icon-mobile.svg';
import { DetailsHeader } from '../../../../auth/login/styles/styledComponents';
import { PageNavigator } from '../../../../common/BackToHomePage';
import CustomLoader from '../../../../common/CustomLoader';
import { CustomSearch } from '../../../../common/search';
import { useRootContext } from '../../../../data/root.context';
import { useLazyGetInvestorsQuery } from '../../../slice';
import { setReportType } from '../../reportSlice';
import TableContainer from './TableContainer';

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  'padding': theme.spacing(0, 2),
  'height': '100%',
  'position': 'absolute',
  'zIndex': 1,
  'pointerEvents': 'none',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  '& svg': {
    [theme.breakpoints.between(768, 1024)]: {
      width: '14px',
    },
  },
}));
export const SearchBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'auto',
}));
export const SearchIconContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
export default function InvestorSelection() {
  const { showToast } = useRootContext();
  const navigate = useNavigate();
  const [searchCriteria, setSearchCriteria] = useState('Name');
  const [searchText, setSearchText] = useState('');
  const [investors, setInvestors] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayLoader, setDisplayLoader] = useState(false);

  const [getInvestorDetails, InvestorDetailsList] = useLazyGetInvestorsQuery();

  useEffect(() => {
    try {
      getInvestorDetails('');
    } catch (error: any) {
      showToast(error?.data?.message, 'error');
    }
  }, []);

  useEffect(() => {
    setDisplayLoader(true);
    if (InvestorDetailsList.data && InvestorDetailsList.data.invester) {
      const result = InvestorDetailsList.data.invester;
      setInvestors(result);
    }
    setDisplayLoader(false);
  }, [InvestorDetailsList.data]);

  const handleSearchTextChanged = (text: string) => {
    setSearchText(text);
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const filteredInvestors = investors.filter(investor => {
    switch (searchCriteria) {
      case 'Name':
        return investor?.name?.toLowerCase().includes(searchText.toLowerCase());
      case 'PAN':
        return investor?.pan?.toLowerCase().includes(searchText.toLowerCase());
      case 'Mobile Number':
        return investor?.mobile?.includes(searchText);
      case 'Email':
        return investor?.email?.toLowerCase().includes(searchText.toLowerCase());
      default:
        return true;
    }
  });

  return (
    <>
      <PageNavigator
        title='Investor Selection'
        backHandler={() => {
          dispatch(setReportType('Investor'));
          navigate(-1);
        }}
        sx={(theme: Theme) => ({
          [theme.breakpoints.down('md')]: {
            mt: 0,
          },
        })}
      />
      {/*Mobile UI */}
      <Box
        sx={{
          backgroundColor: 'text.boxColor',
          padding: '20px 16px',
          mx: '-20px',
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          // justifyContent="space-between"
          mb='15px'
        >
          <DetailsHeader sx={{ fontSize: '14px' }}>Search by</DetailsHeader>
          <Box sx={{ display: 'flex', ml: '10px' }}>
            {['Name', 'PAN', 'Mobile', 'Email'].map((label, index) => {
              return (
                <Chip
                  sx={(theme: Theme) => ({
                    'border': `1px solid ${theme.palette.text.borderColorLight}`,
                    'mx': 0.5,
                    // background: 'linear-gradient(90deg, #215EAB 0%, #0FD1D7 100%)',
                    'background': 'transparent',
                    '& .MuiChip-label': {
                      color: theme.palette.text.primary,
                      fontSize: '12px',
                      fontWeight: '500',
                      padding: '8px 15px',
                      textAlign: 'center',
                      // color:'common.white',
                    },
                  })}
                  key={index}
                  label={label}
                  // onClick={handleClick}
                />
              );
            })}
          </Box>
        </Stack>
        <SearchBox>
          <SearchIconContainer>
            <SearchIconMobile />
          </SearchIconContainer>
          <TextField
            id='filled-basic'
            variant='filled'
            placeholder='Search By Mobile'
            fullWidth
            sx={(theme: Theme) => ({
              '& .MuiInputBase-root ': {
                'color': 'inherit',
                'backgroundColor': theme.palette.common.white,
                'borderTopLeftRadius': '8px',
                'borderTopRightRadius': '8px',
                '& .MuiInputBase-input': {
                  padding: '15px 12px 15px 40px',
                  backgroundColor: theme.palette.common.white,
                  borderRadius: '8px 8px 0px 0px',
                  fontWeight: '500',
                  fontSize: '14px',
                  color: theme.palette.text.labelColor,
                  border: `1px solid ${theme.palette.text.borderColorLight}`,
                },
              },
            })}
          />
        </SearchBox>
      </Box>
      {/*Mobile UI */}
      <Paper
        sx={(theme: Theme) => ({
          'display': { xs: 'none', sm: 'block' },
          'width': '100%',
          'mb': 2,
          'borderRadius': '15px',
          'border': '0.3px solid',
          'borderColor': 'text.borderColorDark',
          'background': theme.palette.common.white,
          'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
          '& .MuiButtonBase-root.MuiRadio-root': {
            'color': 'text.darkGrayBlue',
            'padding': '15px 8px',
            '& .MuiSvgIcon-root': {
              width: '20px',
              height: '20px',
              [theme.breakpoints.down('sm')]: {
                width: '10px',
                height: '20px',
              },
            },
            '&.Mui-checked': {
              'color': 'primary.main',
              '& + .MuiTypography-root': {
                color: 'primary.main',
              },
            },
          },
        })}
      >
        <Box
          sx={{
            padding: { sm: '16px 16px', lg: '16px 25px' },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              columnGap: { xs: '0px', sm: '10px' },
              flex: 'none',
            }}
          >
            <Typography
              sx={(theme: Theme) => ({
                color: 'text.labelColor',
                fontSize: '16px',
                [theme.breakpoints.down('xl')]: {
                  fontSize: '14px',
                },
              })}
            >
              Search By
            </Typography>
            <FormControl>
              <RadioGroup
                sx={{ columnGap: '10px' }}
                row
                value={searchCriteria}
                onChange={e => {
                  setSearchText('');
                  setSearchCriteria(e.target.value);
                }}
              >
                {['Name', 'PAN', 'Mobile Number', 'Email'].map((item, index) => (
                  <FormControlLabel
                    sx={(theme: Theme) => ({
                      '& .MuiTypography-root': {
                        fontSize: '16px',
                        color: 'text.labelColor',
                        fontWeight: 500,
                        [theme.breakpoints.down('xl')]: {
                          fontSize: '14px',
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: '12px',
                        },
                      },
                    })}
                    key={index}
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Stack>
          <CustomSearch
            value={searchText}
            placeholder={`Search by ${searchCriteria}`}
            onChange={handleSearchTextChanged}
            searchCriteria={searchCriteria}
          />
        </Box>
        {filteredInvestors.length !== 0 ? (
          <TableContainer
            data={filteredInvestors}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        ) : (
          <Box
            sx={{
              display: displayLoader ? 'none' : 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              my: 3,
            }}
          >
            No Data found
          </Box>
        )}
        {displayLoader && <CustomLoader display={displayLoader} />}
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component='div'
          count={investors?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
