import AES from 'crypto-js/aes';
import CryptoJSCore from 'crypto-js/core';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import Pkcs7 from 'crypto-js/pad-pkcs7';
import Rp from 'sha.js';

const encryptionkey = `${import.meta.env.VITE_ENCRYPT_KEY || ''}`;
const iv = Utf8.parse('globalaesvectors');

export function encryptData(data: string) {
  const hashstring = Rp('sha256').update(encryptionkey).digest('hex');
  const hashsubstring = hashstring.substring(0, 32);
  const n = Utf8.parse(hashsubstring);
  const l = AES.encrypt(Utf8.parse(data), n, {
    keySize: 16,
    iv: iv,
    mode: CryptoJSCore.mode.CBC,
    padding: Pkcs7,
  });
  return l.ciphertext.toString(Base64).split('+').join('-').split('/').join('_');
}

export function decryptData(data: string) {
  const hashstring = Rp('sha256').update(encryptionkey).digest('hex');
  const hashsubstring = hashstring.substring(0, 32);
  const n = Utf8.parse(hashsubstring);
  const i = data.split('-').join('+').split('_').join('/');
  const decryptedBodyString = AES.decrypt(i, n, {
    keySize: 16,
    iv: iv,
    mode: CryptoJSCore.mode.CBC,
    padding: Pkcs7,
  }).toString(Utf8);
  return JSON.parse(decryptedBodyString);
}
