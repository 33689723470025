import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer as MainTable,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { investorDetails } from '../../../../features/transactions/transactionSlice';
import { StepperNextButton } from '../../../../onboarding/styles/styledComponents';

function TableHeader() {
  return (
    <TableHead
      sx={(theme: Theme) => ({
        '& .MuiTableCell-root.MuiTableCell-head': {
          fontSize: '15px',
          color: 'text.labelColor',
          fontWeight: '500',
          textAlign: 'left',
          [theme.breakpoints.between(768, 1024)]: {
            fontSize: '13px',
          },
        },
      })}
    >
      <TableRow
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.action.hover,
        })}
      >
        <TableCell>Name</TableCell>
        <TableCell>PAN</TableCell>
        <TableCell>Mobile Number</TableCell>
        <TableCell>Email</TableCell>
        <TableCell colSpan={3} />
      </TableRow>
    </TableHead>
  );
}

interface TableContainerProps {
  data: any[];
  page: number;
  rowsPerPage: number;
}

export default function TableContainer({ data, page, rowsPerPage }: TableContainerProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { route } = location.state;

  const handleNewTransaction = () => {
    switch (route) {
      case 'soa':
        return navigate('../soa');
      case 'consolidate-account-statement':
        return navigate('../consolidate-account-statement');
      case 'elss-account-statement':
        return navigate('../elss-account-statement');
      case 'can-based-account-statement':
        return navigate('../can-based-account-statement');
      case 'capital-gain-statement':
        return navigate('../capital-gain-statement');
      case 'grandfathering-account-statement':
        return navigate('../grandfathering-account-statement');
      case 'networth-report':
        return navigate('../networth-report');
      case 'consolidated-capital-gains-statement':
        return navigate('../consolidated-capital-gains-statement');
      default:
        return null;
    }
  };

  return (
    <MainTable
      component={Paper}
      sx={{ boxShadow: 'unset', borderRadius: 'unset', bgcolor: 'unset' }}
    >
      <Table
        sx={{
          '& .MuiTableCell-root ': {
            padding: '16px 16px',
          },
        }}
      >
        <TableHeader />
        <TableBody
          sx={{
            '&.MuiTableBody-root': {
              '& .MuiTableRow-root': {
                'backgroundColor': 'common.white',
                'cursor': 'pointer',
                '& .MuiTableCell-root ': {
                  color: 'text.primary',
                  fontSize: '14px',
                  fontWeight: '400',
                  textAlign: 'left',
                },
              },
            },
          }}
        >
          {data
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((item: any, index: any) => {
              return (
                <TableRow
                  key={index}
                  sx={(theme: Theme) => ({
                    'borderBottom': `1px solid ${theme.palette.text.borderColorDark}`,
                    'backgroundColor': theme.palette.common.white,
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  })}
                >
                  <TableCell>{item.name || 'NA'}</TableCell>

                  <TableCell>{item.pan || 'NA'}</TableCell>

                  <TableCell>{item.mobile || 'NA'}</TableCell>

                  <TableCell>{item.email || 'NA'}</TableCell>
                  <TableCell>
                    <StepperNextButton
                      sx={{ padding: '6px 8px' }}
                      onClick={() => {
                        dispatch(investorDetails(item));
                        handleNewTransaction();
                      }}
                    >
                      New&nbsp;Transaction
                    </StepperNextButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </MainTable>
  );
}
