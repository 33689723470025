// loaderSlice.js
import { createSlice } from '@reduxjs/toolkit';

export interface LoadingResponse {
  loading: boolean;
}

const initialState: LoadingResponse = {
  loading: false,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    resetLoadingState: () => initialState,
  },
});

// Export actions
export const { startLoading, stopLoading, toggleLoading, resetLoadingState } =
  loaderSlice.actions;

// Export reducer
export default loaderSlice.reducer;
