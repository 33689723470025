export const dateToMMDDYYYY = (dateString: any): string => {
  const date = new Date(dateString || new Date());
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear().toString();

  return `${month}/${day}/${year}`;
};

export const dateToDDMMYYYY = (dateString: any, placeholder: string = '/'): string => {
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear().toString();

  return `${day}${placeholder}${month}${placeholder}${year}`;
};

export function mapFrequencyToLetter(frequency: string) {
  switch (frequency) {
    case 'Daily':
      return 'D';
    case 'Fortnightly':
      return 'F';
    case 'Monthly':
      return 'M';
    case 'Quarterly':
      return 'Q';
    case 'Weekly':
      return 'W';
    case 'DailyZip':
      return 'DZ';
    case 'Halfyearly':
      return 'H';
    case 'Yearly':
      return 'Y';
    default:
      return '';
  }
}

export function getFirstLetter(str: string) {
  if (typeof str === 'string' && str.length > 0) {
    return str.charAt(0);
  }
  return '';
}

export function mapFrequency(frequency: string) {
  switch (frequency) {
    case 'D':
      return 'Daily';

    case 'F':
      return 'Fortnightly';
    case 'M':
      return 'Monthly';
    case 'Q':
      return 'Quarterly';
    case 'W':
      return 'Weekly';
    case 'Y':
      return 'Yearly';
    case 'DZ':
      return 'DailyZip';
    case 'H':
      return 'Halfyearly';
    default:
      return '';
  }
}

export const removeLeadingZero = (str: any) => {
  if (!str || typeof str !== 'string') {
    return '';
  }
  return str.replace(/^0+/, '');
};
