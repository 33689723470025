import { Box, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Alert_Icon } from '../../assets/alert-icon.svg';
import { ReactComponent as AddIcon } from '../../assets/rounded-add-icon.svg';
import { useDebounce } from '../../hooks/useDebounce';
import {
  StepperAddButton,
  TransactionHeader,
} from '../auth/login/styles/styledComponents';
import CustomDialog from '../common/AllDialogs/CustomDialog';
import DataTable, { ID } from '../common/DataTable';
import { useRootContext } from '../data/root.context';
import { setTabValue } from '../transactions/reports/reportSlice';
import AddUsersFormContent from './AddUsers/AddUsersFormContent';
import EmployeeSearchAutoComplete from './AutoComplete';
import ResponsiveEmployeeSetup from './ResponsiveBox';
import {
  useDisabledistrubutorMutation,
  useEnabledistrubutorMutation,
  useLazyAdminSearchEmployeesQuery,
  useLazyGetEmployeesQuery,
} from './slice';
import { TeamTableFilter } from './TeamsTableFilter';
import { Employee, TabValuesStates } from './types';

export function NonIndividual() {
  const [selectedEmployee, setSelectedEmployee] = useState<{ [key: string]: any } | null>(
    null,
  );
  const { tabValue }: any = useSelector((state: any) => state.report);
  const [employees, setEmployees] = useState<Employee[] | null>(null);
  const [getEmployees, employeesDetailsValues] = useLazyGetEmployeesQuery();
  const [disableEmployee] = useDisabledistrubutorMutation();
  const [currentStatus, setCurrentStatus] = useState<string>('');
  const [tabValues, setTabValues] = useState<TabValuesStates>({
    tabValueMain: tabValue || '1',
    tabValuesUsers: tabValue || '1',
  });
  const [adminSearchEmployees, adminSearchEmployeesValues] =
    useLazyAdminSearchEmployeesQuery();

  const [totalRowsCount, setTotalRowsCount] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [enableEmployee] = useEnabledistrubutorMutation();
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { showToast } = useRootContext();
  const [query, setQuery] = useState<string>('');
  const isFirstRender = useRef<boolean>(true);
  const mediaQuery = window.matchMedia('(min-width: 600px)');

  const [openAddUsersForm, setOpenAddUsersForm] = useState<boolean>(false);
  // For search field
  const [isFromSearch, setIsFromSearch] = useState<boolean>(false);
  const timeout = 500;
  const allEmployeeCountData = useRef({
    totalEmployees: 0,
    employeeCount: 0,
    subBrokerCount: 0,
  });
  // AutoComplete data to be used in Modal.
  const [autoCompleteData, setAutoCompleteData] = useState<{
    firstInputValue: string;
    secondInputValue: string;
    selectedOption: { label: string; value: string } | null; // Adjusted type
    selectedOption2: { label: string; value: string } | null; // Adjusted type
  }>({
    firstInputValue: '',
    secondInputValue: '',
    selectedOption: null,
    selectedOption2: null,
  });

  const getRows = () => {
    let filteredResponse: Employee[] = [];

    if (isFromSearch) {
      const startIndex = page * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;
      filteredResponse = employees?.slice(startIndex, endIndex) || [];
    } else filteredResponse = employees || [];

    if (Array.isArray(filteredResponse)) {
      return filteredResponse.map(obj => ({
        'Name': obj.name,
        'Role': obj.role,
        'ID': obj.role === 'EMPLOYEE' ? obj.empId : obj.subbrokerCode,
        'Mobile Number': obj.mobile,
        'Email': obj.email,
        'Status': obj.profileStatus,
        'additionalData': { ...obj },
      }));
    } else return [];
  };

  const disableEmployeeHandler = async (id: ID, isSubBroker: boolean) => {
    const response = await disableEmployee({
      employeeId: isSubBroker ? null : id,

      subbrokerCode: isSubBroker ? id : null,
    });

    setSelectedEmployee(null);

    return response;
  };

  const handleSearch = useDebounce(
    async (query: string) => {
      try {
        const queryParams = {
          query: query,
          status: currentStatus || '',
          type:
            tabValues.tabValueMain === '1'
              ? ''
              : tabValues.tabValueMain === '2'
              ? 'EMPLOYEE'
              : 'SUB BROKER',
        };
        adminSearchEmployees(queryParams);
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    },
    timeout,
    isFromSearch,
  );
  useEffect(() => {
    if (query.length === 3) {
      setIsFromSearch(true);
    } else {
      setIsFromSearch(false);
    }
  }, [query]);
  const queryChangeHandler = (value: string) => {
    setQuery(value);

    if (value.length >= 3) {
      handleSearch(value);
    }
  };

  const enableEmployeeHandler = async (id: ID, isSubBroker: boolean) => {
    const payload = {
      employeeId: isSubBroker ? null : id,
      subbrokerCode: isSubBroker ? id : null,
      ...(isSubBroker
        ? {
            manager1SubbrokerCode: autoCompleteData.firstInputValue,
            manager2SubbrokerCode: autoCompleteData.secondInputValue,
          }
        : {
            manager1EmpId: autoCompleteData.firstInputValue,
            manager2EmpId: autoCompleteData.secondInputValue,
          }),
    };
    const response = await enableEmployee(payload).unwrap();
    // filter the employee id and enable the employee (active - true)
    setSelectedEmployee(null);
    return response;
  };

  useEffect(() => {
    if (
      employeesDetailsValues &&
      employeesDetailsValues.isSuccess &&
      !employeesDetailsValues.isFetching
    ) {
      if (isFirstRender.current) {
        allEmployeeCountData.current.totalEmployees =
          employeesDetailsValues.data.totalRecords;
        allEmployeeCountData.current.employeeCount =
          employeesDetailsValues.data.employeeCount;
        allEmployeeCountData.current.subBrokerCount =
          employeesDetailsValues.data.subBrokerCount;
        isFirstRender.current = false;
      }
      setTotalRowsCount(
        tabValues.tabValueMain === '1'
          ? employeesDetailsValues.data.totalRecords
          : tabValues.tabValueMain === '2'
          ? employeesDetailsValues.data.employeeCount
          : employeesDetailsValues.data.subBrokerCount,
      );
      setEmployees(employeesDetailsValues.data.data);
    }
  }, [employeesDetailsValues]);
  useEffect(() => {
    if (
      adminSearchEmployeesValues &&
      adminSearchEmployeesValues.isSuccess &&
      !adminSearchEmployeesValues.isFetching
    ) {
      setTotalRowsCount(adminSearchEmployeesValues?.data?.length);
      setEmployees(adminSearchEmployeesValues?.data);
    }
  }, [adminSearchEmployeesValues]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let queryOptions: Record<string, any> = {
          entriesPerPage: rowsPerPage,
          pageNo: page + 1,
        };

        if (currentStatus !== '') {
          queryOptions.status = currentStatus;
        }

        if (tabValues.tabValueMain === '1') {
          // Fetch All employees
          await getEmployees(queryOptions);
        } else if (tabValues.tabValueMain === '2') {
          // Handle Employee Status for type 'EMPLOYEE'
          queryOptions.type = 'EMPLOYEE';
          await getEmployees(queryOptions);
        } else {
          // Handle Employee Status for type 'SUB BROKER'
          queryOptions.type = 'SUB BROKER';
          await getEmployees(queryOptions);
        }
      } catch (error: any) {
        // TODO: Add toast error here.
        showToast((error.data as { message: string }).message, 'error');
      }
    };
    const fetchInitialData = async () => {
      try {
        await getEmployees('');
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    };
    if (!isFromSearch && query.length === 0) {
      fetchData();
    }
    if (isFirstRender.current) {
      fetchInitialData();
    }
  }, [tabValues, currentStatus, rowsPerPage, page, isFromSearch, query]);
  const toggleHandler = (row: { [key: string]: any }) => {
    setSelectedEmployee(row);
  };

  const actionHandler = (selectedRow: { [key: string]: any }) => {
    // Need to add data in Redux.
    const { additionalData } = selectedRow;
    navigate('../profile-summary', {
      state: { selectedRow: additionalData },
    });
  };
  const statuspopup = () => {
    return (
      <CustomDialog
        isVisible={true}
        firstButtonHandler={() => {
          setSelectedEmployee(null);
        }}
        firstButtonTitle='Cancel'
        secondButtonTitle='Ok'
        secondButtonHandler={async () => {
          if (!selectedEmployee) return;
          const empId = selectedEmployee.ID;
          const isSubBroker = selectedEmployee.Role !== 'EMPLOYEE';
          const isActive = selectedEmployee.additionalData.active;

          const actionHandler = isActive ? disableEmployeeHandler : enableEmployeeHandler;
          try {
            const response = await actionHandler(empId, isSubBroker);
            showToast(
              (response.data || (response as { message: string })).message,
              'success',
            );
            // Update the state with the modified object
            setEmployees(prevEmployees => {
              if (!prevEmployees) return prevEmployees;

              const updatedEmployees = prevEmployees?.map((item: Employee) =>
                item.empId === empId ? { ...item, active: !item.active } : item,
              );

              return updatedEmployees;
            });
          } catch (error: any) {
            setSelectedEmployee(null);
            showToast((error.data as { message: string }).message, 'error');
          }
        }}
        handleClose={() => {}}
        showSecondButton={true}
      >
        <Box>
          {selectedEmployee && selectedEmployee.additionalData.active ? (
            <>
              <Stack
                direction={'row'}
                alignItems={'center'}
                columnGap={'20px'}
                mb={'20px'}
              >
                <Alert_Icon />
                <TransactionHeader sx={{ mb: 0 }}>Disable Employee</TransactionHeader>
              </Stack>
              <Typography
                sx={{
                  color: 'text.labelColor',
                  fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                  mb: 1.25,
                }}
              >
                Are you sure you want to disable
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                  mb: 1.5,
                  fontWeight: 500,
                }}
              >
                {`Employee: ${selectedEmployee.Name}, Emp ID: ${selectedEmployee.ID}`}
              </Typography>
            </>
          ) : (
            <>
              <Stack
                direction={'row'}
                alignItems={'center'}
                columnGap={'20px'}
                mb={'20px'}
              >
                <Alert_Icon />
                <TransactionHeader sx={{ fontSize: '18px', mb: 0 }}>
                  Enable Employee
                </TransactionHeader>
              </Stack>
              <Typography
                sx={{
                  color: 'text.labelColor',
                  fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                  mb: 1.25,
                }}
              >
                Please assign the L1 Manager and L2 Manager
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                  mb: 1.5,
                  fontWeight: 500,
                }}
              >
                {`Employee: ${selectedEmployee?.Name}, Emp ID: ${selectedEmployee?.ID}`}
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ width: '75%', margin: '0 auto' }}
              >
                <Grid
                  item
                  xs={12}
                >
                  <EmployeeSearchAutoComplete
                    textPlaceHolder={'Search L1 Manager Employee ID'}
                    selectedOptionHandler={(newInputValue: string) => {
                      setAutoCompleteData({
                        ...autoCompleteData,
                        firstInputValue: newInputValue,
                      });
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <EmployeeSearchAutoComplete
                    textPlaceHolder={'Search L2 Manager Employee ID'}
                    selectedOptionHandler={(newInputValue: string) => {
                      setAutoCompleteData({
                        ...autoCompleteData,
                        secondInputValue: newInputValue,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </CustomDialog>
    );
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const dropDownHandler = (selectedOption: string) => {
    setCurrentStatus(selectedOption);
  };
  return (
    <>
      {!openAddUsersForm && (
        <>
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: { xs: 2, sm: 0 },
            }}
          >
            <Typography
              sx={{
                mb: { sm: 4 },
                fontSize: { xs: '16px', sm: '22px', xl: '26px' },
                fontWeight: 500,
                maxWidth: { xs: '185px', sm: 'unset' },
              }}
            >
              Non Individual Profile
            </Typography>
            {/* Only for small screens */}
            <StepperAddButton
              onClick={() => setOpenAddUsersForm(true)}
              startIcon={<AddIcon />}
              sx={{
                padding: { xs: '6px 20px', sm: '11px 20px' },
                maxWidth: { xs: 'fit-content' },
                display: { xs: 'inline-flex', sm: 'none' },
              }}
            >
              Add Users
            </StepperAddButton>
            {/* Only for small screens */}
          </Stack>
          <Box sx={{ width: '100%', display: { xs: 'none', md: 'block' } }}>
            <DataTable
              showAdminDetails={true}
              hideHover={true}
              rows={getRows()}
              noDataText={'You do not have any users added.'}
              columns={[
                'Name',
                'Role',
                'ID',
                'Mobile Number',
                'Email',
                'Status',
                'Enable/disable',
                'Actions',
              ]}
              onSubmit={() => {}}
              filter={
                <TeamTableFilter
                  tabValue={tabValues.tabValueMain}
                  tabValueHandler={newValue => {
                    setTabValues(prevTabValues => ({
                      ...prevTabValues,
                      tabValueMain: newValue,
                    }));
                    setQuery('');
                    setPage(0);
                    dispatch(setTabValue(newValue));
                  }}
                  tabsArr={[
                    {
                      label: 'All',
                      value: '1',
                      totalCount: allEmployeeCountData.current.totalEmployees,
                    },
                    {
                      label: 'Employee',
                      value: '2',
                      totalCount: allEmployeeCountData.current.employeeCount,
                    },
                    {
                      label: 'Sub Broker',
                      value: '3',
                      totalCount: allEmployeeCountData.current.subBrokerCount,
                    },
                  ]}
                  tabContent={[]}
                  statusDropDown={{
                    All: '',
                    Completed: 'Completed',
                    Pending: 'Pending',
                  }}
                  selectedDropdownValue={currentStatus}
                  addButtonHandler={() => setDialogVisibility(true)}
                  dropDownHandler={dropDownHandler}
                  queryChangeHandler={queryChangeHandler}
                  query={query}
                />
              }
              hideUpdateButton
              hidecheckbox
              showtoggle
              toggleHandler={(row: { [key: string]: any }) => toggleHandler(row)}
              showAction
              actionHandler={actionHandler}
              paginationActive
              paginationDisabled={false}
              count={totalRowsCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
          <Box sx={{ width: '100%', display: { xs: 'block', md: 'none' } }}>
            <TeamTableFilter
              tabValue={tabValues.tabValueMain}
              tabValueHandler={newValue => {
                setTabValues(prevTabValues => ({
                  ...prevTabValues,
                  tabValueMain: newValue,
                }));
                setQuery('');
                setPage(0);
              }}
              tabsArr={[
                {
                  label: 'All',
                  value: '1',
                  totalCount: allEmployeeCountData.current.totalEmployees,
                },
                {
                  label: 'Employee',
                  value: '2',
                  totalCount: allEmployeeCountData.current.employeeCount,
                },
                {
                  label: 'Sub Broker',
                  value: '3',
                  totalCount: allEmployeeCountData.current.subBrokerCount,
                },
              ]}
              tabContent={[]}
              statusDropDown={{
                All: '',
                Completed: 'Completed',
                Pending: 'Pending',
              }}
              selectedDropdownValue={currentStatus}
              addButtonHandler={() => setDialogVisibility(true)}
              dropDownHandler={dropDownHandler}
              queryChangeHandler={queryChangeHandler}
              query={query}
            />
            <ResponsiveEmployeeSetup
              employeeData={getRows()}
              toggleHandler={(row: { [key: string]: any }) => toggleHandler(row)}
              actionHandler={(row: { [key: string]: any }) => actionHandler(row)}
            />
          </Box>
        </>
      )}

      {selectedEmployee && statuspopup()}
      {/* Both for bigger and smaller screens */}

      {mediaQuery.matches ? (
        <CustomDialog
          showActions={false}
          maxWidth='md'
          isVisible={dialogVisibility}
          firstButtonHandler={() => {}}
          firstButtonTitle='OK'
          handleClose={() => {
            setDialogVisibility(false);
          }}
          showFirstButton={false}
        >
          <AddUsersFormContent
            tabValues={tabValues}
            setTabValues={setTabValues}
            onCloseOrCancelHandler={() => {
              setDialogVisibility(false);
            }}
            setDialogVisibility={setDialogVisibility}
            isBigScreen={mediaQuery.matches}
          />
        </CustomDialog>
      ) : openAddUsersForm ? (
        <AddUsersFormContent
          tabValues={tabValues}
          setTabValues={setTabValues}
          onCloseOrCancelHandler={() => {
            setOpenAddUsersForm(false);
          }}
          setDialogVisibility={setDialogVisibility}
          isBigScreen={mediaQuery.matches}
        />
      ) : null}
    </>
  );
}
