import { Box, Divider, Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import {
  calculateDifference,
  calculateNextInstallmentDate,
  calculateTatDate,
  formatToMMDDYYYYByUTC,
  toUTCDate,
} from '../../../utils';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { FieldSubHeader3, MainHeader } from '../../onboarding/styles/styledComponents';
import SubmitCartButtons from '../common/SubmitCartButtons';
import EuinsFields from '../euinsComponent';
import { useSchemeByfundMutation } from '../mfs/newInvestorOnboarding/slice';
import { mapFrequency } from '../mfs/newInvestorOnboarding/utils';
import {
  useAddItemToCartMutation,
  useGetEUINSQuery,
  useGetPossibleStartDatesMutation,
  useGetSchemaDetailsMutation,
  useListOfSchemesMutation,
  useModifyItemInCartMutation,
  useValidateDatesMutation,
} from '../slice';
import { capitalizeFirstLetter, capitalizeWords, removeSpaces } from '../swp/common';
import { useGetSWPwithdrawalTypeMutation } from '../swp/slice';

interface InitialValues {
  stpOption: 'Fixed' | 'Capital Appreciation';
  frequency: string;
  amount: string;
  // noOfTransfers: string;
  stpStartDate: Date | null;
  stpEndDate: Date | null;
  euin: string;
  subArn: string;
  subBroker: string;
  folioNumber: number;
  switchOutScheme: string;
  isExistingScheme: 'E' | 'N';
  switchInScheme: string;
  category: string;
  amc: string;
}

interface InvestmentDetailsFormProps {
  data: any;
  isEditMode: boolean;
  handleCancelModifyingCart?: () => void;
}

const DEFAULT_MIN_TAT = 7;
export enum InstallmentCycleFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Halfyearly = 'Half Yearly',
  Annually = 'Annually',
  Fortnightly = 'Fortnightly',
}
const InvestmentDetailsForm: React.FC<InvestmentDetailsFormProps> = ({
  data,
  isEditMode,
  handleCancelModifyingCart,
}) => {
  const [stpOptions, setStpOptions] = useState<string[]>([
    'Fixed',
    'Capital Appreciation',
  ]);
  const [addStpItemToCart] = useAddItemToCartMutation();
  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const { showToast } = useRootContext();

  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [schemeDetails, setSchemeDetails] = useState<any>({});
  const [schemeList, setSchemeList] = useState<any>([]);

  const [frequencyType, setFrequencyType] = useState('');
  const firstButtonTitle = !isEditMode ? 'Add to Cart' : 'Cancel';
  const secondButtonTitle = !isEditMode ? 'Save & Proceed' : 'Save';
  const [calculatedInstallments, setCalculatedInstallments] = useState<number>(0);
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<InitialValues>>(null);
  const { data: allEUINs } = useGetEUINSQuery([]);
  const { arn, subArn, subbrokerCode, euin } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const [calendarOpeningMonths, setCalendarOpeningMonths] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [modifyItemInCart] = useModifyItemInCartMutation();

  const [getWithdrawalType] = useGetSWPwithdrawalTypeMutation();

  const [validateDates] = useValidateDatesMutation();

  // {
  //   "fund": "105",
  //     "scheme": "LF",
  //     "trType": "STP",
  //     "endDate": "09/20/2025",
  //     "frequency": "Monthly",
  //   "startDate":"08/20/2024"

  //   }
  const [possibleStartDates] = useGetPossibleStartDatesMutation();

  const { name: investorName, pan } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );

  const [listOfSchemes] = useListOfSchemesMutation();
  const [selectedCategory, setSelectedCategory] = useState(data.selectedCategory);
  const [schemesByFund, setSchemesByFund] = useState<any>([]);
  const [getSchemesByFund] = useSchemeByfundMutation();
  const [switchoutScheme, SelectedSwitchoutscheme] = useState<any>(data);
  const [switchInScheme, SelectedSwitchInScheme] = useState<any>(data);
  const firstChipCall = useRef<boolean>(false);
  const [categoriesData, setCategories] = useState(data.categories);
  const dispatch = useDispatch();

  const [startDates, setStartDates] = useState<string[]>([]);

  const totalAvailableAmount = data?.stpOutData?.units * data?.stpOutData?.nav;

  useEffect(() => {
    async function getWithdrawalTypes(fund: string) {
      try {
        dispatch(startLoading());
        const res = await getWithdrawalType({ fund }).unwrap();
        const descriptions = res.map((item: { description: string }) =>
          capitalizeWords(item.description || ''),
        );
        setStpOptions(descriptions);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }
    async function fetchData() {
      try {
        dispatch(startLoading());
        const payload = await getSchemaDetails({
          fund: data.fund,
          scheme: data.scheme,
          plan: data.plan,
          option: data.option,
          trType: 'STP',
          toScheme: data.scheme,
        }).unwrap();
        setSchemeDetails(payload);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }
    fetchData();
    getWithdrawalTypes(data?.fund);
  }, [data]);

  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(startLoading());
        const response = await listOfSchemes({ pan: pan.toUpperCase() }).unwrap();
        const filteredData = response.filter(
          (scheme: any) => scheme.stpAllow && scheme.fund === data.fund,
        );
        setSchemeList(filteredData);
      } catch (error: any) {
        showToast((error as { message: string }).message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }
    if (isEditMode) fetchData();
  }, []);

  useEffect(() => {
    async function fetchSchemesByFund() {
      try {
        dispatch(startLoading());
        const schemeByFundBody = {
          funds: [data.fund],
          investorPan: '',
          start: 0,
          end: 20000, //TODO: add valid pagination
          ...(selectedCategory && { category: [selectedCategory] }),
        };
        const resFund = await getSchemesByFund(schemeByFundBody).unwrap();
        // Initializing it with the first category.
        if (!firstChipCall.current) {
          const arrangedObj: { [key: string]: any[] } = {};
          for (const item of resFund) {
            const { fund } = item;
            if (!arrangedObj[fund]) {
              arrangedObj[fund] = [item];
            } else {
              arrangedObj[fund].push(item);
            }
          }
          const uniqueCategories: string[] = [
            ...new Set(arrangedObj[data.fund]?.map(item => item.category) || []),
          ];
          setCategories(uniqueCategories);
          firstChipCall.current = true;
        }
        setSchemesByFund(resFund);
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }
    if (isEditMode) fetchSchemesByFund();
  }, [selectedCategory]);

  const getSwitchInSchemes = (isExistingScheme: 'E' | 'N', folioNumber: number) => {
    if (isExistingScheme === 'E') {
      return schemeList.filter(
        (scheme: any) =>
          scheme.folio === folioNumber &&
          `${scheme.scheme}_${scheme.schemePlan}_${scheme.schemeOption}` !==
            `${formikRef.current?.values.switchOutScheme}`,
      );
    } else {
      return schemesByFund;
    }
  };

  const handleAddToCartClick = async () => {
    try {
      dispatch(startLoading());

      const payload = {
        pan,
        mobile: data?.stpOutData?.mobile,
        email: data?.stpOutData?.email,
        folio: data.folio,
        fund: data.fund,
        fundName: data.fundName,
        scheme: data.scheme,
        plan: data.plan,
        option: data.option,
        toScheme: data.toScheme,
        toPlan: data.toPlan,
        toOption: data.toOption,
        amount: formikRef.current?.values.amount,
        subBroker: formikRef.current?.values.subBroker || '',
        subArn: formikRef.current?.values.subArn || '',
        euin: formikRef.current?.values.euin || '',
        transactionType: 'STP',
        category: data.category,
        stpOption: formikRef.current?.values.stpOption,
        frequency: formikRef.current?.values.frequency[0],
        // stpDay: formikRef.current?.values.stpDay,
        stpStartDate: formatToMMDDYYYYByUTC(
          formikRef.current?.values.stpStartDate as Date,
        ),
        stpEndDate: formatToMMDDYYYYByUTC(formikRef.current?.values.stpEndDate as Date),
        noOfTransfers: calculatedInstallments || 0,
        investorName,
        switchInSchemeName: data.switchInSchemeName,
        switchOutSchemeName: data.switchOutSchemeName,
        taxSaverFlag: data.taxSaverFlag,
        ...(data.extraData ? data.extraData : {}),
      };
      await addStpItemToCart(payload).unwrap();
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  async function handleSubmit(values: InitialValues) {
    try {
      const payload = isEditMode
        ? {
            pan,
            mobile: switchoutScheme ? switchoutScheme?.mobile : data?.stpOutData?.mobile,
            email: switchoutScheme ? switchoutScheme?.email : data?.stpOutData?.email,
            folio: switchoutScheme ? switchoutScheme?.folio : data.folio,
            fund: switchoutScheme ? switchoutScheme?.fund : data.fund,
            fundName: switchoutScheme ? switchoutScheme?.amcName : data.fundName,
            scheme: switchoutScheme ? switchoutScheme?.scheme : data.scheme,
            plan: switchoutScheme ? switchoutScheme?.plan : data.plan,
            option: switchoutScheme ? switchoutScheme?.option : data.option,
            toScheme: switchInScheme ? switchInScheme?.scheme : data.toScheme,
            toPlan: switchInScheme ? switchInScheme?.plan : data.toPlan,
            toOption: switchInScheme ? switchInScheme?.option : data.toOption,
            amount: values.amount,
            subBroker: values.subBroker || '',
            euin: values.euin || '',
            subArn: values.subArn || '',
            transactionType: 'STP',
            category: selectedCategory,
            stpOption: values.stpOption,
            frequency: values.frequency[0],
            // stpDay: values.stpDay,
            stpStartDate: values.stpStartDate,
            stpEndDate: values.stpEndDate,
            noOfTransfers: calculatedInstallments || 0,
            investorName,
            switchInSchemeName: switchInScheme?.schemeName || data.switchInSchemeName, // todo check scheme Name for switch in - scheme desc = scheme Name
            switchOutSchemeName: switchoutScheme
              ? switchoutScheme?.schemeName || data.switchOutSchemeName
              : data.switchOutSchemeName,
            taxSaverFlag: `${data.taxSaverFlag}`,
            isExistingScheme: values.isExistingScheme,
          }
        : {
            pan,
            mobile: data?.stpOutData?.mobile,
            email: data?.stpOutData?.email,
            folio: data.folio,
            fund: data.fund,
            fundName: data.fundName,
            scheme: data.scheme,
            plan: data.plan,
            option: data.option,
            toScheme: data.toScheme,
            toPlan: data.toPlan,
            toOption: data.toOption,
            amount: values.amount,
            subBroker: values.subBroker || '',
            euin: values.euin || '',
            subArn: values.subArn || '',
            transactionType: 'STP',
            category: data.category,
            stpOption: values.stpOption,
            frequency: values.frequency[0],
            // stpDay: values.stpDay,
            stpStartDate: values.stpStartDate,
            stpEndDate: values.stpEndDate,
            noOfTransfers: calculatedInstallments || 0,
            investorName,
            switchInSchemeName: data.switchInSchemeName,
            switchOutSchemeName: data.switchOutSchemeName,
            taxSaverFlag: `${data.taxSaverFlag}`,
          };

      if (isEditMode) {
        try {
          dispatch(startLoading());
          await modifyItemInCart(payload).unwrap();
          showToast(`CartItem updated successfully`, 'success');
          if (handleCancelModifyingCart) handleCancelModifyingCart();
        } catch (error: any) {
          const message =
            (error as any).data?.message || (error as any).message || 'Unknown error';
          showToast(message, 'error');
        } finally {
          dispatch(stopLoading());
        }
      } else {
        navigate('/transaction-dashboard/confirmation', {
          state: { payload: payload, isEditMode: isEditMode },
        });
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }

  const handleGoToCart = () => {
    navigate('../../cart');
  };

  const handleAddAnotherTransaction = () => {
    navigate('../');
  };

  const [, minInstallments, minTransactionAmount, coolingPeriod]: [
    string[],
    number,
    number,
    number,
  ] = useMemo(() => {
    const frequency = schemeDetails?.frequency?.find(
      (f: any) => removeSpaces(capitalizeFirstLetter(f.mode || '')) === frequencyType,
    );
    const cycle = frequency?.cycle;
    const dates =
      !cycle || !/\d/.test(cycle)
        ? Array.from({ length: 28 }, (_, index) => `${index + 1}`)
        : cycle.split(',');

    return [
      dates,
      frequency?.minInstallments,
      frequency?.minTransactionAmount,
      frequency?.coolingPeriod,
    ];
  }, [schemeDetails?.frequency, frequencyType]);
  const stpModes: string[] = useMemo(() => {
    const modes = schemeDetails?.frequency?.map((f: any) =>
      removeSpaces(capitalizeFirstLetter(f.mode)),
    );
    setFrequencyType(modes?.[0]);
    return Array.from(new Set(modes));
  }, [schemeDetails?.frequency]);
  useEffect(() => {
    const fetchStartDates = async () => {
      if (frequencyType) {
        try {
          dispatch(startLoading());
          const res = await possibleStartDates({
            fund: data?.fund,
            scheme: data?.scheme,
            trType: 'STP',
            toScheme: data?.toScheme,
            frequency:
              InstallmentCycleFrequency[
                frequencyType as keyof typeof InstallmentCycleFrequency
              ],
            installments: minInstallments || 0, // check this
          }).unwrap();
          setStartDates(res);
          setCalendarOpeningMonths({
            ...calendarOpeningMonths,
            startDate: new Date(res[0]),
          });
        } catch (error: any) {
          const message =
            (error as any).data?.message || (error as any).message || 'Unknown error';
          showToast(message, 'error');
        } finally {
          dispatch(stopLoading());
        }
      }
    };

    fetchStartDates();
  }, [frequencyType]);
  const validationSchema = Yup.object().shape({
    stpOption: Yup.string()
      .oneOf(['Fixed', 'Capital Appreciation'], 'Select one option')
      .required('STP Option is required'),
    frequency: Yup.string()
      .oneOf(stpModes ?? [], 'Select one option')
      .required('STP Frequency is required'),
    amount: Yup.string().when('stpOption', {
      is: 'Fixed',
      then: Yup.string()
        .test(
          'greaterThanMinTransactionAmount',
          `Investment Amount must be greater than ${minTransactionAmount ?? 0}`,
          function (value: any) {
            return parseFloat(value) > (minTransactionAmount ?? 0);
          },
        )
        .required('Amount is required'),
      otherwise: Yup.string().nullable(),
    }),
    stpStartDate: Yup.string().required('STP start date is required'),
    stpEndDate: Yup.string().required('STP end date is required'),
  });

  const initialValues: InitialValues = {
    stpOption: isEditMode ? data?.stpOption : 'Fixed',
    frequency: isEditMode ? mapFrequency(data?.frequency) : frequencyType || stpModes[0],
    amount: isEditMode ? data?.amount : '0',
    // stpDay: isEditMode ? data?.stpDay : stpDates[0],
    stpStartDate: isEditMode ? data?.stpStartDate : null,
    stpEndDate: isEditMode ? data?.stpEndDate : null,
    euin: euin,
    // euinValue: euin,
    subArn: subArn,
    subBroker: subbrokerCode,
    isExistingScheme: data.isExistingScheme,
    folioNumber: data.folio,
    switchOutScheme: `${data.scheme}_${data.plan}_${data.option}`,
    switchInScheme: `${data.toScheme}_${data.toPlan}_${data.toOption}`,
    category: data.selectedCategory,
    amc: data.fundName,
  };

  const handleValidation = async (values: any) => {
    const errors: Record<string, any> = {};

    if (Number(values.amount) > totalAvailableAmount) {
      errors.amount = `Investment amount should not exceed ${totalAvailableAmount}`;
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formikRef}
      validate={async values => handleValidation(values)}
    >
      {({ values, setFieldValue, errors, setValues }) => (
        <Form>
          <Grid
            container
            spacing={2}
            mb={'24px'}
          >
            {isEditMode && (
              <>
                <Grid
                  item
                  sm={6}
                >
                  <FormTextInput
                    name='amc'
                    label='AMC'
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='folioNumber'
                    label='folioNumber'
                    options={schemeList.map((scheme: any) => ({
                      label: scheme.folio,
                      value: scheme.folio,
                    }))}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='switchOutScheme'
                    label='Switch Out Scheme'
                    options={schemeList
                      .filter((scheme: any) => scheme.folio === values.folioNumber)
                      .map((scheme: any) => ({
                        label: `${scheme.schemeName}-${scheme.planDesc}`,
                        value: `${scheme.scheme}_${scheme.plan}_${scheme.option}`,
                      }))}
                    onChange={(e: any) => {
                      const [scheme, plan, option] = e.target.value.split('_');

                      const filteredResult = schemeList.filter(
                        (schemes: any) =>
                          schemes.scheme === scheme &&
                          schemes.plan === plan &&
                          schemes.option === option,
                      );
                      SelectedSwitchoutscheme(filteredResult?.[0]);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  mb={2}
                >
                  <Box sx={{ bgcolor: 'text.boxColor', padding: '20px 15px' }}>
                    <Grid
                      container
                      spacing={2}
                      alignItems={'center'}
                    >
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Balance Units:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {switchoutScheme?.units || 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Current Value:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {(
                              (switchoutScheme?.units || 0) * (switchoutScheme?.nav || 0)
                            ).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            NAV as on Date:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {switchoutScheme?.nav || 0}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={12}
                >
                  <FormStyledRadioButton
                    options={[
                      { label: 'Existing Scheme & Plan', value: 'E' },
                      { label: 'New Scheme & Plan', value: 'N' },
                    ]}
                    name='isExistingScheme'
                  />
                </Grid>
                {values.isExistingScheme === 'N' && (
                  <Grid
                    item
                    sm={6}
                  >
                    <FormSelectInput
                      name='category'
                      label='Category'
                      options={categoriesData?.map((category: string) => ({
                        label: category,
                        value: category,
                      }))}
                      onChange={async (e: SelectChangeEvent<any>) => (
                        setValues(values => ({
                          ...values,
                          category: e.target.value,
                          switchInScheme: '',
                        })),
                        setSelectedCategory(e.target.value)
                      )}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='switchInScheme'
                    label='Switch In Scheme'
                    options={getSwitchInSchemes(
                      values.isExistingScheme,
                      values.folioNumber,
                    ).map((scheme: any) => ({
                      label: `${scheme.schemeName || scheme.schemeDesc}-${
                        scheme.planDesc
                      }`,
                      value: `${scheme.scheme}_${scheme.plan}_${scheme.option}`,
                    }))}
                    onChange={(e: any) => {
                      const [scheme, plan, option] = e.target.value.split('_');

                      const filteredResult = getSwitchInSchemes(
                        values.isExistingScheme,
                        values.folioNumber,
                      ).filter(
                        (schemes: any) =>
                          schemes.scheme === scheme &&
                          schemes.plan === plan &&
                          schemes.option === option,
                      );

                      SelectedSwitchInScheme(filteredResult?.[0]);
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            mb={'24px'}
          >
            <Grid
              item
              sm={6}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 1.5,
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                STP Type
              </Typography>
              <FormStyledRadioButton
                options={stpOptions.map(item => ({
                  label: item,
                  value: item,
                }))}
                name='stpOption'
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 1,
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                STP Mode
              </Typography>
              <FormSelectInput
                name='frequency'
                label='STP Mode'
                onChange={async (e: SelectChangeEvent<unknown>) => {
                  setFrequencyType(e.target.value as string);
                }}
                options={
                  stpModes?.map((mode: string) => ({
                    label: mode,
                    value: mode,
                  })) || []
                }
                // disabled={isLoading}
              />
            </Grid>
          </Grid>

          {values.stpOption === 'Fixed' && (
            <Grid
              container
              spacing={2}
              sx={{ mb: { sm: 3 } }}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 1,
                    fontSize: { xs: '14px', xl: '16px' },
                    fontWeight: 500,
                  }}
                >
                  Investment Amount :
                </Typography>

                <FormTextInput
                  name='amount'
                  label='Investment Amount'
                  placeholder='Enter the Investment Amount'
                  typeOfInput='number'
                  onInput={(e: any) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormDateInput
                name='stpStartDate'
                label='Time Period from'
                placeholder='Time Period from'
                defaultCalendarMonth={calendarOpeningMonths.startDate}
                minDate={calculateTatDate(coolingPeriod || 0)}
                onChange={(date: any) => {
                  const newDate = calculateNextInstallmentDate(
                    date,
                    frequencyType,
                    minInstallments,
                  );
                  setCalendarOpeningMonths({
                    ...calendarOpeningMonths,
                    endDate: newDate,
                  });
                  // setFirstEnabledDate(newDate);
                  // Setting the date to null whenever date is chosen.
                  setFieldValue('stpEndDate', null);
                }}
              />
              {minInstallments !== undefined && (
                <Typography>Minimum No. of Installments: {minInstallments}</Typography>
              )}
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormDateInput
                name='stpEndDate'
                placeholder='Time Period to'
                label='Time Period to'
                minDate={calculateTatDate(coolingPeriod || 0)}
                onChange={async (date: any) => {
                  const endDate = toUTCDate(new Date(date));
                  const startDate = toUTCDate(new Date(values?.stpStartDate as Date));

                  const selectedInstallments = calculateDifference(
                    new Date(values?.stpStartDate as Date),
                    new Date(date),
                    values?.frequency,
                  );
                  setCalculatedInstallments(selectedInstallments as number);
                  try {
                    dispatch(startLoading());
                    // TODO: Check for validateDates for half-yearly.
                    const res = await validateDates({
                      fund: data?.fund,
                      scheme: data?.scheme,
                      trType: 'STP',
                      endDate: formatToMMDDYYYYByUTC(new Date(endDate)),
                      frequency: values?.frequency,
                      startDate: formatToMMDDYYYYByUTC(new Date(startDate)),
                    }).unwrap();
                    if (res?.error === true) {
                      showToast(res?.message || 'Unknown Error', 'error');
                    } else {
                      showToast(res?.message || 'Valid Dates chosen.', 'success');
                    }
                  } catch (error: any) {
                    const message =
                      (error as any).data?.message ||
                      (error as any).message ||
                      'Unknown error';
                    showToast(message, 'error');
                  } finally {
                    dispatch(stopLoading());
                  }
                }}
                defaultCalendarMonth={calendarOpeningMonths.endDate}
              />
              {calculatedInstallments > 0 && (
                <Typography sx={{ mb: 2, my: { xs: 2, md: 0 } }}>
                  Selected No. of Installments: {calculatedInstallments}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            sx={{
              border: '1px dashed',
              borderColor: 'text.borderColorDark',
              my: 3,
            }}
          />
          <Typography
            sx={{
              fontSize: {
                xs: '16px',
                xl: '18px',
              },
              fontWeight: 500,
              color: { xs: 'text.valueColor', sm: 'primary.main' },
              mb: 2,
            }}
          >
            Distributor Details
          </Typography>
          <Grid
            container
            spacing={2}
            mb={'24px'}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormTextInput
                required={false}
                name='arn'
                label={'ARN Code'}
                defaultValue={arn}
                disabled
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormTextInput
                required={false}
                name='subArn'
                label={'Sub ARN Code'}
                defaultValue={subArn}
                typeOfInput='alphanumeric'
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormTextInput
                required={false}
                name='subBroker'
                label={'Sub Broker Code'}
                defaultValue={subbrokerCode}
                typeOfInput='alphanumeric'
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <EuinsFields
                name='euin'
                allEUINs={allEUINs}
                initialValue={
                  isEditMode ? (data?.euinDeclaration == 'Y' ? 'no' : 'yes') : 'yes'
                }
                defaultValue={euin || ''}
              />
            </Grid>
          </Grid>
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            sx={{
              border: '1px dashed',
              borderColor: 'text.borderColorDark',
              mt: 3,
            }}
          />
          <SubmitCartButtons
            onAddToCartClick={
              !isEditMode ? handleAddToCartClick : handleCancelModifyingCart
            }
            firstButtonTitle={firstButtonTitle}
            secondButtonTitle={secondButtonTitle}
          />

          {openDialogBox && (
            <CustomDialog
              isVisible={true}
              firstButtonHandler={handleGoToCart}
              secondButtonHandler={handleAddAnotherTransaction}
              firstButtonTitle='Go to cart'
              secondButtonTitle='Add another Transaction'
              handleClose={() => {}}
              showSecondButton={true}
            >
              <Box sx={{ textAlign: 'center', pt: 2 }}>
                <img
                  src={VerifiedIcon}
                  alt='verified-icon'
                />
                <MainHeader sx={{ fontWeight: 500, mb: 2 }}>Added To Cart</MainHeader>
                <FieldSubHeader3 sx={{ fontSize: '16px' }}>
                  Scheme is added to investor cart
                </FieldSubHeader3>
              </Box>
            </CustomDialog>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default InvestmentDetailsForm;
