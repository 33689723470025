import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageNavigator } from '../../common/BackToHomePage';
import InvestorProfile from '../../common/InvestorProfile';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import PanDetailsBox from '../../common/PanDetails';
// import InvestorProfile from '../../common/InvestorProfile';
import SearchInputBox from '../../common/SearchBox';
import { useRootContext } from '../../data/root.context';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import StyledRadioButtonGroup from '../../forms/FormStyledRadioButton';
import { CommonTable } from '../../transactionDashboard/investorTable';
import { createConvertedOutputArray } from '../common';
import NewSchemeTable from '../common/NewSchemeTable';
import { useListOfSchemesMutation } from '../slice';
import { investorSchemeDetailsColumns, Scheme, TransactionAllow } from '../types';
import { SelectedFolioDetails } from './types';
interface DropdownItem {
  label: string;
  value: string;
}
interface SchemeDetail {
  fund: string;
  amcName: string;
  folio: number;
  dob: string;
  investorName: string;
  mobile: string;
  email: string;
  schemeName: string;
  scheme: string;
  schemePlan: string;
  schemeOption: string;
  planDesc: string;
  transactionSource: string;
  isDemat: boolean;
  taxsaverFlag: boolean;
  mcrid: boolean;
  category: string;
  modeOfHolding: string;
  mohDesc: string;
  units: number;
  nav: number;
  redemptionAllow: boolean;
  swdAllow: boolean;
  switchAllow: boolean;
  stpAllow: boolean;
  additionalPurAllow: boolean;
  newPurAllow: boolean;
}
interface AllArrangedData {
  amcDropDown?: DropdownItem[];
  allData: { [key: string]: SchemeDetail[] }; // Change this type as per your requirement
}
interface AllDropdownData {
  folioData: string[];
  chips: string[];
}
const MFSAdditionalPurchase = () => {
  const navigate = useNavigate();
  const [investorSchemes, setInvestorSchemes] = useState<Scheme[]>([]);
  const [selectedFolioDetails, setSelectedFolioDetails] = useState<SelectedFolioDetails>({
    selectedFolio: '',
    folioEmail: '',
    folioMobileNumber: '',
    folioInvestorName: '',
  });
  const { showToast } = useRootContext();
  const [investorDetailsList] = useListOfSchemesMutation();
  const [selectedAmc, setSelectedAmc] = useState<string>('');
  const [allArrangedData, setAllArrangedData] = useState<AllArrangedData>();
  const [allDropdownData, setAllDropdownData] = useState<AllDropdownData>({
    folioData: [],
    chips: [],
  });
  const [selectedTab, setSelectedTab] = useState<string>('1');
  const { name, pan } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const { state } = location || {};
  const folioNo = state?.cartItem[0]?.folio;
  const fund = state?.cartItem[0]?.fund;
  const fundName = state?.cartItem[0]?.fundName;
  const isFromCart = state?.isFormCart;
  const [allInvestorSchemeData, setAllInvestorSchemeData] = useState<Scheme[]>([]);
  const dispatch = useDispatch();
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const continueButtonHandler = (row: any) => {
    if (
      selectedTab === '1' ||
      (selectedTab === '2' && selectedFolioDetails.selectedFolio)
    ) {
      const filteredObject = investorSchemes.find(
        (obj: Scheme) =>
          obj.fund === row.fund &&
          obj.plan === row.plan &&
          obj.option === row.option &&
          row.schemeName === row.schemeName,
      );
      let payloadData = {};
      if (selectedTab === '2') {
        payloadData = {
          fund: row?.fund,
          fundName: filteredObject?.amcName, // Fund name == amcName
          category: row.category,
          option: row.option,
          plan: row.plan,
          redemptionAllow: row.redemptionAllow,
          swdAllow: row.swdAllow,
          switchAllow: row.switchAllow,
          stpAllow: row.stpAllow,
          additionalPurAllow: row.additionalPurAllow,
          newPurAllow: row.newPurAllow,
          scheme: row.scheme,
          schemeDesc: row.schemeDesc,
          planDesc: row.planDesc,
          planMode: row.planMode,
          email: selectedFolioDetails?.folioEmail,
          mobile: selectedFolioDetails?.folioMobileNumber,
          investorName: selectedFolioDetails?.folioInvestorName,
          folio: selectedFolioDetails?.selectedFolio,
        };
      } else {
        payloadData = {
          ...filteredObject,
          folio: filteredObject?.folio,
          availableAmount: row?.availableAmount,
          balanceUnits: row?.balanceUnits,
          taxSaverFlag: row?.taxsaverFlag,
        };
      }
      navigate('./investment-details', {
        state: {
          previousURL: 'additional-purchase',
          schemeDetails: {
            ...payloadData,
            isNewScheme: selectedTab === '2',
          },
        },
      });
    } else {
      showToast('Please select a Folio from Dropdown.', 'error');
      return;
    }
  };

  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      const payload = {
        pan: pan,
      };
      try {
        dispatch(startLoading());
        const response = await investorDetailsList(payload).unwrap();
        if (response) {
          let filteredData = [];
          setAllInvestorSchemeData(response); // with no filter.
          filteredData = response.filter((item: any) => item.additionalPurAllow === true);

          if (isFromCart && fund) {
            filteredData = response.filter((item: any) => item.fund === fund);
          }
          setInvestorSchemes(filteredData);
        } else {
          showToast(`Error fetching investor details: ${response.error}`, 'error');
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }
    getInvestorDetailsSchemes();
  }, []);
  useEffect(() => {
    const arrangedObj: { [key: string]: any[] } = {};
    const dropdownData: DropdownItem[] = [];
    const exisitngFunds = new Set<string>();

    for (const item of allInvestorSchemeData) {
      const { fund, amcName, additionalPurAllow } = item as Scheme;

      if (!arrangedObj[fund]) {
        arrangedObj[fund] = [item];
      } else {
        arrangedObj[fund].push(item);
      }

      if (amcName && additionalPurAllow && !exisitngFunds.has(fund)) {
        dropdownData.push({
          label: amcName,
          value: fund,
        });
        exisitngFunds.add(fund);
      }
    }

    setAllArrangedData({
      ...allArrangedData,
      amcDropDown: isFromCart
        ? dropdownData.filter(({ value }) => value === fund)
        : dropdownData,
      allData: arrangedObj,
    });
  }, [allInvestorSchemeData]);
  const handleFolioSelection = (folioNo: string) => {
    const [selectedFolio] =
      allArrangedData?.allData[selectedAmc]?.filter(
        (item: SchemeDetail) => item.folio === Number(folioNo),
      ) || [];

    setSelectedFolioDetails(prevDetails => ({
      ...prevDetails,
      selectedFolio: folioNo,
      folioEmail: selectedFolio?.email || '',
      folioMobileNumber: selectedFolio?.mobile || '',
      folioInvestorName: selectedFolio?.investorName || '',
    }));
  };

  const handleAmcSelection = (e: any) => {
    setSelectedAmc(e.target.value);
    setAllDropdownData(folioData => ({
      ...folioData,
      // Only Get folioData when showFolios
      folioData: [
        ...new Set(
          allArrangedData?.allData[e.target.value]?.map(item => String(item.folio)) || [],
        ),
      ],
    }));
  };

  return (
    <>
      <PageNavigator
        title='Additional Purchase'
        subtitle='Please select the scheme you want to make Additional Purchase'
      />
      <PanDetailsBox
        name={name}
        value={pan}
      />
      <Box sx={{ mb: 2 }}>
        <StyledRadioButtonGroup
          options={[
            { label: 'Existing Scheme & Plan', value: '1' },
            { label: 'New Scheme & Plan', value: '2' },
          ]}
          value={selectedTab}
          handleChange={(e: any) => {
            setSelectedTab(e.target.value);
          }}
        />
      </Box>

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={9}
        >
          {selectedTab === '1' ? (
            <CommonTable
              rows={createConvertedOutputArray(
                investorSchemes?.map((item: any) => ({
                  fundName: item.amcName,
                  schemeDesc: item.schemeName,
                  folioNo: item.folio,
                  availableAmount: (item.nav * item.units).toFixed(2),
                  balanceUnits: item.units,
                  exitLoad: '1', //TODO: replace with exit load
                  taxSaverFlag: item.taxsaverFlag,
                  ...item,
                })),
                TransactionAllow['APL'],
              )} //TODO : optimise this line
              columns={Object.values(investorSchemeDetailsColumns) ?? []}
              start={0} // Starting page indexx
              limit={10} // Limit given
              searchPlaceholder={'Search by Name'}
              continueButtonHandler={continueButtonHandler}
              columnsToShow={Object.keys(investorSchemeDetailsColumns)}
              keyToCheck={TransactionAllow['APL']}
            />
          ) : (
            <NewSchemeTable
              onContinueButtonClick={continueButtonHandler}
              showFoliosDropdown
              showAmcDropdown
              selectedFolio={selectedFolioDetails?.selectedFolio}
              handleFolioSelection={handleFolioSelection}
              selectedAmc={selectedAmc}
              handleAmcSelection={handleAmcSelection}
              amcDropdown={allArrangedData?.amcDropDown}
              folioDropdown={allDropdownData?.folioData}
              filterByKey={TransactionAllow['APL']}
              // handleGetFolioDropDownData={handleGetFolioDropDownData}
            />
          )}
          {!isMobile && <SearchInputBox />}
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
        >
          <InvestorProfile />
        </Grid>
      </Grid>
    </>
  );
};
export default MFSAdditionalPurchase;
