import apiSlice from '../../service/api';

const dashboardSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSnapshots: builder.query({
      query: () => ({
        url: 'user/dashboard/get-snapshots',
        method: 'GET',
      }),
    }),
    snapshotDetails: builder.mutation({
      query: payload => ({
        url: '/user/dashboard/snapshot-details',
        method: 'POST',
        body: payload,
      }),
    }),
    orderReinitiate: builder.mutation({
      query: payload => ({
        url: '/user/dashboard/order-re-initiate',
        method: 'POST',
        body: payload,
      }),
    }),
    addSnapShotItemToCart: builder.mutation({
      query: payload => ({
        url: '/user/dashboard/order-add-to-cart',
        method: 'POST',
        body: payload,
      }),
    }),
    createNFO: builder.query({
      query: () => ({
        url: 'user/transactions/nfo/get-nfo-data',
        method: 'GET',
      }),
    }),
    getFundEarnings: builder.mutation({
      query: payload => ({
        url: '/user/dashboard/get-fund-earnings',
        method: 'POST',
        body: payload,
      }),
    }),
    getNFOLink: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nfo/generate-nfo-link',
        method: 'POST',
        body: payload,
      }),
    }),
    getDistributorManagedFunds: builder.query({
      query: () => ({
        url: '/user/dashboard/get-distributor-managed-funds',
        method: 'GET',
      }),
    }),
    getAumSummary: builder.query({
      query: () => ({
        url: '/user/dashboard/aum-summary',
        method: 'GET',
      }),
    }),
    getSipDetails: builder.mutation({
      query: payload => ({
        url: '/user/dashboard/get-sip-details',
        method: 'POST',
        body: payload,
      }),
    }),
    getSipSummary: builder.query({
      query: () => ({
        url: '/user/dashboard/get-sip-summary',
        method: 'GET',
      }),
    }),
    getMfAumDetails: builder.query({
      query: () => ({
        url: '/user/dashboard/get-mf-aum-details',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetSnapshotsQuery,
  useSnapshotDetailsMutation,
  useOrderReinitiateMutation,
  useAddSnapShotItemToCartMutation,
  useGetFundEarningsMutation,
  useGetDistributorManagedFundsQuery,
  useLazyCreateNFOQuery,
  useGetNFOLinkMutation,
  useLazyGetAumSummaryQuery,
  useGetSipDetailsMutation,
  useLazyGetSipSummaryQuery,
  useGetMfAumDetailsQuery,
} = dashboardSlice;
