import { Box, Button, IconButton, Stack, Theme, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as BackIcon_Mobile } from '../../assets/back-icon.svg';
import ArrowBacKIcon from '../../assets/back-icon-large.svg';
import { ReactComponent as BackIcon } from '../../assets/back-icon-large.svg';
import { SubHeader } from '../auth/login/styles/styledComponents';
export interface HomePageProps {
  title?: string;
  to?: string;
  subtitle?: string;
  backHandler?: () => void;
  sx?: (theme: Theme) => any;
}
// for signup and login Pages UI//
export default function HomeLink({ title, to, backHandler }: HomePageProps) {
  return (
    <Typography
      component='span'
      sx={{ display: ['none', 'block'] }}
    >
      <Button
        component={backHandler ? 'button' : Link}
        to={backHandler ? undefined : to}
        onClick={backHandler || undefined}
        sx={{
          color: 'text.primary',
          fontSize: '14px',
          fontWeight: '500',
          textDecoration: 'none',
        }}
      >
        <img
          src={ArrowBacKIcon}
          alt='arrow-back-icon'
          style={{ marginRight: '10px' }}
        />
        {title}
      </Button>
    </Typography>
  );
}
// for signup and login Pages UI//

//for mobile UI
export function HomePage({ title }: HomePageProps) {
  const navigate = useNavigate();
  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        sx={{ display: ['block', 'none'] }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <BackIcon_Mobile />
        </IconButton>

        <SubHeader
          sx={{
            mb: '10px',
          }}
        >
          {title}
        </SubHeader>
      </Stack>
    </>
  );
}
//for mobile UI//

//Global PageNavigator UI//
export function PageNavigator({ title, subtitle, backHandler, sx }: HomePageProps) {
  const navigate = useNavigate();
  return (
    <>
      <Stack
        sx={(theme: Theme) => ({
          flexDirection: 'row',
          alignItems: 'center',

          [theme.breakpoints.up(767)]: {
            mb: '20px',
          },
          [theme.breakpoints.between(768, 900)]: {
            mt: '35px',
          },
          [theme.breakpoints.down(767)]: {
            mt: '30px',
            mb: '20px',
          },
          ...sx?.(theme),
        })}
      >
        <Box sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>
          <IconButton onClick={backHandler || (() => navigate(-1))}>
            <BackIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
          <IconButton onClick={backHandler || (() => navigate(-1))}>
            <BackIcon_Mobile />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '20px', lg: '24px', xl: '26px' },
            fontWeight: 500,
            ml: { xs: '10px', md: '0' },
          }}
        >
          {title}
        </Typography>
      </Stack>
      {subtitle && (
        <Typography
          sx={{
            color: 'primary.main',
            mb: 2,
            fontSize: { xs: '14px', sm: '16px', xl: '18px', fontWeight: 500 },
          }}
        >
          {subtitle}
        </Typography>
      )}
    </>
  );
}
//Global PageNavigator UI//
