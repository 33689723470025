import { alpha, createTheme, ThemeProvider } from '@mui/material/styles';

declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface PaletteColor {
    main: string;
    dark: string;
    light: string;
  }

  // eslint-disable-next-line no-unused-vars
  interface TypeText {
    primary: string;
    disabled: string;
    loginBlueText: string;
    dialogTitle: string;
    sectionLabel: string;
    labelColor: string;
    labelColor2: string;
    buttonBGColor: string;
    buttonColor: string;
    inputTextColor: string;
    headerWhiteText: string;
    borderColorLight: string;
    borderColorDark: string;
    mainLight: string;
    mainLight2: string;
    valueColor: string;
    boxColorLight: string;
    inputLabelText: string;
    navyBlue: string;
    dodgerBlue: string;
    crimson: string;
    emerald: string;
    crimsonShade: string;
    transparencyBlue: string;
    darkBlue: string;
    boxColor: string;
    grayishBlue: string;
    lightGrayishBlue: string;
    darkGray: string;
    shadyGray: string;
    lightGrayishBlue2: string;
    rightProfileCard: string;
    sliderColor: string;
    stepperLine: string;
    lightGray: string;
    steelBlue: string;
    passwordText: string;
    greenShade: string;
    tableBgColor: string;
    lightShadeGreen: string;
    lightShadeAmber: string;
    amber: string;
    coral: string;
    lightBlue: string;
    babyBlue: string;
    lightShadeBlue: string;
    denimBlue: string;
    turquoiseBlue: string;
    lightBlue2: string;
    paleAqua: string;
    skyBlue: string;
    clearText: string;
    labelColor3: string;
    errorText: string;
    lavender: string;
    lightSkyBlue: string;
    pastelYellow: string;
    darkGrayBlue: string;
    darkOrange: string;
    darkGreen: string;
    mediumDarkBlue: string;
    aquaBlue: string;
    ceruleanBlue: string;
    whiteLight: string;
    darkAzureBlue: string;
    lightGray2: string;
    darkGray2: string;
    lightGray3: string;
    lightShadeBlue2: string;
    mediumGray: string;
    charcoalBlack: string;
    powderBlue: string;
    paleGray: string;
    paleOrange: string;
    vividOrange: string;
    borderColorLightBlue: string;
    lightGray4: string;
    slateBlue: string;
    inputLabelColor: string;
    radioTextColor: string;
    gradientSkyBlue: string;
    boxBgColor: string;
    dashboardText: string;
    dashboardBoxBg: string;
  }
  // allow configuration using `createTheme`
  // eslint-disable-next-line no-unused-vars
  interface PaletteColorOptions {
    main?: string;
    dark?: string;
    light?: string;
  }
  // eslint-disable-next-line no-unused-vars
  interface TypeBackground {
    primaryLinearGradient: string;
    primaryLinearGradient2: string;
    primaryLinearGradient3: string;
    primaryLinearGradient4: string;
    whiteLightOpacity: string;
    shadeBlue: string;
  }
}

export function AppThemeContextProvider({ children }: { children: React.ReactNode }) {
  const primaryText = '#111927';
  const lightText = '#FFFFFF';
  const mainTheme = '#2057A6';
  const blackText = '#000000';
  const buttonColor = '#283D7C';
  const inputbaseColor = '#8E8EA1';
  const whiteLight = '#F5F5F5';
  const headerWhiteText = '#FFF';
  const dodgerBlue = '#337FC9';
  const linearGradient = 'linear-gradient(180deg, #215EAB 0%, #0FD1D6 100%)';
  const linearGradient2 = 'linear-gradient(90deg, #215EAB 0%, #0FD1D6 100%)';
  const linearGradient3 = 'linear-gradient(270deg, #215EAB 100%, #0FD0D6 100%)';
  const linearGradient4 = 'linear-gradient(90deg, #215EABAB 0%, #0FD0D5 100%)';

  const defaultTheme = createTheme();
  defaultTheme.shadows.pop();
  defaultTheme.shadows.pop();
  defaultTheme.shadows.push('0px 2px 2px rgba(0, 0, 0, 0.2)');
  defaultTheme.shadows.push('0px 4px 28px 2px rgba(0, 0, 0, 0.05)');
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1700,
      },
    },
    shadows: [...defaultTheme.shadows],
    palette: {
      background: {
        default: '#FBFCFF',
        primaryLinearGradient: linearGradient,
        primaryLinearGradient2: linearGradient2,
        primaryLinearGradient3: linearGradient3,
        primaryLinearGradient4: linearGradient4,

        whiteLightOpacity: alpha(whiteLight, 0.5),
        shadeBlue: 'rgba(173, 204, 233, 0.10)',
      },
      primary: {
        main: mainTheme,
        light: lightText,
        dark: blackText,
      },
      success: {
        main: '#00C95C',
        light: '#DBF6E5',
        dark: '#23D17E',
      },
      error: {
        main: '#ca4d4d',
        dark: '#CB1919',
        light: '#CB3833',
      },
      warning: {
        main: '#f2b705',
      },
      text: {
        primary: primaryText,
        disabled: alpha(primaryText, 0.6),
        mainLight: alpha(mainTheme, 0.33),
        mainLight2: alpha(mainTheme, 0.2),
        dialogTitle: '#344B5C',
        buttonColor: buttonColor,
        whiteLight: whiteLight,
        labelColor: '#6C737F',
        labelColor2: '#637381',
        labelColor3: '#6A6A6A',
        buttonBGColor: buttonColor,
        inputTextColor: inputbaseColor,
        borderColorLight: '#cdcdcd',
        borderColorDark: '#eeeeee',
        valueColor: '#212b36',
        boxColorLight: '#E3F5FF',
        inputLabelText: '#404040',
        navyBlue: '#00579B',
        transparencyBlue: alpha(dodgerBlue, 0.35),
        crimson: '#FF575D',
        crimsonShade: '#f44336',
        dodgerBlue: dodgerBlue,
        darkBlue: '#215EAB',
        boxColor: '#E9F5FE',
        grayishBlue: '#293139',
        lightGrayishBlue: '#EBEEF4',
        babyBlue: '#F3F9FE',
        lightGrayishBlue2: '#ECEEF5',
        darkGray: '#828282',
        darkGray2: '#4f4f4f',
        shadyGray: '#C8C8C8',
        rightProfileCard: '#FAFCFD',
        sliderColor: '#E7EEF6',
        stepperLine: '#D9D9D9',
        passwordText: '#6d6d6d',
        greenShade: '#22C55E',
        tableBgColor: '#F4F6F8',
        lightShadeGreen: '#F0FDF9',
        lightShadeAmber: '#FFFAEB',
        amber: '#FFAB00',
        coral: '#FEF3F2',
        lightBlue: '#EAF6FD',
        lightShadeBlue2: '#E6F7FF',
        lightShadeBlue: '#1E6BB0',
        denimBlue: '#2161AC',
        turquoiseBlue: '#3FDDE0',
        lightBlue2: '#DBF1FD',
        paleAqua: '#C5FCE2',
        skyBlue: '#AAC5F8',
        lightSkyBlue: '#A7E5FE',
        powderBlue: '#C3D7EA',
        clearText: '#FF5630',
        errorText: '#FF2121',
        lavender: '#C6A1FC',
        pastelYellow: '#FFE089',
        lightGray: '#F6F6F6',
        lightGray2: '#F9F9F9',
        darkGrayBlue: '#48535B',
        darkOrange: '#A96B0C',
        darkGreen: '#289967',
        mediumDarkBlue: '#1D6DB0',
        aquaBlue: '#10C1D0',
        ceruleanBlue: '#1D6CB0',
        darkAzureBlue: '#043B72',
        lightGray3: '#F4F6F8',
        mediumGray: '#888888',
        charcoalBlack: '#222222',
        paleGray: '#E3E3E3',
        paleOrange: '#FFE5BD',
        vividOrange: '#EE7026',
        borderColorLightBlue: '#7fa2cf',
        lightGray4: '#cccccc',
        slateBlue: '#3A4D78',
        inputLabelColor: '#6D7480',
        radioTextColor: '#26303C',
        gradientSkyBlue: '#0FD0D6',
        boxBgColor: '#EAF8FF',
        dashboardText: '#696984',
        dashboardBoxBg: '#E9F6F7',
      },
      divider: 'rgba(1, 30, 51, 0.2)',
    },
    typography: {
      fontFamily: ['Roboto', 'Nunito', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background: lightText,
            color: primaryText,
            fontSize: 14,
            fontWeight: 400,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: primaryText,
            fontSize: 14,
            fontWeight: 400,
          },
          h1: {
            fontSize: 60,
            fontWeight: 700,
            [defaultTheme.breakpoints.up(1700)]: {
              fontSize: 65,
            },
            [defaultTheme.breakpoints.between(768, 1024)]: {
              fontSize: 50,
            },
            [defaultTheme.breakpoints.down('md')]: {
              fontSize: 30,
            },
            [defaultTheme.breakpoints.down('sm')]: {
              fontSize: 28,
            },
          },
          h2: {
            fontSize: 26,
            fontWeight: 600,
            [defaultTheme.breakpoints.between(768, 1024)]: {
              fontSize: 24,
            },
            [defaultTheme.breakpoints.down('sm')]: {
              fontSize: 22,
            },
          },
          h3: {
            fontSize: 24,
            fontWeight: 500,
            [defaultTheme.breakpoints.up(1700)]: {
              fontSize: 26,
            },
            [defaultTheme.breakpoints.between(768, 1024)]: {
              fontSize: 20,
            },
            [defaultTheme.breakpoints.down('sm')]: {
              fontSize: 18,
            },
          },
          h4: {
            fontSize: 22,
            fontWeight: 500,
            [defaultTheme.breakpoints.up(1700)]: {
              fontSize: 26,
            },
            [defaultTheme.breakpoints.between(768, 1024)]: {
              fontSize: 18,
            },
            [defaultTheme.breakpoints.down('sm')]: {
              fontSize: 16,
            },
          },
          h5: {
            fontSize: 18,
            fontWeight: 500,
            [defaultTheme.breakpoints.up(1700)]: {
              fontSize: 22,
            },
            [defaultTheme.breakpoints.between(768, 1024)]: {
              fontSize: 16,
            },
            [defaultTheme.breakpoints.down(1200)]: {
              fontSize: 14,
            },
            [defaultTheme.breakpoints.down('sm')]: {
              fontSize: 12,
            },
          },
          subtitle1: {
            fontSize: 16,
            fontWeight: 500,
            [defaultTheme.breakpoints.up(1700)]: {
              fontSize: 18,
            },
            [defaultTheme.breakpoints.down(1200)]: {
              fontSize: 14,
            },
          },
          subtitle2: {
            fontSize: 14,
            fontWeight: 500,
            [defaultTheme.breakpoints.up(1700)]: {
              fontSize: 16,
            },
            [defaultTheme.breakpoints.down(1200)]: {
              fontSize: 12,
            },
          },
          body2: {
            fontSize: 16,
            fontWeight: 600,
            [defaultTheme.breakpoints.down(1200)]: {
              fontSize: 14,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'unset',
            borderRadius: 8,
            fontSize: 14,
            fontWeight: 500,
            [defaultTheme.breakpoints.up(1600)]: {
              fontSize: 16,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: buttonColor,
            fontSize: 14,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: mainTheme,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked': { color: mainTheme },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiSvgIcon-root': {
              color: '#666',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            'color': primaryText,
            'background': lightText,
            'border': '1px solid',
            'borderColor': ' #cdcdcd',
            'borderRadius': 8,
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              appearance: 'none', // Hide the increment and decrement buttons
            },
            '&[type="number"]': {
              appearance: 'textfield',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#6C737F',
            fontSize: 12,
            marginBottom: 4,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            display: 'flex',
            justifyContent: 'space-between',
            [defaultTheme.breakpoints.up('sm')]: {
              paddingLeft: '0px',
              paddingRight: '0px',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            'borderBottom': '1px solid',
            'borderColor': '#f5f5f5',
            '& .MuiTabs-flexContainer': {
              [defaultTheme.breakpoints.up('lg')]: {
                paddingLeft: 24,
              },
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: '#949494',
            textTransform: 'unset',
            fontWeight: 500,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            'fontSize': 14,
            '&:not(:last-of-type)': {
              borderBottom: '1px solid #F2F2F7',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: 0,
          },
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
