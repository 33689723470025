import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FieldArray, useField } from 'formik';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { ReactComponent as AddIcon } from '../../../../assets/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/edit-icon.svg';
import {
  StepperAddButton,
  StepperNextButton,
} from '../../../auth/login/styles/styledComponents';
import FormCheckbox from '../../../forms/FormCheckbox';
import FormRadioButton from '../../../forms/FormRadioButton';
import { FormSelectInput } from '../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../forms/FormStyledRadioButton';
import FormTextInput from '../../../forms/FormTextInput';
import { FatcaInitialValues } from '.';

const taxId = {
  country: '',
  taxId: '',
  idType: '',
  tinReason: '',
  tinRemarks: '',
  tinNotAvailable: false,
  tinIdType: '',
  reasonIndex: '0',
};

const tinNotAvailableReasons = [
  'The country where the account holder is liable to pay tax does not issue TIN to its residents',
  'TIN not required (Applicable only if the authorities of the respective country of tax residency do not require TIN to be collected)',
  'Others (Please specify reason)',
];

interface TaxDetailsProps {
  countryOptions: {
    label: string;
    value: string;
  }[];
  initialValues: typeof FatcaInitialValues;
  setValues: (values: typeof FatcaInitialValues) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}

export interface TaxDetailsRef {
  checkAllSaved: () => boolean;
}

const TaxDetails = forwardRef<TaxDetailsRef, TaxDetailsProps>(
  ({ countryOptions, initialValues: values, setValues, setFieldValue }, ref) => {
    const [savedTaxIds, setSavedTaxIds] = useState([false]);

    function checkAllSaved() {
      return !savedTaxIds.some(val => val === false);
    }

    useImperativeHandle(ref, () => ({
      checkAllSaved,
    }));

    const [{ value }, { error }, { setValue }] = useField<Array<any>>('taxResidency');

    const isValid = !error;

    return (
      <>
        <FieldArray name='taxResidency'>
          {({ remove, push }) => (
            <Box
              sx={{
                borderTop: '0.5px dashed',
                borderColor: 'text.borderColorLight',
                width: '100%',
                mt: 4,
              }}
            >
              {value.map((_, index) => (
                <React.Fragment key={index}>
                  {savedTaxIds[index] ? (
                    <>
                      <Box
                        sx={{
                          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.20)',
                          borderRadius: '7px',
                          padding: '15px 15px 20px 15px',
                          mt: 4,
                        }}
                      >
                        <Stack
                          sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: '14px', xl: '16px' },
                              fontWeight: 600,
                              color: 'primary.main',
                            }}
                          >
                            Tax Identification ID - {index + 1}
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() =>
                                setSavedTaxIds((prev: boolean[]) =>
                                  prev.map((taxIdSavedOrNot, i) =>
                                    i === index ? false : taxIdSavedOrNot,
                                  ),
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            {index !== 0 && (
                              <IconButton
                                onClick={() => {
                                  setSavedTaxIds(prev =>
                                    prev.filter((_, i) => i !== index),
                                  );
                                  remove(index);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Stack>
                        <Grid
                          container
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <Typography sx={{ color: 'text.darkGray2', mb: 1 }}>
                              Country Of Tax Residency:
                            </Typography>

                            <Typography sx={{ color: 'text.darkGray2', fontWeight: 600 }}>
                              {value[index].country || 'NA'}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Typography sx={{ color: 'text.darkGray2', mb: 1 }}>
                              Tax ID:
                            </Typography>

                            <Typography sx={{ color: 'text.darkGray2', fontWeight: 600 }}>
                              {value[index].taxId || 'NA'}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Typography sx={{ color: 'text.darkGray2', mb: 1 }}>
                              Type:
                            </Typography>

                            <Typography sx={{ color: 'text.darkGray2', fontWeight: 600 }}>
                              {value[index].taxId !== ''
                                ? value[index].tinIdType === 'tinNumber'
                                  ? 'TIN'
                                  : value[index].tinIdType
                                : 'NA'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          backgroundColor: 'text.sliderColor',
                          borderRadius: '7px',
                          padding: '10px 15px',
                          mt: 3,
                        }}
                      >
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                            }}
                          >
                            {value.length === 1
                              ? 'Tax identification ID'
                              : `Tax identification ID - ${index + 1}`}
                          </Typography>
                          {index !== 0 && (
                            <IconButton
                              onClick={() => {
                                setSavedTaxIds(prev =>
                                  prev.filter((_, i) => i !== index),
                                );
                                remove(index);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Box>

                      <Grid
                        container
                        spacing={2}
                        my={2}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                        >
                          <FormSelectInput
                            name={`taxResidency.${index}.country`}
                            defaultValue={''}
                            label='Country of tax residency'
                            required
                            options={countryOptions}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                        >
                          <FormTextInput
                            disabled={(value[index] as typeof taxId).tinNotAvailable}
                            name={`taxResidency.${index}.taxId`}
                            label='Tax Identification Number/Equivalent'
                            typeOfInput='alphanumeric'
                          />
                        </Grid>
                      </Grid>

                      <FormCheckbox
                        name={`taxResidency.${index}.tinNotAvailable`}
                        label='Tax Identification Number not available'
                        margin='dense'
                        onChange={() => setFieldValue(`taxResidency.${index}.taxId`, '')}
                      />

                      {!(value[index] as typeof taxId).tinNotAvailable ? (
                        <>
                          <Typography
                            sx={{
                              my: 2,
                              color: 'primary.main',
                              fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                              fontWeight: 500,
                            }}
                          >
                            Identification Type
                          </Typography>
                          <FormStyledRadioButton
                            name={`taxResidency.${index}.tinIdType`}
                            options={[
                              { label: 'Tax Identification Number', value: 'tinNumber' },
                              { label: 'Others(please specify)', value: 'others' },
                            ]}
                          />
                          {(value[index] as typeof taxId).tinIdType === 'others' && (
                            <Grid
                              container
                              spacing={2}
                              my={'15px'}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={6}
                              >
                                <FormTextInput
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      padding: '8px 16px',
                                    },
                                  }}
                                  placeholder='Type here'
                                  required={false}
                                  name={`taxResidency.${index}.tinRemarks`}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{
                              my: 1,
                              color: 'text.valueColor',
                              fontWeight: 500,
                              fontSize: { xs: '14px', xl: '16px' },
                            }}
                          >
                            Please select the reason:
                          </Typography>
                          <FormRadioButton
                            name={`taxResidency.${index}.reasonIndex`}
                            onChange={(e: any) => {
                              setValue(
                                value.map((datum, i) => ({
                                  ...datum,
                                  ...(i === index
                                    ? { tinReason: '', reasonIndex: e.target.value }
                                    : {}),
                                })),
                              );
                            }}
                            options={tinNotAvailableReasons.map((reason, index) => ({
                              label: reason,
                              value: `${index}`,
                            }))}
                            label={''}
                          />

                          {(value[index] as typeof taxId).reasonIndex === '2' && (
                            <Grid
                              container
                              spacing={2}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={10}
                              >
                                <FormTextInput
                                  multiline
                                  rows={4}
                                  placeholder='Specify here'
                                  required={false}
                                  name={`taxResidency.${index}.tinReason`}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <Stack
                    direction='row'
                    columnGap='20px'
                    my='30px'
                  >
                    {index === value.length - 1 && index < 2 && (
                      <StepperAddButton
                        startIcon={<AddIcon />}
                        onClick={() => {
                          setSavedTaxIds(prev => [...prev, false]);
                          push(taxId);
                        }}
                        sx={{
                          flex: { xs: '1', sm: 'unset' },
                          minWidth: { xs: 'unset', sm: '200px' },
                          padding: '8px 16px',
                        }}
                      >
                        Add another id
                      </StepperAddButton>
                    )}
                    {!savedTaxIds[index] && (
                      <StepperNextButton
                        sx={{
                          flex: { xs: '1', sm: 'unset' },
                          minWidth: { xs: 'unset', sm: '200px' },
                          padding: '8px 16px',
                        }}
                        // disabled={!isValid}
                        onClick={() => {
                          setSavedTaxIds((prev: boolean[]) =>
                            prev.map((taxIdSavedOrNot, i) =>
                              i === index ? true : taxIdSavedOrNot,
                            ),
                          );
                          setValues({
                            ...values,
                            taxResidency: value.map((tR: any, i: number) => {
                              if (i === index)
                                return {
                                  ...tR,
                                  idType: `TAXID${index + 1}`,
                                  ...(!tR.tinNotAvailable
                                    ? {
                                        ...(tR.tinIdType !== 'others'
                                          ? { tinRemarks: '' }
                                          : {}),
                                      }
                                    : {
                                        tinRemarks: '',
                                        ...(tR.reasonIndex !== '2'
                                          ? {
                                              tinReason:
                                                tinNotAvailableReasons[
                                                  parseInt(tR.reasonIndex ?? '0')
                                                ],
                                            }
                                          : {}),
                                      }),
                                };
                              return tR;
                            }),
                          });
                        }}
                      >
                        Save
                      </StepperNextButton>
                    )}
                  </Stack>
                </React.Fragment>
              ))}
            </Box>
          )}
        </FieldArray>
      </>
    );
  },
);

export default TaxDetails;
