import { Box, CardMedia, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import AmountIcon from '../../../assets/amount-coin.svg';
import DatePickerIcon from '../../../assets/date-picker.svg';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import MobileInvestorProfile from '../../common/UI/MobileInvestorProfile';
import { useRootContext } from '../../data/root.context';
import Layout from '../Layout';
import { useGetIndividualSchemeDetailsMutation } from '../slice';
import { SchemeDetails } from '../types';
import InvestmentDetailsForm from './InvestmentDetailsForm';

export default function InvestmentDetails() {
  const { state } = useLocation();
  const [investorDetails, setInvestorDetails] = useState<SchemeDetails | null>(null);
  const [individualSchemeDetails] = useGetIndividualSchemeDetailsMutation();
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const { showToast } = useRootContext();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      dispatch(startLoading());

      const payload = {
        folio: state?.schemeDetails?.folioNo,
        pan: pan,
        fund: state?.schemeDetails?.fund,
        trtype: 'RED',
        scheme: state?.schemeDetails?.scheme,
        option: state?.schemeDetails?.schemeOption,
        plan: state?.schemeDetails?.schemePlan,
      };
      try {
        const response = await individualSchemeDetails(payload).unwrap();
        setInvestorDetails(response);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }

    getInvestorDetailsSchemes();
  }, []);

  const RightBottomComp = ({ schemaData }: any) => {
    const availableUnits = schemaData ? schemaData.units : 0;
    const availableAmount = schemaData ? availableUnits * schemaData.nav : 0;
    const formattedAmount = availableAmount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return (
      <Box
        sx={{
          padding: '30px 20px',
          borderTop: '1px solid',
          borderColor: 'text.borderColorDark',
        }}
      >
        <Stack
          direction='column'
          gap='16px'
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: 500,
                fontSize: { xs: '16px', xl: '18px' },
              }}
            >
              Redeem Scheme
            </Typography>
            <CardMedia
              sx={{
                '&.MuiCardMedia-root': {
                  width: '12%',
                },
              }}
              component='img'
              image={AmountIcon}
              alt='amount'
            />
          </Stack>
          {schemaData?.schemeDesc && (
            <Typography
              sx={{
                color: 'text.valueColor',
                fontWeight: 500,
                fontSize: { xs: '14px', xl: '16px' },
              }}
            >
              {schemaData?.schemeDesc || 'NA'}
            </Typography>
          )}
          {schemaData?.folioNo && (
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Folio Number</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {schemaData?.folioNo || 'NA'}
              </Typography>
            </Stack>
          )}
          <Stack gap='5px'>
            <Typography sx={{ color: 'text.labelColor' }}>Total Balance Units</Typography>
            <Typography
              sx={{
                color: 'text.valueColor',
                fontWeight: 500,
                fontSize: { xs: '14px', xl: '16px' },
              }}
            >
              {availableUnits}
            </Typography>
          </Stack>
          {formattedAmount && (
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Total Amount</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {formattedAmount}
              </Typography>
            </Stack>
          )}
          {schemaData?.nav && (
            <>
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>Current Nav</Typography>
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  ₹{schemaData?.nav || 'NA'}
                </Typography>
              </Stack>
              <Stack
                gap='5px'
                direction='row'
                alignItems='center'
              >
                <img
                  src={DatePickerIcon}
                  alt='date-picker'
                />
                <Typography
                  sx={{
                    color: 'text.labelColor3',
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                >
                  As on Date
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    );
  };

  return (
    <Layout
      title='Investment Details'
      subtitle='Please enter the investment details'
      rightBottomComponent={<RightBottomComp schemaData={investorDetails} />}
    >
      {/* Mobile UI Investor Profile  */}
      <MobileInvestorProfile
        commercialTransactionTitle='Redeem'
        data={investorDetails}
      />
      {/* Mobile UI Investor Profile  */}
      <Typography
        variant='subtitle1'
        sx={{ color: 'primary.main', mb: 2, display: { xs: 'block', sm: 'none' } }}
      >
        Please enter the investment details
      </Typography>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Investorcard
            sx={theme => ({
              padding: '30px',
              [theme.breakpoints.down('md')]: {
                border: 'unset',
                boxShadow: 'unset',
                padding: '0px',
              },
            })}
          >
            <InvestmentDetailsForm
              data={investorDetails}
              isEditMode={false}
              isFromCart={state.isFromcart}
            />
          </Investorcard>
        </Grid>
      </Grid>
    </Layout>
  );
}
