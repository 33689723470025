import { Grid, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import {
  filterMandatesForLumpsum,
  filterPaymentModesForLumpsum,
  filterRegistrationMethodsSIP,
  removeKotmFromPaymentModes,
} from '../additionalPurchase/common';
import {
  AllMandateData,
  CheckoutPaymentFormValues,
  Mandate,
  PaymentOption,
} from '../additionalPurchase/types';
import {
  useGetInvestorMandatesMutation,
  useGetPaymentModesByFundMutation,
} from '../slice';
import { AlpModeType, AplTrtType } from '../types';
import { AlpPaymentModesPayload } from './types';

interface PaymentModesFormProps {
  aplPaymentsModePayload: AlpPaymentModesPayload;
  formikRef?: React.RefObject<FormikProps<CheckoutPaymentFormValues>> | null;
  selectedMandate: Mandate;
  setSelectedMandate: React.Dispatch<React.SetStateAction<Mandate>>;
}
const validationSchema = Yup.object().shape({
  paymentMode: Yup.string().required('Mode of Payment is required'),
  umrn: Yup.string().when('paymentMode', {
    is: (paymentMode: string) => paymentMode === 'kotm',
    then: Yup.string().required('UMRN is required'),
    otherwise: Yup.string().nullable(),
  }),
});
type Option = {
  label: string;
  value: string;
};
const PaymentModesForm = ({
  aplPaymentsModePayload,
  formikRef = null,
  selectedMandate,
  setSelectedMandate,
}: PaymentModesFormProps) => {
  const [allPaymentOptions, setAllPaymentOptions] = useState<PaymentOption[]>([]);
  const [registrationOptions, setRegistrationOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [getPaymentModesByFund] = useGetPaymentModesByFundMutation(); // Payment modes
  const { showToast } = useRootContext();
  const dispatch = useDispatch();

  const [allMandateData, setAllMandateData] = useState<AllMandateData>({
    kotmData: [],
    umrnOptions: [],
    aotmData: [],
  });
  const [getInvestorMandates] = useGetInvestorMandatesMutation();
  const currentTrtType = aplPaymentsModePayload.trtype;
  const isFirstRender = useRef<boolean>(false);
  const initialPaymentOptionsRef = useRef<Option[]>([]);
  const selectedModeOfRegistration = initialPaymentOptionsRef.current?.filter(
    method =>
      aplPaymentsModePayload?.modeOfRegistration?.toLowerCase() ===
      method.value.toLowerCase(),
  );

  const initialValues: CheckoutPaymentFormValues = {
    paymentMode: '', // Selected Payment Option.
    umrn: '', // Selected Mandate.
    modeOfRegistration: selectedModeOfRegistration?.[0]?.value || '',
    selectedMandateObj: {
      label: '',
      value: '',
      mandateLimit: 0,
      startDate: '',
      endDate: '',
      bankAccountNumber: '',
      bankName: '',
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(startLoading());
        const { folio, fund, pan, trtype, amount } = aplPaymentsModePayload;
        const paymentModesResponse = await getPaymentModesByFund({
          fund: aplPaymentsModePayload.fund,
        }).unwrap();
        let initialPaymentOptions = Object.entries(paymentModesResponse)
          .filter(([_, value]) => value === 'Y')
          .map(([key, _]) => ({ label: key.toUpperCase(), value: key }));
        initialPaymentOptionsRef.current = initialPaymentOptions;

        // Fetch investor mandates response

        const mandatesResponse = await getInvestorMandates({
          folio: String(folio),
          fund,
          pan,
          trtype: 'APL',
        }).unwrap();
        let filteredMandates = mandatesResponse?.kotmData;
        let paymentOptions,
          registrationModes = initialPaymentOptions;
        // Filterout all mandates and payment, registration modes according to trtype.
        if (trtype === 'APL' || trtype === 'SIN') {
          // For lumpsum , and SIP+Lumpsum
          paymentOptions = filterPaymentModesForLumpsum(initialPaymentOptions);
          filteredMandates = mandatesResponse?.kotmData.filter((mandate: Mandate) =>
            filterMandatesForLumpsum(Number(amount), mandate),
          );
        }
        if (trtype === 'ISIP' || trtype === 'SIN') {
          registrationModes = filterRegistrationMethodsSIP(
            initialPaymentOptions,
            aplPaymentsModePayload?.isPanBasedAOTM as boolean,
            aplPaymentsModePayload?.modeOfRegistration,
            aplPaymentsModePayload?.isNriInvestor as boolean,
          );
        }

        setRegistrationOptions(registrationModes); // Filtered Registration Modes
        setAllPaymentOptions(paymentOptions); // Fetched payment modes

        const options = filteredMandates?.map((item: any) => {
          return { label: item.umrn, value: item.umrn };
        });
        options.unshift({ label: '+ Create New Mandate', value: 'createMandate' });
        setAllMandateData({
          ...allMandateData,
          aotmData: mandatesResponse?.aotmData,
          kotmData: mandatesResponse?.kotmData,
          umrnOptions: options,
        });
        isFirstRender.current = true;
      } catch (error: any) {
        showToast(error.data?.message ?? 'Unknown error', 'error');
      } finally {
        dispatch(stopLoading());
      }
    };
    if (aplPaymentsModePayload.fund !== '' && !isFirstRender.current) {
      fetchData();
    }
  }, [aplPaymentsModePayload]);

  const handleMandateChange = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    const value = e.target.value;
    const selectedValue = allMandateData?.kotmData?.find(
      (mandate: any) => mandate.umrn === value,
    );
    if (value !== 'createMandate') {
      setSelectedMandate({
        label: value,
        value: value,
        mandateLimit: selectedValue?.amount ?? 0,
        startDate: selectedValue?.fromDate || '',
        endDate: selectedValue?.toDate || '',
        bankAccountNumber: selectedValue?.bnkacno || '',
        bankName: selectedValue?.bank || '',
      });
      setFieldValue('selectedMandateObj', {
        label: value,
        value: value,
        mandateLimit: selectedValue?.amount ?? 0,
        startDate: selectedValue?.fromDate || '',
        endDate: selectedValue?.toDate || '',
        bankAccountNumber: selectedValue?.bnkacno || '',
        bankName: selectedValue?.bank || '',
      });
    } else {
      setSelectedMandate({
        label: '+ Create New Mandate',
        value: 'createMandate',
        startDate: '',
        endDate: '',
        mandateLimit: 0,
        bankAccountNumber: '',
        bankName: '',
      });
      setFieldValue('selectedMandateObj', {
        label: '+ Create New Mandate',
        value: 'createMandate',
        startDate: '',
        endDate: '',
        mandateLimit: 0,
        bankAccountNumber: '',
        bankName: '',
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
        innerRef={formikRef}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Grid
                container
                spacing={2}
              >
                {currentTrtType !== 'APL' && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Typography
                      sx={{
                        mb: 1.5,
                        color: 'text.inputLabelText',
                        fontSize: { xs: '14px', xl: '16px' },
                        fontWeight: 500,
                      }}
                    >
                      Mode of Registration
                    </Typography>
                    <FormSelectInput
                      name='modeOfRegistration'
                      label='Mode of Registration'
                      defaultValue={''}
                      options={registrationOptions}
                    />
                  </Grid>
                )}
                {currentTrtType !== 'ISIP' && (
                  <Grid
                    item
                    xs={12}
                    sx={{ mb: 1 }}
                  >
                    <Typography
                      sx={{
                        mb: 1.5,
                        color: 'text.inputLabelText',
                        fontSize: { xs: '14px', xl: '16px' },
                        fontWeight: 500,
                      }}
                    >
                      Select Payment Mode *
                    </Typography>
                    <FormStyledRadioButton
                      options={
                        values.modeOfRegistration === 'ISIP'
                          ? removeKotmFromPaymentModes(allPaymentOptions)
                          : allPaymentOptions
                      }
                      name='paymentMode'
                      // handleChange={handlePaymentMode}
                    />
                  </Grid>
                )}
                {((AplTrtType[currentTrtType as keyof typeof AplTrtType] === 'Lumpsum' &&
                  values?.paymentMode === 'kotm') ||
                  (values.modeOfRegistration === 'kotm' &&
                    AplTrtType[currentTrtType as keyof typeof AplTrtType] !==
                      'Lumpsum')) && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      mb: 2,
                      mt: {
                        sm:
                          AplTrtType[currentTrtType as keyof typeof AplTrtType] !==
                          'Lumpsum'
                            ? 4
                            : 0,
                      },
                    }}
                  >
                    <FormSelectInput
                      name='umrn'
                      label='Select Mandate'
                      required
                      options={
                        AplTrtType[currentTrtType as keyof typeof AplTrtType] ===
                          'Lumpsum' ||
                        (AlpModeType[currentTrtType as keyof typeof AlpModeType] ===
                          'LumpsumAndSIP' &&
                          values.paymentMode === 'kotm')
                          ? allMandateData?.umrnOptions?.slice(1)
                          : allMandateData?.umrnOptions
                      }
                      onChange={e => handleMandateChange(e, setFieldValue)}
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
    // </CustomDialog>
  );
};
export default PaymentModesForm;
