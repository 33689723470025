import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputBaseProps,
  Radio,
  RadioGroup,
  // Stack,
  Typography,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { ReactElement } from 'react';

export type FormRadioOptions = Array<{ label: string; value: any }>;
export default function FormRadioButton({
  name,
  label,
  options = [],
}: InputBaseProps & {
  name: string;
  options: FormRadioOptions;
  label: string | ReactElement;
}): JSX.Element {
  const formikContext = useFormikContext();
  const { isSubmitting: disabled } = formikContext;
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;
  return (
    <>
      <Typography
        sx={{
          fontWeight: 500,
          color: 'primary.dark',
        }}
      >
        {label}
      </Typography>
      <FormControl
        error={hasError}
        sx={{ mt: 1 }}
      >
        <RadioGroup
          row
          {...field}
        >
          {options.map(option => (
            <FormControlLabel
              sx={theme => ({
                'mb': 2,
                '& .MuiTypography-root ': {
                  color: 'text.labelColor',
                  fontSize: '14px',
                  fontWeight: '500',
                },
                // '& .MuiButtonBase-root': {
                //   '&.Mui-checked': {
                //     color: `${theme.palette.primary.main}`,
                //   },
                // },
                // '.MuiButtonBase-root.Mui-checked + .MuiTypography-root ': {
                //   color: `${theme.palette.primary.main}`,
                // },
              })}
              key={option.value}
              value={option.value}
              control={
                <Radio
                  disabled={disabled}
                  size='small'
                />
              }
              label={option.label}
            />
          ))}
        </RadioGroup>

        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </>
  );
}
