import { Box, Grid, Stack } from '@mui/material';

import { CartLabel, CartValue } from '../../auth/login/styles/styledComponents';
import { inrCurrencyFormatter } from '../utils';

const Cart = ({
  data,
  children,
  cartItemslength,
}: {
  data?: any;
  children?: React.ReactNode;
  cartItemslength?: boolean;
}) => {
  const trtType = data?.transactionType;
  return (
    <Box
      sx={{
        'pb': 2,
        'mb': 3,
        '&:nth-of-type(n+2)': {
          borderTop: cartItemslength ? '1px solid ' : 'unset',
          borderColor: cartItemslength ? 'text.borderColorLight' : 'unset',
          pt: cartItemslength ? 3 : 'unset',
        },
      }}
    >
      {children}

      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Stack gap='10px'>
            <CartLabel>{data?.fundName}</CartLabel>
            <CartValue>{data?.schemeName}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
        >
          <Stack gap='10px'>
            <CartLabel>Folio Number</CartLabel>
            <CartValue>{data?.folio}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2}
        >
          <Stack gap='10px'>
            <CartLabel>Amount:</CartLabel>
            {/*INFO for SIN: sipAmount: investmentAmount: */}
            <CartValue>
              ₹{' '}
              {trtType === 'ISIP'
                ? inrCurrencyFormatter(data?.installmentAmount)
                : inrCurrencyFormatter(data?.cartAmount)}
            </CartValue>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cart;
