export const reportsOption = [
  {
    label: 'Transaction Report',
    value: 'MFSD201',
  },
  {
    label: 'Net AUM Report',
    value: 'MFSD202',
  },
  {
    label: 'Client-wise AUM Report',
    value: 'MFSD203',
  },
  {
    label: 'Brokerage Report',
    value: 'MFSD205',
  },
  {
    label: 'Investor Master Information',
    value: 'MFSD211',
  },
  {
    label: 'NAV Report',
    value: 'MFSD217',
  },
  {
    label: 'Transaction Wise Investor Master',
    value: 'MFSD221',
  },
  {
    label: 'SIP/STP Report',
    value: 'MFSD230',
  },
];

export const reportTypesOptions = [
  {
    label: 'Excel',
    value: 'Excel',
  },
  {
    label: 'DBF',
    value: 'DBF',
  },
];

export const periodOptions = [
  { label: 'Current Fiscal Year', value: 0 },
  { label: 'Previous Fiscal Year', value: -1 },
  { label: '2nd Last Fiscal Year', value: -2 },
  { label: '3rd Last Fiscal Year', value: -3 },
];
