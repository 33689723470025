import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Form, Formik } from 'formik';
import React from 'react';

import FormOTPInput from '../../../forms/FormOtpInput/FormOTPInput';
import { LinkButton, SubTitle } from '../../login/styles/styledComponents';

interface OtpInputSectionProps {
  OTPHandler: (_value: string) => Promise<void>;
  isDisabledField: boolean;
  isResendDisabled: boolean;
  resendButtonHandler: () => void;
  formattedTime: string;
}

const OtpInputSection: React.FC<OtpInputSectionProps> = ({
  OTPHandler, // OtpHandelr
  isDisabledField, // emailOtp
  isResendDisabled,
  resendButtonHandler,
  formattedTime,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: { xs: '10px' },
        }}
      >
        <Formik
          initialValues={{ otp: ['', '', '', '', '', ''] }}
          onSubmit={values => {
            //TODO: implement this
          }}
        >
          {({ values, errors, resetForm }) => {
            return (
              <Form>
                <FormOTPInput
                  onChange={OTPHandler}
                  disabled={isDisabledField}
                />
                {!isDisabledField && (
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Stack
                      direction='row'
                      gap='4px'
                      alignItems='center'
                    >
                      <SubTitle>Didn’t receive OTP?</SubTitle>
                      <LinkButton
                        disabled={isResendDisabled}
                        onClick={() => {
                          resendButtonHandler();
                          resetForm();
                        }}
                        sx={{
                          opacity: isResendDisabled ? '0.4' : '1',
                          textDecoration: isResendDisabled
                            ? 'none !important'
                            : 'underline',
                        }}
                      >
                        Resend OTP
                      </LinkButton>
                    </Stack>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                          color: 'primary.main',
                          textAlign: 'right',
                          fontWeight: 500,
                        }}
                      >
                        {formattedTime}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default OtpInputSection;
