import { createSlice } from '@reduxjs/toolkit';

export interface ReportTypeInterface {
  reportTo: string;
  tabValue: string;
}

const initialState: ReportTypeInterface = {
  reportTo: 'Myself',
  tabValue: '',
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setReportType(state, action) {
      state.reportTo = action.payload;
    },
    setTabValue(state, action) {
      state.tabValue = action.payload;
    },
    resetReportState: () => initialState,
  },
});

export const { setReportType, setTabValue, resetReportState } = reportSlice.actions;

export default reportSlice.reducer;
