import { Box, CardMedia, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import AmountIcon from '../../../assets/amount-coin.svg';
import DatePickerIcon from '../../../assets/date-picker.svg';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import MobileInvestorProfile from '../../common/UI/MobileInvestorProfile';
import { useRootContext } from '../../data/root.context';
import Layout from '../Layout';
import { useGetIndividualSchemeDetailsMutation } from '../slice';
import { SchemeDetails } from '../types';
import AdditionalPurchaseInvestmentDetailsForm from './InvestmentDetailsForm';

export default function AdditionalPurchaseInvestmentDetails() {
  const { state } = useLocation();
  const [investorDetails, setInvestorDetails] = useState<SchemeDetails | null>(null);
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const [individualSchemeDetails] = useGetIndividualSchemeDetailsMutation();
  const { showToast } = useRootContext();
  const isNewScheme = state?.schemeDetails?.isNewScheme;
  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      try {
        const payload = {
          pan: pan,
          folio: String(state?.schemeDetails?.folio),
          fund: state?.schemeDetails?.fund.trim(),
          scheme: state?.schemeDetails?.scheme,
          option: state?.schemeDetails?.option,
          plan: state?.schemeDetails?.plan,
        };
        const response = await individualSchemeDetails(payload).unwrap();
        setInvestorDetails({ ...response, schemeName: state?.schemeDetails.schemeName });
      } catch (error: any) {
        const { message } = error.data;
        const errorMessage = message || 'An unknown error occurred';
        showToast(errorMessage, 'error');
        setInvestorDetails(null); // Set state to null or some default value
      }
    }
    // if (!isNewScheme) {
    //   getInvestorDetailsSchemes();
    // } else {
    // }
    setInvestorDetails({ ...state?.schemeDetails, pan: pan }); // for new scheme
  }, []);

  const RightBottomComp = ({ schemaData }: any) => {
    return (
      <Box
        sx={{
          padding: '30px 20px',
          borderTop: '1px solid',
          borderColor: 'text.borderColorDark',
        }}
      >
        <Stack
          direction='column'
          gap='16px'
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: 500,
                fontSize: { xs: '16px', xl: '18px' },
              }}
            >
              Purchase Scheme
            </Typography>
            <CardMedia
              sx={{
                '&.MuiCardMedia-root': {
                  width: '12%',
                },
              }}
              component='img'
              image={AmountIcon}
              alt='amount'
            />
          </Stack>
          {schemaData?.schemeDesc ||
            (schemaData?.schemeName && (
              <Typography
                variant='subtitle2'
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {schemaData?.schemeDesc || schemaData?.schemeName || 'NA'}
              </Typography>
            ))}
          {schemaData?.folio && (
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Folio Number</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {schemaData?.folio || 'NA'}
              </Typography>
            </Stack>
          )}
          <Stack gap='5px'>
            <Typography sx={{ color: 'text.labelColor' }}>Total Balance Units</Typography>
            <Typography
              sx={{
                color: 'text.valueColor',
                fontWeight: 500,
                fontSize: { xs: '14px', xl: '16px' },
              }}
            >
              {schemaData?.balanceUnits?.toFixed(2) || '0'}
            </Typography>
          </Stack>
          {schemaData?.availableAmount && (
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Total Amount</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                {schemaData?.availableAmount || 'NA'}
              </Typography>
            </Stack>
          )}

          {schemaData?.nav && (
            <>
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>Current Nav</Typography>
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  ₹{schemaData?.nav || 'NA'}
                </Typography>
              </Stack>
              <Stack
                gap='5px'
                direction='row'
                alignItems='center'
              >
                <img
                  src={DatePickerIcon}
                  alt='date-picker'
                />
                <Typography
                  sx={{
                    color: 'text.labelColor3',
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                >
                  As on Date
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    );
  };
  return (
    <Layout
      title='Investment Details'
      subtitle='  Please enter the investment details'
      rightBottomComponent={<RightBottomComp schemaData={state?.schemeDetails} />}
    >
      {/* Mobile UI Investor Profile  */}
      <MobileInvestorProfile
        commercialTransactionTitle='Additional Purchase'
        data={investorDetails}
      />
      {/* Mobile UI Investor Profile  */}
      <Typography
        variant='subtitle1'
        sx={{ color: 'primary.main', mb: 2, display: { xs: 'block', sm: 'none' } }}
      >
        Please enter the investment details
      </Typography>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Investorcard
            sx={theme => ({
              padding: '30px',

              [theme.breakpoints.down('md')]: {
                border: 'unset',
                boxShadow: 'unset',
                padding: '0px',
              },
            })}
          >
            <AdditionalPurchaseInvestmentDetailsForm
              data={investorDetails}
              isEditMode={false}
              isNewScheme={isNewScheme}
            />
          </Investorcard>
        </Grid>
      </Grid>
    </Layout>
  );
}
