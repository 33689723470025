import {
  Box,
  CardMedia,
  FormControlLabel,
  Grid,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Profile_Admin from '../../../assets/profile-admin.svg';
import Profile_Icon from '../../../assets/profile-avatar.svg';
import {
  Detailslabel,
  DetailsValue,
  Investorcard,
  ToggleSwitch,
} from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { PageNavigator } from '../../common/BackToHomePage';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import AddUsersForm from '../AddUsers/AddUsersForm';
import FilterTabs from '../FilterTabs';
import {
  useAddEmployeeAsAdminMutation,
  useDisabledistrubutorMutation,
  useEnabledistrubutorMutation,
  useRemoveEmployeeAsAdminMutation,
} from '../slice';
interface CheckedState {
  checkAdmin: boolean;
  checkEnable: boolean;
  currentType: string;
}
interface InvestorCardProps {
  checked: { checkAdmin: boolean; checkEnable: boolean };
  handleChange: (type: string) => void;
}
const InvestorCardComponent = ({ checked, handleChange }: InvestorCardProps) => {
  return (
    <Investorcard
      sx={(theme: Theme) => ({
        padding: '35px',
        [theme.breakpoints.down('md')]: {
          padding: '16px',
        },
        [theme.breakpoints.down('sm')]: {
          padding: 0,
          boxShadow: 'unset',
          border: 'unset',
        },
      })}
    >
      <Box
        sx={{
          padding: '4px 8px',
          borderRadius: '5px',
          bgcolor: 'success.light',
          width: '60px',
          ml: 'auto',
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <Typography
          sx={{
            color: 'success.dark',

            fontWeight: 600,
          }}
        >
          Active
        </Typography>
      </Box>
      <CardMedia
        sx={{
          '&.MuiCardMedia-root': {
            width: '38%',
            margin: '0 auto 40px',
            display: { xs: 'none', sm: 'block' },
          },
        }}
        component='img'
        image={checked?.checkAdmin ? Profile_Admin : Profile_Icon}
        alt='Profile'
      />
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={'20px'}
        sx={{
          borderBottom: { xs: '1px solid', sm: 'unset' },
          borderColor: { xs: 'text.borderColorDark', sm: 'unset' },
          padding: { xs: '12px 0px ', sm: 'unset' },
        }}
      >
        <Stack rowGap={'8px'}>
          {/* <Detailslabel sx={{ fontWeight: 500 }}>Activation</Detailslabel> */}
          <DetailsValue>Make as Admin</DetailsValue>
        </Stack>
        <FormControlLabel
          sx={{ mx: 0 }}
          control={
            <ToggleSwitch
              checked={checked?.checkAdmin}
              onChange={() => handleChange('checkAdmin')}
            />
          }
          label=''
        />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={'16px'}
        sx={{
          borderBottom: { xs: '1px solid', sm: 'unset' },
          borderColor: { xs: 'text.borderColorDark', sm: 'unset' },
          padding: { xs: '12px 0px ', sm: 'unset' },
        }}
      >
        <Stack rowGap={'8px'}>
          {/* <Detailslabel sx={{ fontWeight: 500 }}>Activation</Detailslabel> */}
          <DetailsValue>Enable Account</DetailsValue>
        </Stack>
        <FormControlLabel
          sx={{ mx: 0 }}
          control={
            <ToggleSwitch
              checked={checked?.checkEnable}
              onChange={() => handleChange('checkEnable')}
            />
          }
          label=''
        />
      </Stack>
      <Stack
        rowGap={'8px'}
        mb={'16px'}
        sx={{
          borderBottom: { xs: '1px solid', sm: 'unset' },
          borderColor: { xs: 'text.borderColorDark', sm: 'unset' },
          padding: { xs: '12px 0px ', sm: 'unset' },
        }}
      >
        <Detailslabel sx={{ fontWeight: 500 }}>Date of Activation</Detailslabel>
        <DetailsValue>22 August 2021</DetailsValue>
      </Stack>
      <Stack rowGap={'8px'}>
        <Detailslabel sx={{ fontWeight: 500 }}>Last Login Session</Detailslabel>
        <DetailsValue>22 August 2021, 10:15 pm</DetailsValue>
      </Stack>
    </Investorcard>
  );
};
const ProfileSummary = () => {
  const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false);

  const [removeEmployeeAsAdmin] = useRemoveEmployeeAsAdminMutation();
  const [addEmployeeAsAdmin] = useAddEmployeeAsAdminMutation();

  const [disableEmployeeOrSubBroker] = useDisabledistrubutorMutation();
  const [enableEmployeeOrSubBroker] = useEnabledistrubutorMutation();
  const [showVisibility, setShowVisibility] = useState<boolean>(false);
  const {
    state: { selectedRow },
  } = useLocation();
  const [currentTabValue, setCurrentTabValue] = useState<string>('1');
  const { showToast } = useRootContext();
  const { empId, name: empName, subbrokerCode } = selectedRow;
  const [manager1 = {}, manager2 = {}] = selectedRow.Manager || [];
  const { empId: manager1Id } = manager1;
  const { empId: manager2Id } = manager2;
  const isSubbroker = subbrokerCode !== null && subbrokerCode !== '';
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState<CheckedState>({
    checkAdmin: selectedRow.isAdmin,
    checkEnable: selectedRow.active,
    currentType: '',
  });
  const editEnableButtonHandler = () => {
    setIsEditEnabled(!isEditEnabled);
  };
  const toggleHandler = async () => {
    try {
      let response;
      if (checked?.currentType === 'checkAdmin') {
        const actionFunction = !checked?.checkAdmin
          ? addEmployeeAsAdmin
          : removeEmployeeAsAdmin;
        const payload = {
          empId: empId,
        };
        dispatch(startLoading());

        response = await actionFunction(payload).unwrap();

        setChecked({
          ...checked,
          checkAdmin: !checked?.checkAdmin,
        });
      } else {
        const actionFunction = !checked?.checkEnable
          ? enableEmployeeOrSubBroker
          : disableEmployeeOrSubBroker;

        const payload = {
          ...(!checked?.checkEnable
            ? {
                employeeId: isSubbroker ? null : empId,
                subbrokerCode: isSubbroker ? empId : null,
                ...(isSubbroker
                  ? {
                      manager1SubbrokerCode: manager1Id,
                      manager2SubbrokerCode: manager2Id,
                    }
                  : {
                      manager1EmpId: manager1Id,
                      manager2EmpId: manager2Id,
                    }),
              }
            : {
                employeeId: isSubbroker ? null : empId,
                subbrokerCode: isSubbroker ? empId : null,
              }),
        };
        response = await actionFunction(payload).unwrap();

        setChecked(prevChecked => ({
          ...prevChecked,
          checkEnable: !prevChecked?.checkEnable,
        }));
      }

      setShowVisibility(false);
      const messageToShow =
        'message' in response
          ? response.message
          : 'data' in response
          ? response.data.message
          : null;

      if (messageToShow) {
        showToast(messageToShow, 'success');
      }
    } catch (error: any) {
      showToast(
        (error.data as { message: string }).message || 'An error occurred',
        'error',
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const StatusPopUp = () => {
    const status =
      checked?.currentType === 'checkAdmin'
        ? !checked?.checkAdmin
        : !checked?.checkEnable;

    const content = `Are you sure you want to ${
      status ? 'make' : 'remove'
    } this person as ${checked?.currentType === 'checkAdmin' ? 'Admin' : 'Enabled'}`;
    return (
      <CustomDialog
        isVisible={showVisibility}
        firstButtonHandler={() => setShowVisibility(false)}
        firstButtonTitle='No'
        secondButtonTitle='Yes'
        secondButtonHandler={async () => toggleHandler()}
        handleClose={() => setShowVisibility(false)}
        showSecondButton={true}
      >
        <Box>
          <Typography
            sx={{
              mb: 2,
              fontWeight: 500,
              fontSize: { xs: '16px', sm: '18px', xl: '20px' },
            }}
          >
            {status ? 'Make' : 'Remove'} as{' '}
            {checked?.currentType === 'checkAdmin' ? 'Admin' : 'Enabled'}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '16px', xl: '18px' },
              color: 'text.labelColor',
              fontWeight: 400,
              mb: 1,
            }}
          >
            {content}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '16px', xl: '18px' },
              fontWeight: 500,
            }}
          >
            Employee: {empName}, Emp ID: {empId}
          </Typography>
        </Box>
      </CustomDialog>
    );
  };

  const handleChange = async (type: string) => {
    setShowVisibility(true);
    setChecked(prevChecked => ({
      ...prevChecked,
      currentType: type,
    }));
  };
  return (
    <>
      {showVisibility && StatusPopUp()}

      <PageNavigator
        title='Profile Summary'
        backHandler={() => navigate(-1)}
        sx={(theme: Theme) => ({
          [theme.breakpoints.down('md')]: {
            mt: 0,
          },
        })}
      />
      <Box sx={{ display: ['none', 'block'] }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={7}
            sm={4.5}
          >
            <InvestorCardComponent
              checked={checked}
              handleChange={handleChange}
            />
          </Grid>
          {/* EDIT FORM HERE */}
          <Grid
            item
            sm={7.5}
          >
            <Investorcard sx={{ padding: '35px' }}>
              <AddUsersForm
                isEditMode={true}
                isEditEnabled={isEditEnabled}
                showCancel={true}
                buttonTitle={!isEditEnabled ? 'Edit' : 'Cancel'}
                cancelButtonHandler={editEnableButtonHandler}
                selectedRow={selectedRow}
                submitButtonLabel={'Save'}
              />
            </Investorcard>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: ['block', 'none'] }}>
        <CardMedia
          sx={{
            '&.MuiCardMedia-root': {
              width: '38%',
              margin: '0 auto',
              display: { xs: 'block', sm: 'none' },
            },
          }}
          component='img'
          image={checked?.checkAdmin ? Profile_Admin : Profile_Icon}
          alt='Profile'
        />
        <Typography
          sx={{ textAlign: 'center', mb: 2, fontSize: { xs: '22px' }, fontWeight: 500 }}
        >
          {empName}
        </Typography>
        <FilterTabs
          tabValue={currentTabValue}
          onChange={newValue => {
            setCurrentTabValue(newValue);
          }}
          tabsArr={[
            { label: 'Basic Details', value: '1' },
            { label: 'Profile Details', value: '2' },
          ]}
          tabContent={[
            <InvestorCardComponent
              key='investor-card'
              checked={checked}
              handleChange={handleChange}
            />,
            <AddUsersForm
              isEditMode={true}
              key='add-users-form'
              isEditEnabled={isEditEnabled}
              showCancel={true}
              buttonTitle={!isEditEnabled ? 'Edit' : 'Cancel'}
              cancelButtonHandler={editEnableButtonHandler}
              selectedRow={selectedRow}
              submitButtonLabel={'Save'}
            />,
          ]}
          mobileTabsButton
        />
      </Box>
    </>
  );
};
export default ProfileSummary;
