import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { ReactComponent as Tab_Right_Arrow } from '../../assets/Distributor-details/tab-right-arrow.svg';
import { ReactComponent as Tab_Right_Arrow_Active } from '../../assets/Distributor-details/tab-right-arrow-active.svg';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface VerticalTabsProps {
  onTabChange: (newValue: number) => void;
  tabs: Array<{
    label: string;
    icon: React.ReactNode;
    activeIcon: React.ReactNode;
  }>;
}
function CustomTabLabel({
  icon1,
  label,
  active,
}: {
  icon1: React.ReactNode;
  label: string;
  active: boolean;
}) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ marginRight: '10px', display: { xs: 'none', sm: 'block' } }}>
        {icon1}
      </Box>
      <Typography
        sx={{
          color: {
            xs: active ? 'common.white' : 'text.labelColor',
            sm: active ? 'text.navyBlue' : 'text.darkGray',
          },
          fontSize: { xs: '12px', sm: '14px' },
          fontWeight: { xs: active ? 600 : 400, sm: 600 },
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}
export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      sx={{ display: value === index ? 'block' : 'none' }}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    'id': `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs({ onTabChange, tabs }: VerticalTabsProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  return (
    <>
      <Tabs
        sx={(theme: Theme) => ({
          'border': 'unset',
          '& .MuiTabs-flexContainer': {
            pl: 0,
            flexDirection: { xs: 'row', sm: 'column' },
            justifyContent: { xs: 'flex-start' },
          },
          '& .MuiTab-root': {
            'flexDirection': 'row',
            'justifyContent': 'space-between',
            'alignItems': 'center',
            'gap': '10px',
            'padding': '12px 24px',
            'minHeight': '60px',
            [theme.breakpoints.down('sm')]: {
              borderRadius: '6px',
              minHeight: '28px',
              padding: '12px 12px',
            },
            '&.Mui-selected': {
              background: 'rgba(51, 127, 201, 0.10)',
              [theme.breakpoints.down('sm')]: {
                background: theme.palette.background.primaryLinearGradient2,
              },
            },
          },
          '& .MuiTabs-indicator': {
            background: 'transparent',
          },
        })}
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
      >
        {tabs.map((tab, index) => (
          <Tab
            iconPosition='end'
            icon={
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {value === index ? <Tab_Right_Arrow_Active /> : <Tab_Right_Arrow />}
              </Box>
            }
            key={index}
            label={
              <CustomTabLabel
                icon1={
                  <Box
                    sx={{
                      backgroundColor:
                        value === index ? 'text.navyBlue' : 'text.lightGrayishBlue',
                      borderRadius: '50%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '31px',
                      height: '31px',
                      display: 'flex',
                    }}
                  >
                    {value === index ? tab.activeIcon : tab.icon}
                  </Box>
                }
                label={tab.label}
                active={value === index}
              />
            }
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </>
  );
}
