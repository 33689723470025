import {
  Box,
  Collapse,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { capitalizeFirstLetter } from '../../../../../utils';
import { StepperNextButton } from '../../../../auth/login/styles/styledComponents';
import { findLogo } from '../../../utils';
import { setFundName, setSchemeDetails } from '../context/NewMfsInvestorAction';
import { useNewMfsInvestorContext } from '../context/NewMfsInvestorContext';
import { Scheme, SchemeByfund } from '.';

export function RadioCollapsable({
  schemeByFund,
  setSchemesByFund,
  tempSchemeData,
  setTempSchemeData,
  allFundData,
  page,
  rowsPerPage,
}: {
  schemeByFund: SchemeByfund[];
  setSchemesByFund: React.Dispatch<React.SetStateAction<SchemeByfund[]>>;
  tempSchemeData: SchemeByfund[];
  setTempSchemeData: React.Dispatch<React.SetStateAction<SchemeByfund[]>>;
  allFundData: Scheme[];
  page: number;
  rowsPerPage: number;
}) {
  const navigate = useNavigate();
  const [selectedScheme, setSelectedScheme] = useState<number | undefined>(undefined);
  const { dispatch: contextDispatch } = useNewMfsInvestorContext();

  const handleClick = (e: any) => {
    navigate('../investment-details');
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setSelectedScheme(value);
    const filterScheme = schemeByFund.filter((item: any) => item.key === value)[0];
    const filteredFundName = allFundData.find(
      (fundItem: any) => fundItem.fund === filterScheme.fund,
    );
    contextDispatch(setFundName(filteredFundName?.fundName));
    if (filterScheme) {
      contextDispatch(setSchemeDetails(filterScheme));
    }
    const mappedExistingData = schemeByFund.map((item: any) => ({
      ...item,
      open: item.key === value ? !item.open : false,
    }));
    setSchemesByFund(mappedExistingData);
  };

  return (
    <Box sx={{ position: 'relative', mx: { xs: '-20px', sm: '0px' } }}>
      {schemeByFund.length !== 0 ? (
        <RadioGroup
          value={selectedScheme}
          onChange={handleChange}
        >
          {schemeByFund
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((item: any, index: number) => {
              const amcLogo = findLogo(item.fund);
              return (
                <Box
                  sx={(theme: Theme) => ({
                    'position': 'relative',
                    'p': { xs: '20px 20px 20px 10px', sm: '30px' },
                    'paddingLeft': item.open ? '0px' : '5px',
                    'borderLeft': item.open
                      ? `5px solid ${theme.palette.primary.main}`
                      : 'unset',
                    'backgroundColor': item.open
                      ? `${theme.palette.text.boxColor}`
                      : 'common.white',
                    '&:not(:last-child)': {
                      borderBottom: `0.5px solid ${theme.palette.text.borderColorLight}`,
                    },
                  })}
                  key={item.key}
                >
                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'flex' },
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        columnGap: '20px',
                      }}
                    >
                      <Radio
                        sx={{
                          'position': 'absolute',
                          'width': { sm: '60%', md: '70%', lg: '80%' },
                          'justifyContent': 'flex-start',
                          'left': '20px',
                          'top': '42px',
                          'right': '0px',
                          'bottom': '0px',
                          'alignItems': 'baseline',
                          'padding': '0px',
                          'display': 'block',
                          '& .MuiSvgIcon-root': {
                            width: '20px',
                            height: '20px',
                          },
                        }}
                        value={item.key}
                        name='radio-buttons'
                        inputProps={{ 'aria-label': 'A' }}
                        disableFocusRipple
                        disableRipple
                      />
                      <Box
                        sx={{
                          'borderRadius': '10px',
                          'border': '0.5px solid',
                          'borderColor': 'text.borderColorLight',
                          'boxShadow': '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                          'ml': 4,
                          'p': '6px 6px',
                          'display': 'flex',
                          'justifyContent': 'center',
                          'alignItems': 'center',
                          '& svg': {
                            width: '30px',
                            height: '30px',
                          },
                        }}
                      >
                        {amcLogo?.icon && <amcLogo.icon />}
                      </Box>
                      <Typography
                        variant='subtitle2'
                        sx={(theme: Theme) => ({
                          ...(item?.open
                            ? {
                                backgroundClip: 'text',
                                webkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                backgroundImage: `linear-gradient(180deg,  ${theme.palette.text.darkBlue} 0%,  ${theme.palette.text.gradientSkyBlue} 100%)`,
                              }
                            : { color: 'text.valueColor' }),
                        })}
                      >
                        {`${item.schemeDesc} - ${item.planDesc}`}
                      </Typography>
                    </Stack>

                    <Stack
                      direction={'row'}
                      columnGap={'20px'}
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{ color: 'text.labelColor3' }}
                        component={'span'}
                      >
                        {capitalizeFirstLetter(item.category)}
                      </Typography>
                      {item.subCategory && (
                        <Typography
                          variant='subtitle2'
                          sx={{ color: 'text.labelColor3' }}
                          component={'span'}
                        >
                          .{item.subCategory}
                        </Typography>
                      )}
                      {item.risk && (
                        <Typography
                          variant='subtitle2'
                          sx={{
                            color:
                              item.risk === 'Low Risk'
                                ? 'success.main'
                                : item.risk === 'Moderate Risk'
                                ? 'warning.main'
                                : 'text.errorText',
                          }}
                          component={'span'}
                        >
                          .{item.risk}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                  {/* Mobile UI */}
                  <Grid
                    sx={{ display: { xs: 'flex', sm: 'none' } }}
                    container
                  >
                    <Grid
                      item
                      xs={1}
                    >
                      <Radio
                        sx={{
                          'position': 'absolute',
                          'width': '60%',
                          'justifyContent': 'flex-start',
                          'left': { xs: '20px', sm: '10px' },
                          'top': { xs: '20px', sm: '10px' },
                          'right': '0px',
                          'bottom': '0px',
                          'alignItems': 'baseline',
                          'padding': '0px',
                          'display': 'block',
                          '& svg': {
                            width: '16px',
                          },
                        }}
                        value={item.key}
                        name='radio-buttons'
                        inputProps={{ 'aria-label': 'A' }}
                        disableFocusRipple
                        disableRipple
                      />
                    </Grid>
                    <Grid
                      item
                      xs={11}
                    >
                      <Stack
                        columnGap={'20px'}
                        sx={{ flexDirection: 'row', alignItems: 'flex-start', mb: 2 }}
                      >
                        <Box
                          sx={{
                            borderRadius: '10px',
                            border: '0.5px solid',
                            borderColor: 'text.borderColorLight',
                            boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                            ml: 0.25,
                            p: '6px 6px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '32px',
                            height: '32px',
                          }}
                        >
                          {amcLogo?.icon && <amcLogo.icon />}
                        </Box>

                        <Typography
                          sx={{
                            color: 'text.valueColor',
                            fontSize: { xs: '14px', xl: '16px' },
                            fontWeight: { xs: 500, sm: 400 },
                          }}
                        >
                          {`${item.schemeDesc} - ${item.planDesc}`}
                        </Typography>
                      </Stack>

                      <Stack
                        direction={'row'}
                        columnGap={'20px'}
                        sx={{
                          '& .MuiTypography-root': {
                            fontWeight: 400,
                          },
                        }}
                      >
                        <Typography
                          variant='subtitle2'
                          sx={{ color: 'text.labelColor3' }}
                          component={'span'}
                        >
                          {capitalizeFirstLetter(item.category)}
                        </Typography>
                        {item.subCategory && (
                          <Typography
                            variant='subtitle2'
                            sx={{ color: 'text.labelColor3' }}
                            component={'span'}
                          >
                            .{item.subCategory}
                          </Typography>
                        )}
                        {item.risk && (
                          <Typography
                            variant='subtitle2'
                            sx={{
                              color:
                                item.risk === 'Low Risk'
                                  ? 'success.main'
                                  : item.risk === 'Moderate Risk'
                                  ? 'warning.main'
                                  : 'text.errorText',
                            }}
                            component={'span'}
                          >
                            .{item.risk}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                  {/* Mobile UI */}
                  <Collapse
                    in={item.open}
                    timeout='auto'
                    unmountOnExit
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <StepperNextButton
                        sx={{
                          minWidth: { xs: '125px', sm: '160px', lg: '200px' },
                          flex: { xs: 'unset' },
                          mt: 3,
                        }}
                        onClick={e => handleClick(e)}
                        disabled={!item.newPurAllow}
                      >
                        Continue
                      </StepperNextButton>
                    </Box>
                  </Collapse>
                </Box>
              );
            })}
        </RadioGroup>
      ) : (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          mt={4}
        >
          No data Found
        </Box>
      )}
    </Box>
  );
}
