import { Divider, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import useSipNew from '../../../hooks/useSipData';
import { StepperCancelButton } from '../../auth/login/styles/styledComponents';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import FormCheckbox from '../../forms/FormCheckbox';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import SubmitButton from '../../forms/FormSubmitButton';
import FormTextInput from '../../forms/FormTextInput';
import EuinsFields from '../euinsComponent';
import {
  useAllPaymentModesMutation,
  useLazyAllSchemeDetailsQuery,
  useSchemeByfundMutation,
} from '../mfs/newInvestorOnboarding/slice';
import {
  dateToDDMMYYYY,
  dateToMMDDYYYY,
  mapFrequency,
  mapFrequencyToLetter,
} from '../mfs/newInvestorOnboarding/utils';
import { dateToDDMMYYY } from '../nps/investorOnBoarding/utils';
import {
  useGetEUINSQuery,
  useGetSchemaDetailsMutation,
  useModifyItemInCartMutation,
} from '../slice';
import {
  getPerpetualSipEndDate,
  handlePerpetualSipBoxChnage,
  maxInstallmentBasedOnFrequency,
} from '../utils';
import { SchemeByfund } from '.';

interface Option {
  value: any;
  label: any;
}

export interface InvestmentDetails {
  arnCode: string;
  subArnCode: string;
  subBrokerCode: string;
  euin: string;
  amc: string;
  category: string;
  scheme: string;
  modeOfTransaction: string;
  investmentAmount: string;
  frequency: string;
  sipDay: string;
  numberOfInstalments: string;
  startDate: Date | null;
  endDate: Date | null;
  lumpsumAmount: string;
  modeOfRegistration: string;
  perpetual: boolean;
}

interface InvestmentDetailsFormProps {
  data: any;
  isEditMode: boolean;
  handleCancelModifyingCart?: () => void;
}

const UpdateInvestmentDetailsForm: React.FC<InvestmentDetailsFormProps> = ({
  data,
  isEditMode,
  handleCancelModifyingCart,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [schemaData, setSchemaData] = useState<any>({});
  const [schemeDetail, setSchemeDetail] = useState<any>({});
  const formikRef = useRef<FormikProps<InvestmentDetails>>(null);
  const { showToast } = useRootContext();
  const [sipFrequencyOption, setSipFrequencyOptions] = useState<Option[]>([]);
  const [sipDays, setSipDays] = useState<Option[]>([]);
  const [allAmc, setAllAmc] = useState<Option[]>([]);
  const [amcCategory, setAmcCategory] = useState<Option[]>([]);
  const [schemesByFund, setSchemesByFund] = useState<any[]>([]);
  const [localCategory, setLocalCategory] = useState('');
  const [paymentModeOptions, setPaymnetModeOptions] = useState<any[]>([]);
  const [selectedFrequencyAnSip, setSelectedFrequencyAnSip] = useState<{
    selectedFrequency: any;
    selectedSipDay: any;
    noOfInstallments: number;
  }>({
    selectedFrequency: null,
    selectedSipDay: null,
    noOfInstallments: 1,
  });
  const payloadObj = JSON.parse(data.payload);
  const {
    pan: invPan,
    email,
    mobile,
    name,
  } = useSelector((state: { transactions: TransactionResponse }) => state.transactions);

  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const [getAllPaymentModes] = useAllPaymentModesMutation();
  const [updateCartItem] = useModifyItemInCartMutation();
  const [getSchemes, getAllFundResponse] = useLazyAllSchemeDetailsQuery();
  const [getSchemesByFund] = useSchemeByfundMutation();
  const { data: allEUINs } = useGetEUINSQuery([]);

  const selectedScheme = schemesByFund.filter(
    (item: any) =>
      item.option === payloadObj?.option &&
      item.plan === payloadObj?.plan &&
      item.scheme === payloadObj?.scheme &&
      item.schemeDesc === payloadObj?.schemeName,
  );

  const [startDate, endDate, errors] = useSipNew({
    fund: payloadObj?.fund,
    scheme: payloadObj?.scheme,
    frequency: selectedFrequencyAnSip?.selectedFrequency || '',
    installments: Number(selectedFrequencyAnSip?.noOfInstallments) || 1,
    trtype: formikRef?.current?.values?.modeOfTransaction === 'Lumpsum' ? 'NPL' : 'SINI',
    day: selectedFrequencyAnSip?.selectedSipDay
      ? Number(selectedFrequencyAnSip?.selectedSipDay)
      : 1,
    isPerpetual: false,
  });
  useEffect(() => {
    if (!!errors && errors?.showError && selectedFrequencyAnSip?.selectedFrequency) {
      showToast(errors?.errorMessage, 'error');
    }
  }, [errors]);
  const initialValues: InvestmentDetails = {
    arnCode: payloadObj.arnCode || 'NA',
    subArnCode: payloadObj?.subArn || '',
    subBrokerCode: payloadObj.subBrokerCode || '',
    euin: payloadObj?.euin || '',
    amc: payloadObj?.fund || '',
    category: localCategory === '' ? payloadObj?.category : localCategory || '',
    //Todo: remove schemeDesc as it is not required.
    scheme:
      `${selectedScheme?.[0]?.schemeDesc} - ${selectedScheme?.[0]?.plan} - ${selectedScheme?.[0]?.option} - ${selectedScheme?.[0]?.scheme}` ||
      '',
    modeOfTransaction:
      data.transactionType === 'NPL'
        ? 'Lumpsum'
        : data.transactionType !== 'NPL' &&
          data?.investmentAmount !== 0 &&
          data?.sipAmount !== 0
        ? 'Lumpsum + SIP'
        : 'SIP',
    investmentAmount:
      data.transactionType === 'NPL'
        ? payloadObj.amount
        : payloadObj?.installmentAmount || '',
    frequency: mapFrequency(payloadObj?.sipFrequency) || '',
    sipDay: payloadObj?.sipDay || '',
    numberOfInstalments: payloadObj?.noOfInstallments?.toString() || '',
    startDate: payloadObj?.sipStartDate || null,
    endDate: payloadObj?.sipEndDate || null,
    modeOfRegistration: payloadObj?.modeOfRegistration || '',
    perpetual: payloadObj?.perpetual || '',
    lumpsumAmount:
      data.transactionType !== 'NPL' && payloadObj.amount !== 0 ? payloadObj.amount : '',
  };

  const validationSchema = Yup.object().shape({
    modeOfTransaction: Yup.string().required('Mode of Transaction is required'),
    arnCode: Yup.string().required('ARN code is required'),
    amc: Yup.string().required('AMC is required'),
    category: Yup.string().required('Category is required'),
    investmentAmount: Yup.string()
      .required('Investment Amount is required')
      .min(1, 'Minimum investment amount should not be 0')
      .matches(/^[0-9]+$/, 'Investment Amount must contain only numbers'),
    frequency: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string().required('SIP frequency is required'),
    }),
    sipDay: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string().required('SIP Day is required'),
    }),
    numberOfInstalments: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string()
        .required('Number of Installment is required')
        .matches(/^[0-9]+$/, 'Number of installments must contain only numbers'),
    }),
    startDate: Yup.string()
      .typeError('Invalid Date')
      .nullable()
      .test('is-valid-date', 'Start date is required', function (value) {
        const { modeOfTransaction } = this.parent;
        if (modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP') {
          return !!startDate;
        }
        return true;
      }),
    endDate: Yup.string()
      .typeError('Invalid Date')
      .nullable()
      .test('is-valid-date', 'End date is required', function (value) {
        const { modeOfTransaction } = this.parent;
        if (modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP') {
          return !!endDate;
        }
        return true;
      }),
    lumpsumAmount: Yup.string().when('modeOfTransaction', {
      is: 'Lumpsum + SIP',
      then: Yup.string()
        .required('Lumpsum Amount is required')
        .min(1, 'Minimum investment amount should not be 0')
        .matches(/^[0-9]+$/, 'Investment Amount must contain only numbers'),
    }),
  });

  const handleModeOfTransactionChange = (
    setFieldValue: (field: string, value: any) => void,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    setFieldError('investmentAmount', '');
    setFieldError('frequency', '');
    setFieldError('sipDay', '');
    setFieldError('numberOfInstalments', '');
    setFieldError('startDate', '');
    setFieldError('endDate', '');
    setFieldError('lumpsumAmount', '');

    setFieldValue('investmentAmount', '');
    setFieldValue('frequency', '');
    setFieldValue('sipDay', '');
    setFieldValue('numberOfInstalments', '');
    setFieldValue('startDate', null);
    setFieldValue('endDate', null);
    setFieldValue('lumpsumAmount', '');
  };

  const handleUpdateCartItem = async (values: InvestmentDetails) => {
    const selectedSchemeDetails = schemesByFund?.filter((item: any) => {
      if (
        `${item?.schemeDesc} - ${item?.plan} - ${item?.option} - ${item?.scheme}` ===
        values.scheme
      ) {
        return item;
      }
    });

    try {
      dispatch(startLoading());
      const payload = {
        cartId: data?.cartId,
        cartItemId: data?.cartItemId,
        amount:
          values.modeOfTransaction === 'Lumpsum'
            ? Number(values.investmentAmount)
            : Number(values.lumpsumAmount),
        pan: invPan.toUpperCase(),
        email: payloadObj?.email || email,
        mobile: payloadObj?.mobile || mobile,
        euin: payloadObj?.euin,
        euinDeclaration: payloadObj?.euinDeclaration,
        scheme: selectedSchemeDetails?.[0]?.scheme,
        plan: selectedSchemeDetails?.[0]?.plan,
        option: selectedSchemeDetails?.[0]?.option,
        fund: selectedSchemeDetails?.[0]?.fund,
        schemeName: selectedSchemeDetails?.[0]?.schemeDesc,
        planDesc: selectedSchemeDetails?.[0]?.planDesc,
        investorName: name,
        familyEmailFlag: payloadObj.familyEmailFlag || 'SP',
        familyMobileFlag: payloadObj.familyMobileFlag || 'SP',
        subBroker: payloadObj?.subBrokerCode,
        category: selectedSchemeDetails?.[0]?.category,
        fundName: payloadObj?.fundName,
        transactionType: data?.transactionType,
        ...(values.modeOfTransaction !== 'Lumpsum' && {
          noOfInstallments: Number(values.numberOfInstalments),
          remarks: 'ok',
          sipDay: values?.sipDay,
          sipEndDate: values.perpetual
            ? dateToMMDDYYYY(values.endDate)
            : format(endDate as Date, 'MM/dd/yyyy'),
          sipFrequency: mapFrequencyToLetter(values?.frequency),
          sipStartDate: format(startDate as Date, 'MM/dd/yyyy'),
          installmentAmount: Number(values.investmentAmount),
          modeOfRegistration: values.modeOfRegistration,
          perpetual: values.perpetual,
        }),
      };
      await updateCartItem(payload).unwrap();
      if (handleCancelModifyingCart) {
        handleCancelModifyingCart();
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleSubmit = async (values: InvestmentDetails) => {
    handleUpdateCartItem(values);
  };

  const validateMinInvestmentAmount = (values: InvestmentDetails) => {
    const errors: Record<string, any> = {};
    const minAmount = schemaData?.minTransactionAmount;

    const selectedFrequency = schemaData?.frequency?.find(
      (item: any) => item.mode === values.frequency,
    );
    const minInstallments = selectedFrequency?.minInstallments || '';
    const minSipAmount = selectedFrequency?.minTransactionAmount;

    if (Number(values.investmentAmount) < minAmount) {
      errors.investmentAmount = `Minimum Investment Amount should be ${minAmount}`;
    }
    if (Number(values.investmentAmount) < minSipAmount) {
      errors.investmentAmount = `Minimum Investment Amount should be ${minSipAmount}`;
    }
    if (
      values.modeOfTransaction === 'Lumpsum + SIP' &&
      Number(values.lumpsumAmount) < minSipAmount
    ) {
      errors.lumpsumAmount = `Minimum Lumpsum Amount should be ${minSipAmount}`;
    }

    if (Number(values.numberOfInstalments) < minInstallments) {
      errors.numberOfInstalments = `Number of installments should be ${minInstallments}`;
    }
    return errors;
  };

  const resetInvestmentFormFields = (
    setFieldValue: (field: string, value: any) => void,
  ) => {
    setFieldValue('frequency', '');
    setFieldValue('sipDay', '');
    setFieldValue('numberOfInstalments', '');
    setFieldValue('startDate', null);
    setFieldValue('endDate', null);
    setFieldValue('perpetual', false);
    setFieldValue('investmentAmount', '');
    setFieldValue('lumpsumAmount', '');
  };

  const handleSipFrequencyChange = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    setFieldValue('frequency', e.target.value);
    setSelectedFrequencyAnSip({
      ...selectedFrequencyAnSip,
      selectedFrequency: e.target.value,
      noOfInstallments: 0,
    });
    const sipCycle = schemaData?.frequency?.find(
      (item: any) => item.mode === e.target.value,
    );
    if (sipCycle) {
      const cycleArray = sipCycle.cycle?.split(',') || [];
      let sipDays = [];
      const isNumericSequence = cycleArray.every(
        (day: { trim: () => number }) => !isNaN(day.trim()),
      );
      if (!isNumericSequence) {
        sipDays = Array.from({ length: 28 }, (_, i) => ({
          label: (i + 1).toString(),
          value: (i + 1).toString(),
        }));
      } else {
        sipDays =
          cycleArray?.map((day: any) => ({
            label: day,
            value: day,
          })) || [];
      }
      setSipDays(sipDays);
    }
  };

  const handleCategoryChange = async (
    e: any,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    const categoryValue = e.target.value;
    setLocalCategory(categoryValue);
    setFieldValue('category', categoryValue);
    try {
      const schemeByFundBody = {
        funds: [payloadObj?.fund],
        category: [categoryValue],
        investorPan: '',
        start: 0,
        end: 500,
      };
      const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

      const resFundWithOpenKey = resFund
        .map((item: SchemeByfund) => ({
          ...item,
        }))
        ?.filter((item: any) => item.newPurAllow);
      setSchemesByFund(resFundWithOpenKey);
    } catch (error: any) {
      showToast(error?.data?.message || 'unknown error', 'error');
    }
  };

  useEffect(() => {
    dispatch(startLoading());
    if (
      getAllFundResponse &&
      getAllFundResponse.isSuccess &&
      !getAllFundResponse.isFetching
    ) {
      const getAllSchemes = async () => {
        const allFunds = getAllFundResponse.data;
        const amcList = allFunds.map((item: any) => ({
          label: item.fundName,
          value: item.fund,
        }));
        setAllAmc(amcList);
        try {
          const schemeByFundBody = {
            funds: [payloadObj?.fund],
            category: [],
            investorPan: '',
            start: 0,
            end: 2000,
            searchQuery: '',
          };
          const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

          const resFundWithOpenKey = resFund
            .map((item: SchemeByfund) => ({
              ...item,
            }))
            ?.filter((item: any) => item.newPurAllow);

          const tempCategoryData = resFund
            .map((item: any) => item.category)
            .filter((category: string) => category !== null && category !== '');
          const uniQueCategory = [...new Set(tempCategoryData)] as string[];
          setSchemesByFund(resFundWithOpenKey);
          const amcCategory = uniQueCategory.map((category: any) => ({
            label: category,
            value: category,
          }));
          setAmcCategory(amcCategory);
        } catch (error: any) {
          showToast(error?.data?.message || 'unknown error', 'error');
        } finally {
          dispatch(stopLoading());
        }
      };
      getAllSchemes();
    }
  }, [getAllFundResponse]);

  useEffect(() => {
    try {
      getSchemes('');
    } catch (error: any) {
      showToast(error?.data?.message, 'error');
    }
  }, []);

  useEffect(() => {
    const getSchema = async () => {
      try {
        const payload = {
          fund: payloadObj?.fund,
          scheme: payloadObj?.scheme,
          plan: payloadObj?.plan,
          option: payloadObj?.option,
          trType: data?.transactionType === 'NPL' ? 'NPL' : 'SIP',
        };
        const result: any = await getSchemaDetails(payload).unwrap();
        setSchemaData(result);
        if (result?.frequency) {
          const uniqueModes = result?.frequency
            .map((option: { mode: any }) => option.mode)
            .filter(
              (mode: any, index: any, self: string | any[]) =>
                self.indexOf(mode) === index,
            );
          const sipFrequency = uniqueModes.map((mode: any) => ({
            label: mode,
            value: mode,
          }));
          setSipFrequencyOptions(sipFrequency);
        }

        const sipCycle = result?.frequency?.find(
          (item: any) => item.mode === mapFrequency(payloadObj?.sipFrequency),
        );
        if (sipCycle) {
          const cycleArray = sipCycle.cycle?.split(',') || [];
          let sipDays = [];
          const isNumericSequence = cycleArray.every(
            (day: { trim: () => number }) => !isNaN(day.trim()),
          );
          if (!isNumericSequence) {
            sipDays = Array.from({ length: 28 }, (_, i) => {
              const day = (i + 1).toString();
              return {
                label: day,
                value: day,
              };
            });
          } else {
            sipDays =
              cycleArray?.map((day: string) => {
                const dayWithoutLeadingZero = parseInt(day, 10).toString();
                return {
                  label: dayWithoutLeadingZero,
                  value: dayWithoutLeadingZero,
                };
              }) || [];
          }
          setSipDays(sipDays);
        }
      } catch (error: any) {
        showToast(
          (error as { message: string })?.message || error?.data?.message,
          'error',
        );
      }
    };
    setSelectedFrequencyAnSip({
      selectedFrequency: mapFrequency(payloadObj?.sipFrequency),
      selectedSipDay: payloadObj?.sipDay,
      noOfInstallments: payloadObj?.noOfInstallments,
    });
    getSchema();
  }, []);

  useEffect(() => {
    const getPaymentOptions = async () => {
      const payload = {
        fund: payloadObj?.fund,
      };
      const response = await getAllPaymentModes(payload).unwrap();
      const options = Object.entries(response)
        .map(([key, value]) => {
          if (value === 'Y') {
            return { label: key.toUpperCase(), value: key };
          }
          return null;
        })
        .filter(Boolean);
      setPaymnetModeOptions(options);
    };
    getPaymentOptions();
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validate={validateMinInvestmentAmount}
      >
        {({ values, errors, setFieldValue, setFieldError }) => {
          const selectedFrequency = schemaData?.frequency?.find(
            (item: any) => item.mode === values.frequency,
          );
          const minInstallments = selectedFrequency?.minInstallments || '';
          const sipMinAmount = selectedFrequency?.minTransactionAmount || '';

          return (
            <Form>
              <Typography
                sx={{
                  fontSize: {
                    xs: '16px',
                    xl: '18px',
                  },
                  fontWeight: 500,
                  color: { xs: 'text.valueColor', sm: 'primary.main' },
                  mb: 2,
                }}
              >
                Distributor Details
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ mb: 2 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='arnCode'
                    label='ARN Code'
                    required={false}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='subArnCode'
                    label='Sub ARN Code'
                    required={false}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='subBrokerCode'
                    label='Sub Broker Code'
                    required={false}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={9}
                  lg={7}
                >
                  <EuinsFields
                    name='euin'
                    allEUINs={allEUINs}
                    initialValue={payloadObj?.euin !== '' ? 'yes' : 'no'}
                    disabled
                  />
                </Grid>
              </Grid>

              <Divider
                orientation='horizontal'
                variant='fullWidth'
                flexItem
                sx={{
                  border: '1px dashed',
                  borderColor: 'text.borderColorDark',
                  my: 3,
                }}
              />
              <Grid
                container
                spacing={2}
                mb={2}
              >
                <Grid
                  item
                  xs={6}
                >
                  <FormSelectInput
                    name='amc'
                    label='AMC'
                    defaultValue={''}
                    required
                    options={allAmc}
                    disabled={payloadObj?.fund !== ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormSelectInput
                    name='category'
                    label='Category'
                    value={values.category}
                    required
                    options={amcCategory}
                    onChange={(e: any) => {
                      handleCategoryChange(e, setFieldValue);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormSelectInput
                    name='scheme'
                    label='Select Scheme'
                    defaultValue={''}
                    required
                    options={schemesByFund?.map((item: any) => ({
                      label: `${item.schemeDesc} - ${item.planDesc}`,
                      value: `${item.schemeDesc} - ${item.plan} - ${item.option} - ${item.scheme}`,
                    }))}
                    onChange={() => resetInvestmentFormFields(setFieldValue)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  mb={3}
                >
                  <Typography
                    sx={{
                      color: 'text.valueColor',
                      mb: 2,
                      fontWeight: 500,
                      fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                    }}
                  >
                    Mode of Transaction *
                  </Typography>
                  <FormStyledRadioButton
                    options={[
                      { label: 'Lumpsum', value: 'Lumpsum' },
                      { label: 'SIP', value: 'SIP' },
                      { label: 'Lumpsum + SIP', value: 'Lumpsum + SIP' },
                    ]}
                    disabled
                    name='modeOfTransaction'
                    handleChange={async (e: any) => {
                      try {
                        const payload = {
                          fund: payloadObj?.fund,
                          scheme: payloadObj?.scheme,
                          plan: payloadObj?.plan,
                          option: payloadObj?.option,
                          trType: e.target.value === 'Lumpsum' ? 'NPL' : 'SIP',
                        };
                        const result: any = await getSchemaDetails(payload).unwrap();
                        setSchemaData(result);
                        if (result?.frequency) {
                          const sipFrequency = result?.frequency?.map((option: any) => ({
                            label: option.mode,
                            value: option.mode,
                          }));
                          setSipFrequencyOptions(sipFrequency);
                        }
                      } catch (error: any) {
                        showToast(
                          (error as { message: string })?.message || error?.data?.message,
                          'error',
                        );
                      }
                      handleModeOfTransactionChange(setFieldValue, setFieldError);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
              >
                {values?.modeOfTransaction !== '' &&
                  values?.modeOfTransaction !== 'Lumpsum' && (
                    <>
                      <Grid
                        item
                        sm={6}
                        xs={12}
                      >
                        <FormSelectInput
                          name='modeOfRegistration'
                          label='Mode Of Registration'
                          defaultValue={''}
                          required
                          options={paymentModeOptions.filter(
                            item => item.label !== 'NETBANKING' && item.label !== 'UPI',
                          )}
                          disabled={payloadObj?.modeOfRegistration !== ''}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <FormSelectInput
                          name='frequency'
                          label='Frequency'
                          defaultValue={''}
                          required
                          options={sipFrequencyOption}
                          onChange={(e: any) => {
                            setFieldValue('sipDay', '');
                            setFieldValue('numberOfInstalments', '');
                            setFieldValue('startDate', null);
                            setFieldValue('endDate', null);
                            setFieldValue('perpetual', false);
                            handleSipFrequencyChange(e, setFieldValue);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <FormTextInput
                          name='numberOfInstalments'
                          label='Number Of Installments'
                          required
                          onChange={e => {
                            setFieldValue('sipDay', '');
                            setFieldValue('startDate', null);
                            setFieldValue('endDate', null);
                            setSelectedFrequencyAnSip({
                              ...selectedFrequencyAnSip,
                              noOfInstallments: Number(e.target.value),
                            });
                            //TODO: Need to change the logic based on the actual api response for sip dates.
                            const end_date = getPerpetualSipEndDate(
                              values.modeOfRegistration,
                              startDate as Date,
                            );
                            const maxInstallments = maxInstallmentBasedOnFrequency(
                              values.frequency,
                              values.modeOfRegistration,
                            );
                            if (
                              values.modeOfRegistration === 'ISIP' &&
                              Number(e.target.value) > maxInstallments
                            ) {
                              showToast(
                                'Number of installments needs to reduced so that end date is not greater than 12/2099',
                                'error',
                              );
                              setFieldValue('numberOfInstalments', '');
                            } else if (
                              values.modeOfRegistration === 'kotm' &&
                              Number(e.target.value) > maxInstallments
                            ) {
                              showToast(
                                `SIP End date cannot exceed ${dateToDDMMYYY(end_date)}`,
                                'error',
                              );
                              setFieldValue('numberOfInstalments', '');
                            }
                          }}
                          disabled={values.perpetual}
                          typeOfInput='number'
                        />

                        <Typography>
                          Minimum Number of installments: {minInstallments}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <FormSelectInput
                          name='sipDay'
                          label='SIP Day'
                          defaultValue={''}
                          required
                          options={sipDays}
                          onChange={e => {
                            setSelectedFrequencyAnSip({
                              ...selectedFrequencyAnSip,
                              selectedSipDay: e.target.value,
                            });
                            //TODO: Need to change the logic based on the actual api response.
                            const actualTime = new Date(endDate as Date).getTime();
                            const end_date = getPerpetualSipEndDate(
                              values.modeOfRegistration,
                              startDate as Date,
                            );
                            const requiredTime = new Date(end_date).getTime();
                            if (
                              values.modeOfRegistration === 'ISIP' &&
                              actualTime > requiredTime
                            ) {
                              showToast(
                                'Number of installments needs to reduced so that end date is not greater than 12/2099',
                                'error',
                              );
                              setFieldValue('numberOfInstalments', '');
                            } else if (
                              values.modeOfRegistration === 'kotm' &&
                              actualTime > requiredTime
                            ) {
                              showToast(
                                `SIP End date cannot exceed ${dateToDDMMYYY(end_date)}`,
                                'error',
                              );
                              setFieldValue('numberOfInstalments', '');
                              setFieldValue('sipDay', '');
                            }
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <FormDateInput
                          name='startDate'
                          label='Start Date'
                          required={true}
                          value={dateToDDMMYYY(startDate as Date)}
                          viewOnly
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <FormDateInput
                          name='endDate'
                          label='End Date'
                          required={true}
                          value={
                            values.perpetual
                              ? dateToDDMMYYYY(values.endDate)
                              : dateToDDMMYYY(endDate as Date)
                          }
                          viewOnly
                        />
                      </Grid>
                      {values.sipDay !== '' && (
                        <Grid
                          item
                          xs={12}
                        >
                          <FormCheckbox
                            name='perpetual'
                            label='Perpetual SIP'
                            margin='dense'
                            checked={values?.perpetual}
                            onChange={e =>
                              handlePerpetualSipBoxChnage(
                                e,
                                setFieldValue,
                                values,
                                startDate as Date,
                                selectedFrequencyAnSip,
                              )
                            }
                          />
                        </Grid>
                      )}
                    </>
                  )}
                <Grid
                  item
                  xs={6}
                >
                  <FormTextInput
                    name='investmentAmount'
                    label='Investment Amount'
                    required
                    typeOfInput='number'
                    onInput={(e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      if (e.target.value.startsWith('0')) {
                        e.target.value = e.target.value.replace(/^0+/, '');
                      }
                      if (e.target.value.length > 18) {
                        e.target.value = e.target.value.slice(0, 18);
                      }
                      setFieldValue('investmentAmount', e.target.value);
                    }}
                  />
                  <Typography>
                    Minimum Amount:{' '}
                    {values.modeOfTransaction === 'Lumpsum'
                      ? schemaData?.minTransactionAmount
                      : sipMinAmount}
                  </Typography>
                </Grid>
                {values.modeOfTransaction === 'Lumpsum + SIP' && (
                  <Grid
                    item
                    xs={6}
                  >
                    <FormTextInput
                      name='lumpsumAmount'
                      label='Lumpsum Amount'
                      typeOfInput='number'
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        if (e.target.value.startsWith('0')) {
                          e.target.value = e.target.value.replace(/^0+/, '');
                        }
                        if (e.target.value.length > 18) {
                          e.target.value = e.target.value.slice(0, 18);
                        }
                        setFieldValue('lumpsumAmount', e.target.value);
                      }}
                    />
                    <Typography>Minimum Amount: {sipMinAmount}</Typography>
                  </Grid>
                )}
              </Grid>
              <Stack sx={{ flexDirection: 'row', columnGap: '20px', mt: 3 }}>
                <StepperCancelButton
                  sx={{ minWidth: '200px', my: 3 }}
                  onClick={handleCancelModifyingCart}
                >
                  Cancel
                </StepperCancelButton>
                <SubmitButton
                  sx={{
                    '&.MuiButtonBase-root': {
                      borderRadius: '5px',
                      minWidth: '200px',
                      my: 3,
                    },
                  }}
                  label='Update'
                />
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default UpdateInvestmentDetailsForm;
