import { Box, Divider, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import { freqType } from '../../../hooks/useSip';
import useSipNew from '../../../hooks/useSipData';
import { calculateTatDate, formatToMMDDYYYYByUTC } from '../../../utils';
import { FieldSubHeader3, MainHeader } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { SwpCartItem } from '../../transactions/cart/types';
import {
  useAddItemToCartMutation,
  useGetSchemaDetailsMutation,
  useListOfSchemesMutation,
  useModifyItemInCartMutation,
} from '../../transactions/slice';
import RenderSubmitCartButtons from '../common/SubmitCartButtons';
import { mapFrequencyToLetter } from '../mfs/newInvestorOnboarding/utils';
import { SWP } from '../types';
import { maxInstallmentBasedOnFrequency } from '../utils';
import { capitalizeFirstLetter, capitalizeWords, removeSpaces } from './common';
import { useGetSWPwithdrawalTypeMutation } from './slice';

interface InvestmentDetailsFormProps {
  data: any;
  isEditMode: boolean;
  handleCancelModifyingCart?: () => void;
  isFromCart?: boolean;
}

const validationSchema = Yup.object().shape({
  swpType: Yup.string().required('Please select SWP Type'),
  swpMode: Yup.string().required('Please select SWP Mode'),
  swpDate: Yup.string().required('SWP Day is required'),
  investmentAmount: Yup.string().when('swpType', {
    is: (swpType: string) => swpType?.toLowerCase() === 'fixed',
    then: Yup.string().required('Investment Amount is required for Fixed SWP'),
    otherwise: Yup.string(),
  }),
  noOfTransfers: Yup.string().required('Transfer number is required'),
});

const InvestmentDetailsForm: React.FC<InvestmentDetailsFormProps> = ({
  data,
  isEditMode,
  handleCancelModifyingCart,
  isFromCart,
}) => {
  const [swpType, setSwpType] = useState<string[]>(['Fixed', 'Capital Appreciation']);
  const formikRef = useRef<FormikProps<SWP | SwpCartItem>>(null);
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const firstButtonTitle = !isEditMode ? 'Add to Cart' : 'Cancel';
  const secondButtonTitle = !isEditMode ? 'Save & Proceed' : 'Update';
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [addSwpItemsInCart] = useAddItemToCartMutation();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [schemaData, setSchemaData] = useState<any>({});
  const [getWithdrawalType] = useGetSWPwithdrawalTypeMutation();

  const [investorDetailsList] = useListOfSchemesMutation();
  const [investorSchemes, setInvestorSchemes] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>(data);

  const [modifyItemInCart] = useModifyItemInCartMutation();

  const totalAvailableAmount = data?.units * data?.nav;

  const [selectedFrequencyAnSip, setSelectedFrequencyAnSip] = useState<{
    selectedFrequency: any;
    selectedSipDay: any;
    noOfInstallment: any;
  }>({
    selectedFrequency: null,
    selectedSipDay: null,
    noOfInstallment: 0,
  });
  const dispatch = useDispatch();

  const fetchSchemaDetails = async (data: any, ignore: boolean) => {
    try {
      const payload = {
        fund: data?.fund,
        scheme: data?.scheme,
        plan: data?.plan || data?.schemePlan,
        option: data?.option || data?.schemeOption,
        trType: 'SWP',
      };
      dispatch(startLoading());

      const result: any = await getSchemaDetails(payload).unwrap();
      setIsLoading(false);
      if (!ignore) {
        const uniqueFrequencies = Array.from(
          new Map(
            result?.frequency?.map((item: any) => [item.mode.trim(), item]),
          ).values(),
        );

        // Create a new object with the deduplicated frequency array
        const newResult = {
          ...result,
          frequency: uniqueFrequencies,
        };

        setSchemaData(newResult);
      }
    } catch (error: any) {
      showToast((error as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  async function getWithdrawalTypes(fund: string) {
    try {
      const payload = { fund: fund };
      dispatch(startLoading());

      const res = await getWithdrawalType(payload).unwrap();
      const descriptions = res.map((item: { description: string }) =>
        capitalizeWords(item.description || ''),
      );
      setSwpType(descriptions);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }

  useEffect(() => {
    let ignore = false;
    if (Object.keys(data).length > 0) {
      fetchSchemaDetails(data, ignore);
      getWithdrawalTypes(data?.fund);
    }

    return () => {
      ignore = true;
    };
  }, [data]);

  const handleSubmit = async (values: SWP | SwpCartItem) => {
    if (isFromCart) {
      navigate('../../cart');
      return;
    }
    const {
      cartId,
      cartItemId,
      fund,
      folio,
      email,
      mobile,
      option,
      pan,
      scheme,
      schemeName,
      fundName,
      plan,
      planDesc,
    } = data || {};
    const payload = isEditMode
      ? {
          cartId: cartId,
          cartItemId: cartItemId,
          schemeName:
            selectedOption && selectedOption.schemeName !== ''
              ? selectedOption.schemeName
              : String(schemeName || ''),
          amount: values.swpType === 'Fixed' ? Number(values.investmentAmount) : '0',
          email: selectedOption ? selectedOption?.email : String(email),
          fund:
            selectedOption && selectedOption.fund
              ? selectedOption.fund.trim()
              : fund
              ? fund.trim()
              : '',
          folio: selectedOption ? selectedOption?.folio : String(folio),
          frequency: mapFrequencyToLetter(capitalizeFirstLetter(values.swpMode || '')),
          investorName: data?.name || data?.investorName,
          mobile: selectedOption ? selectedOption?.mobile : String(mobile),
          option: selectedOption ? selectedOption?.option : String(option),
          pan,
          plan: selectedOption ? selectedOption?.plan : String(plan),
          noOfWithdrawals: Number(values.noOfTransfers || data?.noOfWithdrawals),
          scheme: selectedOption ? selectedOption?.scheme : String(scheme),
          swpDay: values.swpDate,
          swpEndDate: format(new Date(values.timePeriodTo as Date), 'MM/dd/yyyy'),
          swpFrequency: mapFrequencyToLetter(values.swpMode),
          swpStartDate: format(new Date(values.timePeriodFrom as Date), 'MM/dd/yyyy'),
          taxSaverFlag: selectedOption
            ? selectedOption?.taxSaver || selectedOption?.taxSaverFlag
            : data?.taxSaver || data?.taxSaverFlag,
          transactionType: 'SWP',
          withdrawalType: values.swpType,
          swpOption: values.swpType,
          planDesc: selectedOption ? selectedOption?.planDesc : planDesc,
        }
      : {
          schemeName: data?.schemeDesc || data?.schemeName,
          amount: values.swpType === 'Fixed' ? Number(values.investmentAmount) : '0',
          email,
          folio: data?.folioNo || data?.folio,
          frequency: mapFrequencyToLetter(capitalizeFirstLetter(values.swpMode || '')),
          fund: data?.fund.trim(),
          fundName,
          investorName: data?.name || data?.investorName,
          mobile,
          noOfWithdrawals: Number(values.noOfTransfers || data?.noOfWithdrawals),
          option,
          pan,
          plan,
          scheme,
          swpDay: values.swpDate,
          swpEndDate: formatToMMDDYYYYByUTC(values.timePeriodTo as Date),
          swpFrequency: mapFrequencyToLetter(values.swpMode),
          swpOption: values.swpType,
          swpStartDate: formatToMMDDYYYYByUTC(values.timePeriodFrom as Date),
          taxSaverFlag: data?.taxSaver || data?.taxSaverFlag,
          transactionType: 'SWP',
          withdrawalType: values.swpType,
        };

    if (isEditMode) {
      try {
        dispatch(startLoading());
        await modifyItemInCart(payload).unwrap();
        showToast(`CartItem updated successfully`, 'success');
        if (handleCancelModifyingCart) handleCancelModifyingCart();
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    } else {
      navigate('/transaction-dashboard/confirmation', {
        state: { payload: payload, isEditMode: isEditMode },
      });
    }
  };

  const handleAddToCartClick = async () => {
    const payload = {
      amount:
        formikRef.current?.values.swpType === 'Fixed'
          ? Number(formikRef.current?.values.investmentAmount)
          : '0',
      branch: 'DA99', // todo remove hard coded
      pan: pan,
      mobile: data?.mobile || '',
      email: data?.email || '',
      folio: data?.folioNo || '',
      swpMode: mapFrequencyToLetter(formikRef.current?.values.swpMode as string),
      fund: data?.fund,
      investorName: data?.name || '',
      noOfWithdrawals: Number(formikRef.current?.values.noOfTransfers),
      option: data?.option,
      plan: data?.plan,
      scheme: data?.scheme,
      schemeName: data?.schemeDesc,
      fundName: data?.fundName,
      swpDay: formikRef.current?.values.swpDate,
      swpEndDate: formatToMMDDYYYYByUTC(formikRef.current?.values.timePeriodTo as Date),
      frequency: mapFrequencyToLetter(formikRef.current?.values.swpMode as string),
      swpFrequency: mapFrequencyToLetter(formikRef.current?.values.swpMode as string),
      swpOption: formikRef.current?.values.swpType,
      swpStartDate: formatToMMDDYYYYByUTC(
        formikRef.current?.values.timePeriodFrom as Date,
      ),
      taxSaverFlag: data?.taxSaver,
      withdrawalType: formikRef.current?.values.swpType,
      transactionType: 'SWP',
      planDesc: data?.planDesc || '',
    };

    try {
      dispatch(startLoading());
      await addSwpItemsInCart(payload).unwrap();
      setOpenDialogBox(true); // Close dialog after handling logic
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleAddingItemInCart = async () => {
    setOpenDialogBox(false); // Close dialog after handling logic
    navigate('../../cart');
  };

  const handleValidation = async (values: any) => {
    const errors: Record<string, any> = {};

    if (values.noOfTransfers < values.minTransferAllowed) {
      errors.noOfTransfers = `Min transfer Required ${values.minTransferAllowed}`;
    }

    if (values?.swpType === 'Fixed' && values.investmentAmount < minTransactionAmount) {
      errors.investmentAmount = `Min transaction amount Required ${minTransactionAmount}`;
    }

    if (Number(values.investmentAmount) > totalAvailableAmount) {
      errors.investmentAmount = `Investment amount should not exceed ${totalAvailableAmount}`;
    }
    return errors;
  };

  const handleAddAnotherTransactionBtn = () => {
    // Second button click logic
    setOpenDialogBox(false); // Close dialog after handling logic
    navigate('../');
  };
  const [startDate, endDate, errors] = useSipNew({
    fund: data?.fund,
    scheme: data?.scheme,
    frequency: selectedFrequencyAnSip?.selectedFrequency || '',
    installments: Number(selectedFrequencyAnSip?.noOfInstallment),
    trtype: 'SWP',
    day: selectedFrequencyAnSip?.selectedSipDay
      ? Number(selectedFrequencyAnSip?.selectedSipDay)
      : 0,
    isPerpetual: false,
  });

  useEffect(() => {
    if (!!errors && errors?.showError && selectedFrequencyAnSip?.selectedFrequency) {
      showToast(errors?.errorMessage, 'error');
    }
  }, [errors]);

  useEffect(() => {
    if (
      selectedFrequencyAnSip?.selectedFrequency &&
      selectedFrequencyAnSip?.noOfInstallment &&
      selectedFrequencyAnSip?.selectedSipDay
    ) {
      formikRef.current?.setFieldValue('timePeriodFrom', startDate);
      formikRef.current?.setFieldValue('timePeriodTo', endDate);
    }
  }, [selectedFrequencyAnSip, formikRef.current, startDate, endDate]);

  const [swpDates, , minTransactionAmount]: [string[], number, number, number, any] =
    useMemo(() => {
      const frequency = schemaData?.frequency?.find(
        (f: any) =>
          removeSpaces(capitalizeFirstLetter(f.mode || '')) ===
            selectedFrequencyAnSip.selectedFrequency || data?.frequency,
      );
      const cycle = frequency?.cycle;
      const trimmedCycle = cycle?.replace(/\s/g, '');

      const dates =
        !cycle || !/^\d+(,\d+)*$/.test(trimmedCycle)
          ? Array.from({ length: 28 }, (_, index) => `${index + 1}`)
          : trimmedCycle.split(',');

      const tatDate = calculateTatDate(frequency?.coolingPeriod);
      formikRef.current?.setFieldValue('minTransferAllowed', frequency?.minInstallments);

      return [
        dates,
        frequency?.minInstallments,
        frequency?.minTransactionAmount,
        frequency?.coolingPeriod,
        tatDate,
      ];
    }, [schemaData?.frequency, selectedFrequencyAnSip, data]);

  // All SWP Modes filtered out.
  const swpModes: string[] = useMemo(() => {
    if (!Array.isArray(schemaData?.frequency)) return [];
    const modes = schemaData.frequency.map((f: any) =>
      removeSpaces(capitalizeFirstLetter(f.mode || '')),
    );
    setSelectedFrequencyAnSip({
      ...selectedFrequencyAnSip,
      selectedFrequency: modes[0],
    });
    // setFrequencyType(modes[0]);
    return Array.from(new Set(modes));
  }, [schemaData?.frequency]);

  function getInvestment(fund?: string, folio?: number): any[] {
    if (fund && folio) {
      return investorSchemes?.filter(
        (inv: { fund: string; folio: number }) =>
          String(inv.fund) === String(fund) && String(inv.folio) === String(folio),
      );
    } else if (fund) {
      return investorSchemes?.filter(
        (inv: { fund: string }) => String(inv.fund) === String(fund),
      );
    } else {
      return investorSchemes;
    }
  }

  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      const payload = {
        pan: pan,
      };
      try {
        dispatch(startLoading());
        const response = await investorDetailsList(payload).unwrap();
        const filteredSchemes = response?.filter(
          (item: { swdAllow: any }) => item.swdAllow,
        );
        setInvestorSchemes(filteredSchemes);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }
    if (isEditMode) {
      getInvestorDetailsSchemes();
    }
  }, []);

  const handleSelectChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    values: any,
  ) => {
    const selectObject = Array.from(
      new Set(
        investorSchemes
          ?.filter((item: any) => {
            // Create a unique key from `schemeName` and `planDesc`
            const key = `${item.scheme} - ${item.plan} - ${item.option}`;
            return key === event.target.value;
          })
          .map((item: any) => JSON.stringify(item)),
      ),
    ).map(item => JSON.parse(item as string));
    // getting  particular scheme validations
    +(await fetchSchemaDetails(selectObject[0], false));
    setSelectedOption(selectObject[0]);
  };

  return (
    <Formik
      initialValues={{
        swpType: capitalizeFirstLetter(data?.swpOption || 'Fixed'),
        swpDate: selectedOption?.folio !== data?.folio ? '' : String(data?.swpDay) || '',
        timePeriodFrom:
          selectedOption?.folio !== data?.folio ? null : data?.swpStartDate || null,
        investmentAmount: data?.amount || '',
        swpMode:
          selectedOption?.folio !== data?.folio
            ? ''
            : freqType[data?.frequency as keyof typeof freqType] || '',
        noOfTransfers: data?.noOfWithdrawals || '',
        timePeriodTo:
          selectedOption?.folioNo !== data?.folioNo ? null : data?.swpEndDate || null,
        minTransferAllowed: 0,
        folioNumber: data?.folio,
        schemeName: `${data?.scheme} - ${data?.plan} - ${data?.option}`,
      }}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      validationSchema={validationSchema}
      validate={async values => handleValidation(values)}
    >
      {({ values, handleChange, setFieldValue, errors }) => (
        <Form>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 1.5,
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                SWP Type
              </Typography>

              <FormStyledRadioButton
                options={swpType.map(item => ({
                  label: item,
                  value: item,
                }))}
                name='swpType'
                handleChange={handleChange}
              />
            </Grid>

            {isEditMode && (
              <>
                <Grid
                  item
                  xs={12}
                >
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <Typography
                        sx={{
                          mb: 1.5,
                          fontSize: {
                            xs: '14px',
                            xl: '16px',
                          },
                          fontWeight: 500,
                          color: 'text.inputLabelText',
                        }}
                      >
                        {' '}
                        Selected AMC
                      </Typography>

                      <FormTextInput
                        name='AMC'
                        value={data?.fundName}
                        placeholder=''
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <Typography
                        sx={{
                          mb: 1.5,
                          fontSize: {
                            xs: '14px',
                            xl: '16px',
                          },
                          fontWeight: 500,
                          color: 'text.inputLabelText',
                        }}
                      >
                        {' '}
                        Folio
                      </Typography>
                      <FormSelectInput
                        name='folioNumber'
                        label='folioNumber'
                        options={getInvestment(data?.fund).map(data => ({
                          value: data.folio,
                          label: data.folio,
                        }))}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <Typography
                        sx={{
                          mb: 1.5,
                          fontSize: {
                            xs: '14px',
                            xl: '16px',
                          },
                          fontWeight: 500,
                          color: 'text.inputLabelText',
                        }}
                      >
                        {' '}
                        Scheme
                      </Typography>
                      <FormSelectInput
                        name='schemeName'
                        label='schemeName'
                        options={getInvestment(
                          data?.fund,
                          (values as any)?.folioNumber,
                        ).map(data => ({
                          label: `${data.schemeName} - ${data.planDesc}`,
                          value: `${data.scheme} - ${data.plan} - ${data.option}`,
                        }))}
                        onChange={async (e: any) => handleSelectChange(e, values)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid
              item
              xs={12}
              sm={6}
              sx={{ mb: 1.5 }}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 1,
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                SWP Mode
              </Typography>

              <FormSelectInput
                name='swpMode'
                label='SWP Mode'
                onChange={(e: SelectChangeEvent<unknown>) => {
                  // setFrequencyType(e.target.value as string);
                  formikRef.current?.setFieldValue('timePeriodFrom', null);
                  formikRef.current?.setFieldValue('timePeriodTo', null);
                  formikRef.current?.setFieldValue('investmentAmount', '');
                  formikRef.current?.setFieldValue('noOfTransfers', '');
                  formikRef.current?.setFieldValue('swpDate', '');
                  setSelectedFrequencyAnSip({
                    ...selectedFrequencyAnSip,
                    selectedFrequency: e.target.value,
                  });
                }}
                options={swpModes?.map((item: string) => ({
                  label: item,
                  value: item,
                }))}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ mb: 1.5 }}
            >
              <Typography
                sx={{
                  mb: 1.5,
                  fontSize: {
                    xs: '14px',
                    xl: '16px',
                  },
                  fontWeight: 500,
                  color: 'text.inputLabelText',
                }}
              >
                No of Transfers{' '}
                <Typography
                  component='span'
                  sx={{
                    fontSize: {
                      xs: '14px',
                      xl: '16px',
                    },
                    fontWeight: 400,
                    color: 'text.inputLabelText',
                  }}
                >
                  (minimum:): {values.minTransferAllowed || ''}
                </Typography>
              </Typography>
              <FormTextInput
                name='noOfTransfers'
                label='No of Transfers'
                placeholder='Enter the number of Transfers'
                typeOfInput='number'
                onChange={e => {
                  const maxInstallmentsAllowed = maxInstallmentBasedOnFrequency(
                    values.swpMode,
                  );
                  if (Number(e.target.value) > maxInstallmentsAllowed) {
                    showToast(
                      `No of Transfers should not be greater than ${maxInstallmentsAllowed}`,
                      'error',
                    );
                    formikRef.current?.setFieldValue('noOfTransfers', '');
                  }
                  formikRef.current?.setFieldValue('timePeriodFrom', null);
                  formikRef.current?.setFieldValue('timePeriodTo', null);
                  formikRef.current?.setFieldValue('swpDate', '');
                  setSelectedFrequencyAnSip({
                    ...selectedFrequencyAnSip,
                    noOfInstallment: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 1.5,
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                SWP Day
              </Typography>

              <FormSelectInput
                name='swpDate'
                label='SWP Day'
                options={swpDates?.map((date: string) => ({
                  label: date,
                  value: date,
                }))}
                // onChange={e => setSipDay(e.target.value as string)}
                onChange={e => {
                  const selectedDate = e.target.value;
                  setFieldValue('swpDate', selectedDate);
                  setSelectedFrequencyAnSip({
                    ...selectedFrequencyAnSip,
                    selectedSipDay: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            ></Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormDateInput
                name='timePeriodFrom'
                label='Time Period from'
                placeholder='Time Period from'
                viewOnly
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormDateInput
                name='timePeriodTo'
                placeholder='Time Period to'
                label='Time Period to'
                viewOnly
              />
            </Grid>
            {values.swpType === 'Fixed' && (
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 1.5,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Installment Amount
                </Typography>

                <FormTextInput
                  disabled={isLoading}
                  name='investmentAmount'
                  label='Installment Amount'
                  placeholder='Enter the installment Amount'
                  onInput={(e: any) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            sx={{
              border: '1px dashed',
              borderColor: 'text.borderColorDark',
              mt: 3,
            }}
          />
          <RenderSubmitCartButtons
            onAddToCartClick={
              !isEditMode ? handleAddToCartClick : handleCancelModifyingCart
            }
            firstButtonTitle={firstButtonTitle}
            secondButtonTitle={isFromCart ? 'Go to Cart' : secondButtonTitle}
          />
          <CustomDialog
            isVisible={openDialogBox}
            firstButtonHandler={handleAddingItemInCart}
            secondButtonHandler={handleAddAnotherTransactionBtn}
            firstButtonTitle='Go to cart'
            secondButtonTitle='Add another Transaction'
            handleClose={() => {
              setOpenDialogBox(false);
            }}
            showSecondButton={true}
          >
            <Box
              sx={{
                'textAlign': 'center',
                'pt': 2,
                '& img': {
                  width: { xs: '58px', sm: 'unset' },
                },
              }}
            >
              <img
                src={VerifiedIcon}
                alt='verified-icon'
              />
              <MainHeader sx={{ fontWeight: 500, mb: 1.5 }}>Added To Cart</MainHeader>
              <FieldSubHeader3 sx={{ fontSize: '16px' }}>
                Scheme is added to investor cart
              </FieldSubHeader3>
            </Box>
          </CustomDialog>
        </Form>
      )}
    </Formik>
  );
};

export default InvestmentDetailsForm;
