import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  Grid,
  Stack,
  styled,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as DeleteIcon } from '../../../../../assets/delete-red.svg';
import { ReactComponent as Filter } from '../../../../../assets/filter.svg';
import { ReactComponent as SearchIconMobile } from '../../../../../assets/search-icon-mobile.svg';
import { useDebounce } from '../../../../../hooks/useDebounce';
import {
  StepperCancelButton,
  StepperNextButton,
} from '../../../../auth/login/styles/styledComponents';
import { CustomizedCheckbox } from '../../../../common/checkbox';
import { startLoading, stopLoading } from '../../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../../data/root.context';
import { useSchemeByfundMutation } from '../slice';
import { Scheme, SchemeByfund } from '.';

type Anchor = 'bottom';

const anchor = 'bottom';
const SearchBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'auto',
  margin: '10px 0px 20px 0px',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
const FilterBox = styled(Box)(({ theme }) => ({
  borderLeft: '1px solid',
  borderColor: theme.palette.text.borderColorLight,
  position: 'absolute',
  top: '0px',
  right: '0px',
  height: '100%',
  zIndex: 1,
  // pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
}));
const SearchIconContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export interface InvOnboardingProps {
  setSchemesByFund: React.Dispatch<React.SetStateAction<SchemeByfund[]>>;
  schemeCategoryData: string[];
  allFundData: Scheme[];
  query: string;
  chips: string[];
  setChips: React.Dispatch<React.SetStateAction<string[]>>;
  setQuery: (text: string) => void;
  schemeSearchQuery: string;
  setSchemeSearchQuery: (searchText: string) => void;
  setSchemeCategoryData: React.Dispatch<React.SetStateAction<string[]>>;
  selectedScheme: string[];
  setSelectedScheme: React.Dispatch<React.SetStateAction<string[]>>;
  setTempSchemeData: React.Dispatch<React.SetStateAction<SchemeByfund[]>>;
  selectedCategory: string[];
  setSelectedCategory: React.Dispatch<React.SetStateAction<string[]>>;
  setPage: (pageNumber: number) => void;
  setTempAllFundData: React.Dispatch<React.SetStateAction<SchemeByfund[]>>;
}

export const ResponsiveInvOnBoardingFilters = ({
  setSchemesByFund,
  allFundData,
  schemeCategoryData,
  query,
  setQuery,
  selectedScheme,
  setSelectedScheme,
  selectedCategory,
  setSelectedCategory,
  chips,
  setChips,
  schemeSearchQuery,
  setSchemeSearchQuery,
  setSchemeCategoryData,
  setTempSchemeData,
  setPage,
  setTempAllFundData,
}: InvOnboardingProps) => {
  3;
  const dispatch = useDispatch();
  const [filter, setFilter] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { showToast } = useRootContext();

  const [getSchemesByFund] = useSchemeByfundMutation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setFilter(newValue);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const handleCheckboxChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = e.target.checked;
    const fund = allFundData[index]?.fund;
    const updatedSelectedScheme = selectedScheme.includes(fund)
      ? selectedScheme.filter(scheme => scheme !== fund)
      : [...selectedScheme, fund];
    setSelectedScheme(updatedSelectedScheme);

    if (!isChecked) {
      setChips(chips.filter((chip: string) => chip !== allFundData[index]?.fundName));
    } else {
      setChips((prev: string[]) => [...new Set([...prev, allFundData[index]?.fundName])]);
    }
  };

  const handleCategoryChange = (index: number, e: any) => {
    const isChecked = e.target.checked;
    const updatedSelectedCategory = selectedCategory.includes(schemeCategoryData[index])
      ? selectedCategory.filter(category => category !== schemeCategoryData[index])
      : [...selectedCategory, schemeCategoryData[index]];

    setSelectedCategory(updatedSelectedCategory);

    if (!isChecked) {
      setChips((prev: string[]) =>
        prev.filter((chip: string) => chip !== schemeCategoryData[index]),
      );
    } else {
      setChips((prev: string[]) => [...new Set([...prev, schemeCategoryData[index]])]);
    }
  };

  const handleApplyButtonClick = async () => {
    try {
      dispatch(startLoading());
      const schemeByFundBody = {
        funds:
          selectedScheme.length !== 0
            ? selectedScheme
            : allFundData.map((item: any) => item.fund),
        category: selectedScheme.length !== 0 ? selectedCategory : [],
        investorPan: '',
        start: 0,
        end: 3000,
      };
      const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

      const resFundWithOpenKey = resFund
        .map((item: SchemeByfund) => ({
          ...item,
          open: false,
          key: nanoid(),
        }))
        ?.filter((item: any) => item.newPurAllow);

      setDrawerOpen(false);
      setSchemesByFund(resFundWithOpenKey);
    } catch (error: any) {
      showToast(error?.data?.message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleSearch = useDebounce(async (searchText: string) => {
    const fundNames = allFundData.map((item: Scheme) => item.fund);
    if (searchText !== '') {
      try {
        const schemeByFundBody = {
          funds: selectedScheme.length !== 0 ? selectedScheme : fundNames,
          category: selectedCategory.length !== 0 ? selectedCategory : [],
          investorPan: '',
          start: 0,
          end: 500,
          searchQuery: searchText,
        };
        const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

        const resFundWithOpenKey = resFund.map((item: SchemeByfund) => ({
          ...item,
          open: false,
          key: nanoid(),
        }));

        const tempCategoryData = resFund
          .map((item: any) => item.category)
          .filter((category: string) => category !== null && category !== '');
        const uniqueCategory = [...new Set(tempCategoryData)] as string[];
        setSchemesByFund(resFundWithOpenKey);
        setSchemeCategoryData(uniqueCategory);
        setTempSchemeData(resFundWithOpenKey);
        setPage(0);
        if (selectedScheme.length === 0 && selectedCategory.length === 0) {
          setTempAllFundData(resFundWithOpenKey);
        }
      } catch (error: any) {
        showToast(error?.data?.message || 'unknown error', 'error');
      }
    }
  }, 500);

  const handleSearchTextChanged = (text: string) => {
    setSchemeSearchQuery(text);
    setPage(0);
    handleSearch(text);
  };

  return (
    <>
      <SearchBox>
        <SearchIconContainer>
          <SearchIconMobile />
        </SearchIconContainer>
        <TextField
          id='filled-basic'
          variant='filled'
          placeholder='Search Scheme'
          fullWidth
          value={schemeSearchQuery}
          onChange={e => handleSearchTextChanged(e.target.value)}
          sx={(theme: Theme) => ({
            '& .MuiInputBase-root ': {
              'color': 'inherit',
              'backgroundColor': 'text.lightGray',
              'borderTopLeftRadius': '5px',
              'borderTopRightRadius': '5px',
              '& .MuiInputBase-input': {
                padding: '15px 12px 15px 40px',
                backgroundColor: 'text.lightGray',
                borderRadius: '8px 8px 0px 0px',
                fontWeight: '500',
                fontSize: '14px',
                color: 'text.labelColor',
                border: `1px solid ${theme.palette.text.borderColorLight}`,
                borderBottom: `2px solid ${theme.palette.primary.main}`,
              },
            },
          })}
        />
        <FilterBox>
          <Button
            startIcon={<Filter />}
            onClick={toggleDrawer(true)}
          >
            Sort & Filter
          </Button>
          <Drawer
            sx={{
              '& .MuiPaper-root ': {
                borderRadius: '30px 30px 0px 0px',
                backgroundColor: 'common.white',
              },
            }}
            anchor={anchor}
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '10px 25px',
                borderBottom: '1px solid',
                borderColor: 'text.borderColorDark',
              }}
            >
              <Typography
                sx={{ color: 'text.darkAzureBlue', fontSize: '16px', fontWeight: 500 }}
              >
                Filter
              </Typography>
              <Button
                sx={{ color: 'text.clearText', fontSize: '14px' }}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setSelectedCategory([]);
                  setSelectedScheme([]);
                  setChips([]);
                  setFilter(0);
                }}
              >
                Clear All
              </Button>
            </Box>
            <Grid container>
              <Grid
                item
                xs={5}
              >
                <Typography
                  sx={{
                    color: 'text.labelColor',
                    padding: '12px 16px',
                    flex: 1,
                    bgcolor: 'text.whiteLight',
                  }}
                >
                  Sort by
                </Typography>
                <Tabs
                  sx={{
                    'bgcolor': 'text.whiteLight',
                    'minHeight': '100%',
                    '& .MuiTabs-flexContainer': {
                      'alignItems': 'flex-start',
                      'pl': 0,
                      '& .MuiButtonBase-root.MuiTab-root ': {
                        'minWidth': '100%',
                        'alignItems': 'stretch',
                        'minHeight': '46px',
                        'borderTop': '0.5px solid',
                        'borderBottom': '0.5px solid',
                        'borderColor': 'text.borderColorLight',
                        '&:last-child': {
                          borderBottom: '0px',
                        },
                        '& .MuiTypography-root': {
                          fontSize: '14px',
                          fontWeight: '400',
                          color: 'text.labelColor',
                          textAlign: 'left',
                        },
                        '&.Mui-selected': {
                          'bgcolor': 'rgba(32, 87, 166, 0.15)',
                          '& .MuiTypography-root': {
                            color: 'primary.main',
                            fontWeight: '500',
                          },
                        },
                      },
                    },
                    '& .MuiTabs-indicator': {
                      left: 0,
                      height: '46px',
                      width: '2px',
                    },
                  }}
                  orientation='vertical'
                  variant='scrollable'
                  value={filter}
                  onChange={handleChange}
                  aria-label='Vertical tabs example'
                >
                  {['AMC', 'Category'].map((item, index) => {
                    return (
                      <Tab
                        key={index}
                        label={
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <Typography>{item}</Typography>
                            {item === 'AMC' ? (
                              <Typography>{allFundData?.length}</Typography>
                            ) : (
                              <Typography>{schemeCategoryData?.length}</Typography>
                            )}
                          </Stack>
                        }
                        {...a11yProps(index)}
                      />
                    );
                  })}
                </Tabs>
              </Grid>
              <Grid
                item
                xs={7}
              >
                {filter === 0 && (
                  <TextField
                    fullWidth
                    type='serach'
                    placeholder='search'
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    InputProps={{
                      startAdornment: <SearchIconMobile />,
                    }}
                    sx={{
                      // 'p': '0px 20px',
                      // 'mb': 3,
                      '& .MuiInputBase-root': {
                        '&.Mui-focused,&:hover': {
                          '& .MuiOutlinedInput-notchedOutline ': {
                            border: 'unset',
                          },
                        },
                      },
                      '& fieldset': {
                        border: 'unset',
                      },
                      '& .MuiInputBase-input': {
                        padding: '16px 14px',
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'text.labelColor',
                        border: 'none',
                      },
                    }}
                  />
                )}
                <TabPanel
                  value={filter}
                  index={0}
                >
                  {allFundData?.map((amc, index) => (
                    <FormControlLabel
                      sx={{
                        'display': 'flex',
                        'borderTop': '1px solid',
                        'borderBottom': '1px solid',
                        'borderColor': 'text.borderColorLight',
                        'm': 0,
                        'p': '16px 10px',
                        '& .MuiTypography-root ': {
                          fontSize: '12px',
                          fontWeight: 500,
                          color: 'text.labelColor',
                        },
                      }}
                      key={index}
                      control={
                        <CustomizedCheckbox
                          checked={selectedScheme.includes(amc?.fund)}
                          onChange={async e => handleCheckboxChange(index, e)}
                        />
                      }
                      label={amc?.fundName}
                    />
                  ))}
                </TabPanel>

                <TabPanel
                  value={filter}
                  index={1}
                >
                  {schemeCategoryData?.map((scheme, index) => (
                    <FormControlLabel
                      sx={{
                        'display': 'flex',
                        'borderTop': '1px solid',
                        'borderBottom': '1px solid',
                        'borderColor': 'text.borderColorLight',
                        'm': 0,
                        'p': '16px 10px',
                        '& .MuiTypography-root ': {
                          fontSize: '12px',
                          fontWeight: 500,
                          color: 'text.labelColor',
                        },
                      }}
                      key={index}
                      control={
                        <CustomizedCheckbox
                          checked={selectedCategory.includes(scheme)}
                          onChange={async e => handleCategoryChange(index, e)}
                        />
                      }
                      label={scheme}
                    />
                  ))}
                </TabPanel>
              </Grid>
            </Grid>
            <Stack
              direction={'row'}
              columnGap={'16px'}
              sx={{ padding: '20px', bgcolor: 'common.white' }}
            >
              <StepperCancelButton
                sx={{
                  '&.MuiButtonBase-root.MuiButton-root': {
                    padding: '10px 20px',
                  },
                }}
                onClick={() => {
                  setDrawerOpen(false);
                  setSelectedCategory([]);
                  setSelectedScheme([]);
                  setChips([]);
                  setFilter(0);
                }}
              >
                Cancel
              </StepperCancelButton>
              <StepperNextButton
                sx={{
                  '&.MuiButtonBase-root.MuiButton-root': {
                    padding: '10px 20px',
                  },
                }}
                onClick={() => {
                  handleApplyButtonClick();
                }}
              >
                Apply
              </StepperNextButton>
            </Stack>
          </Drawer>
        </FilterBox>
      </SearchBox>
    </>
  );
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ maxHeight: '400px', overflowY: 'auto', mt: '5px' }}
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    'id': `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
