import {
  Box,
  CardMedia,
  FormControlLabel,
  IconButton,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { FormikProps } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AmountIcon from '../../../assets/amount-coin.svg';
import { ReactComponent as AddIcon } from '../../../assets/circle-add-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import {
  Investorcard,
  MainHeader,
  StepperAddButton,
  StepperNextButton,
} from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import Checkbox from '../../common/checkbox';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { CheckoutPaymentFormValues, Mandate } from '../additionalPurchase/types';
import TransactionFactory from '../common/TransactionsFactory';
import Layout from '../Layout';
import { useInvestorDetailsMutation } from '../mfs/newInvestorOnboarding/slice';
import {
  useGetCartItemsMutation,
  useGetIndividualSchemeDetailsMutation,
  useRemoveItemsFromCartMutation,
} from '../slice';
import { AplTrtType, SchemeDetails, TransactionTypeRoute } from '../types';
import { TransactionComponentsType, TransactionType } from '../types';
import { findLogo } from '../utils';
import PaymentModesForm from './paymentModesForm';
import { useCheckoutCartMutation } from './slice';
import { AlpPaymentModesPayload, PayloadWithDetails } from './types';
interface CheckoutButtonDetails {
  isCheckoutSelected: boolean;
  checkoutTitle: string;
}
export default function Cart(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editIndex, setEditIndex] = useState<number>(-1);
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const [removeItemsFromCart] = useRemoveItemsFromCartMutation();
  const [cartItems, setCartItems] = useState<SchemeDetails[]>([]);
  const [checkedItems, setCheckedItems] = useState<boolean[]>([]);
  const [getCartItems] = useGetCartItemsMutation();
  const { showToast } = useRootContext();
  const transactionType = cartItems[0]?.transactionType || '';

  const { state } = useLocation() as { state: any };

  const [individualSchemeDetails] = useGetIndividualSchemeDetailsMutation();
  const [getData] = useInvestorDetailsMutation();
  const [checkoutCart] = useCheckoutCartMutation();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [selectedMandate, setSelectedMandate] = useState<Mandate>({
    label: '',
    value: '',
    mandateLimit: 0,
    startDate: '',
    endDate: '',
    bankAccountNumber: '',
    bankName: '',
  });
  const [checkoutButtonDetails, setCheckoutButtonDetails] =
    useState<CheckoutButtonDetails>({
      isCheckoutSelected: false,
      checkoutTitle: 'Check out',
    });
  const formikRef = useRef<FormikProps<CheckoutPaymentFormValues>>(null);
  const [bacthId, setBatchId] = useState('');
  const cartHeader =
    transactionType === 'ISIP' || transactionType === 'SIN' || transactionType === 'APL'
      ? `Additional Purchase - ${AplTrtType[transactionType as keyof typeof AplTrtType]}`
      : transactionType === 'NPL' || transactionType === 'SINI'
      ? 'New Purchase'
      : transactionType === 'RED'
      ? 'Redeem'
      : transactionType;

  const [showPaymentsForm, setShowPaymentsForm] = useState<boolean>(false);
  const [aplPaymentsModePayload, setAplPaymentsModePayload] =
    useState<AlpPaymentModesPayload>({
      fund: '',
      pan: '',
      folio: '',
      trtype: '',
      modeOfRegistration: '',
      amount: 0,
    });

  useEffect(() => {
    async function getDitCartItems() {
      try {
        const payload = {
          pan: pan,
        };
        dispatch(startLoading());
        const response = await getCartItems(payload).unwrap();
        const cartItems = response.cartItems;
        if (
          cartItems[0]?.transactionType === 'NPL' ||
          cartItems[0]?.transactionType === 'SINI'
        ) {
          setCartItems(cartItems);
          return;
        }
        const promises = response.cartItems.map(async (cartItem: any) => {
          const payloadObj = JSON.parse(cartItem.payload);
          const getSchemeDetailpayload = {
            pan: pan,
            folio: String(payloadObj.folio),
            fund: payloadObj.fund,
            trtype: cartItem.transactionType,
            scheme: payloadObj?.scheme,
            option: payloadObj?.option,
            plan: payloadObj?.plan,
          };
          let details = {};
          if (
            !!transactionType &&
            transactionType !== 'ISIP' &&
            transactionType !== 'SIN' &&
            transactionType !== 'APL' &&
            transactionType !== 'STP'
          ) {
            details = await individualSchemeDetails(getSchemeDetailpayload).unwrap();
          }
          return {
            cartItemId: cartItem.cartItemId,
            folio: payloadObj.folio,
            schemeName: payloadObj.schemeName,
            fund: payloadObj.fund,
            cartAmount: cartItem.investmentAmount,
            cartUnits: cartItem.units,
            transactionType: cartItem.transactionType,
            cartId: cartItem?.cartId,
            pan: pan.toUpperCase(),
            investmentAmount: cartItem.investmentAmount,
            sipAmount: cartItem.sipAmount,
            installmentAmount: cartItem.installmentAmount,
            ...payloadObj,
            ...(details ?? {}),
          };
        });

        const newData = await Promise.all(promises);
        setCartItems(newData);
      } catch (error: any) {
        showToast(error.data?.message ?? 'Unknown error', 'error');
      } finally {
        dispatch(stopLoading());
      }
    }
    getDitCartItems();
  }, [editIndex]);

  useEffect(() => {
    setCheckedItems(new Array(cartItems.length).fill(true));
  }, [cartItems]);
  const handleItemDelete = async (index: number): Promise<void> => {
    const payload = {
      cartItemId: cartItems[index].cartItemId,
      cartId: cartItems[index].cartId,
      pan: pan,
    };
    try {
      dispatch(startLoading());
      const result = await removeItemsFromCart(payload);
      if ('data' in result) {
        const updatedCartItems = cartItems.filter((item, i) => i !== index);
        setCartItems(updatedCartItems);
        showToast((result.data as { message: string }).message, 'success');
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };
  const handleCheckboxChange = (index: number) => {
    setCheckedItems(prev => prev.map((item, idx) => (idx === index ? !item : item)));
  };

  const totalSIPValue = cartItems?.reduce((total, item, index) => {
    if (checkedItems[index]) {
      const payloadOb = item?.payload ? JSON.parse(item.payload) : {};
      return total + (payloadOb?.installmentAmount ?? 0);
    }
    return total;
  }, 0);
  const totalSIPValueAlp = cartItems?.reduce((total, item, index) => {
    if (checkedItems[index]) {
      return total + (item?.sipAmount ?? 0);
    }
    return total;
  }, 0);

  const totalLumpsumAmt = cartItems.reduce((total, item, index) => {
    if (checkedItems[index]) {
      return total + (item.investmentAmount ?? 0);
    }
    return total;
  }, 0);

  const totalSipAndLumpsumAmount = cartItems.reduce((total, item, index) => {
    if (checkedItems[index]) {
      return total + (item.investmentAmount ?? 0) + (item.sipAmount ?? 0);
    }
    return total;
  }, 0);
  const handleCheckout = async () => {
    const selectedCartItems = cartItems.filter(
      (item, index) => checkedItems[index] && item.cartItemId,
    );
    if (selectedCartItems.length === 0) {
      showToast('Please select at least one item', 'error');
      return;
    }

    const selectedCartItemsIds = selectedCartItems.map(item => item.cartItemId);
    const cartItemIds = selectedCartItems.map(item => item.cartItemId);

    try {
      dispatch(startLoading());
      if (transactionType === 'NPL' || transactionType === 'SINI') {
        const invResponse = await getData({ pan }).unwrap();
        navigate('/transaction-dashboard/new-purchase/investor-details', {
          state: {
            ...state,
            cartItemIds: selectedCartItemsIds,
            invResponse,
            cartItems: selectedCartItems,
            previousUrl: 'cart',
          },
        });
      } else {
        let payload = {
          cartId: cartItems?.[0]?.cartId,
          pan: pan,
          selectedCartItems: cartItemIds,
        };
        if (['ISIP', 'SIN', 'APL'].includes(transactionType)) {
          if (!checkoutButtonDetails.isCheckoutSelected) {
            setShowPaymentsForm(true);
            setAplPaymentsModePayload({
              fund: cartItems?.[0]?.fund,
              pan: cartItems?.[0]?.pan,
              folio: String(cartItems?.[0]?.folio),
              trtype: cartItems?.[0]?.transactionType as string,
              modeOfRegistration: cartItems?.[0]?.modeOfRegistration as string,
              amount: cartItems?.[0]?.investmentAmount as number,
            });
            setCheckoutButtonDetails({
              isCheckoutSelected: true,
              checkoutTitle: 'Save and Proceed',
            });
            return;
          }

          let payloadObj: PayloadWithDetails = {
            selectedMandates: selectedMandate,
            createMandate: selectedMandate?.value === 'createMandate',
            umrn:
              selectedMandate?.value === 'createMandate'
                ? ''
                : formikRef?.current?.values?.umrn,
          };
          if (cartItems?.[0]?.transactionType === 'APL') {
            payloadObj = {
              ...payload,
              ...(formikRef?.current?.values?.paymentMode === 'kotm' && {
                ...payloadObj,
              }),
              paymentDetails: {
                paymentMode: formikRef?.current?.values?.paymentMode || '',
                pioTransaction: false,
                utrno: '',
              },
            };
          } else {
            payloadObj = {
              ...payload,
              ...payloadObj,
              modeOfRegistration: formikRef?.current?.values?.modeOfRegistration,
              ...(cartItems?.[0]?.transactionType === 'SIN' && {
                paymentDetails: {
                  paymentMode: formikRef?.current?.values?.paymentMode || '',
                  pioTransaction: false,
                  utrno: '',
                },
              }),
            };
          }
          const checkoutResponse = await checkoutCart(payloadObj).unwrap();
          setBatchId(checkoutResponse?.batchId || checkoutResponse?.ihno);
          setOpenDialogBox(true);
        } else {
          const checkoutResponse = await checkoutCart(payload).unwrap();
          setBatchId(checkoutResponse?.batchId || checkoutResponse?.ihno);
          setOpenDialogBox(true);
        }
      }
    } catch (error: any) {
      const message = error?.response?.data?.message || error.message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };
  const checkIfAllItemsContainUnits = () => {
    return cartItems.some((item: SchemeDetails) => item.cartUnits);
  };

  const calculateTotalAmount = () => {
    const transactionType = cartItems[0]?.transactionType;
    const investmentAmount = cartItems[0]?.investmentAmount;
    const sipAmount = cartItems[0]?.sipAmount;
    const cartAmount = cartItems[0]?.cartAmount;
    const cartUnits = cartItems[0]?.cartUnits;
    const showLumpsumAmt =
      transactionType === 'NPL' ||
      transactionType === 'APL' ||
      transactionType === 'SWP' ||
      transactionType === 'STP' ||
      transactionType === 'SWITCH' ||
      transactionType === 'RED';

    if (showLumpsumAmt) {
      return totalLumpsumAmt;
    } else if (investmentAmount !== 0 && sipAmount !== 0) {
      return totalSipAndLumpsumAmount;
    } else if (transactionType === 'ISIP') {
      return totalSIPValueAlp;
    } else {
      return totalSIPValue;
    }
  };
  const amcLogo = findLogo(cartItems?.[0]?.fund);
  return (
    <>
      <Layout
        title='Cart'
        onBackPress={() => {
          editIndex !== -1 ? setEditIndex(-1) : navigate(-1);
        }}
        subtitle={editIndex !== -1 ? 'Update cart item' : 'Please proceed for checkout'}
        rightBottomComponent={
          editIndex === -1 && (
            <Investorcard
              sx={(theme: Theme) => ({
                bgcolor: { xs: 'unset', sm: 'text.rightProfileCard' },
                padding: { xs: '5px 10px', sm: '20px' },
                my: { xs: 1, sm: 3 },
                display: { xs: 'block', sm: 'flex' },
                flexWrap: 'wrap',
                flexDirection: 'column',
                alignItems: { xs: 'start', sm: 'center' },
                [theme.breakpoints.down('sm')]: {
                  border: 'unset',
                  boxShadow: 'unset',
                },
              })}
            >
              {!checkIfAllItemsContainUnits() && (
                <Box
                  sx={{
                    display: { xs: 'flex' },
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'row', sm: 'column' },
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: 'flex' },
                      columnGap: '20px',
                      alignItems: 'center',
                      flexDirection: { xs: 'row', sm: 'column' },
                    }}
                  >
                    <CardMedia
                      sx={{
                        '&.MuiCardMedia-root': {
                          width: { xs: '15%', sm: 'unset' },
                          my: 3,
                        },
                      }}
                      component='img'
                      image={AmountIcon}
                      alt='amount'
                    />
                    <Typography sx={{ color: 'text.labelColor', mb: { xs: '0', sm: 1 } }}>
                      Total Amount
                    </Typography>
                  </Box>
                  <Typography variant='h3'>
                    ₹{calculateTotalAmount().toFixed(2)}
                  </Typography>
                </Box>
              )}

              <StepperNextButton
                sx={{ mt: { xs: 0.5, sm: 5 }, minWidth: { xs: '100%' } }}
                onClick={handleCheckout}
              >
                {checkoutButtonDetails.checkoutTitle}
              </StepperNextButton>
            </Investorcard>
          )
        }
        rightBottomComponentDivider
      >
        <Typography
          variant='subtitle1'
          sx={{ color: 'primary.main', mb: 2, display: { xs: 'block', sm: 'none' } }}
        >
          Please proceed for checkout
        </Typography>

        <Investorcard
          sx={theme => ({
            padding: '30px',
            position: 'relative',
            mb: '20px',
            [theme.breakpoints.down('sm')]: {
              border: 'unset',
              boxShadow: 'unset',
              padding: '0px',
              minWidth: '100%',
            },
          })}
        >
          <Typography
            sx={{
              fontSize: { xs: '16px', xl: '24px' },
              fontWeight: 500,
              color: 'primary.main',
              mb: 2,
            }}
          >
            {cartHeader || ''}
          </Typography>

          {cartItems.length > 0 ? (
            cartItems.map((item: any, index: number) => {
              return (
                <Fragment key={item.cartItemId}>
                  {editIndex === index ? (
                    <TransactionFactory
                      type={
                        TransactionType[transactionType as keyof typeof TransactionType]
                      }
                      component={TransactionComponentsType.InvestmentDetailsForm}
                      data={item}
                      cartItemslength={cartItems?.length > 1}
                      isEditMode
                      handleCancelModifyingCart={() => setEditIndex(-1)}
                    />
                  ) : (
                    editIndex < 0 && (
                      <TransactionFactory
                        type={
                          TransactionType[transactionType as keyof typeof TransactionType]
                        }
                        component={TransactionComponentsType.Cart}
                        data={item}
                        cartItemslength={cartItems?.length > 1}
                      >
                        <Stack
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1.15,
                          }}
                        >
                          <Stack sx={{ flexDirection: 'row' }}>
                            {' '}
                            <FormControlLabel
                              sx={{
                                'mr': 1,
                                '& .MuiTypography-root ': {
                                  color: 'text.primary',
                                  fontWeight: '500',
                                  fontSize: '14px',
                                },
                              }}
                              control={
                                <Checkbox
                                  sx={{
                                    'cursor': 'pointer',
                                    '&.Mui-checked': {
                                      color: 'primary.main',
                                    },
                                  }}
                                  disableRipple
                                  checked={!!checkedItems[index]}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                              }
                              label={''}
                            />
                            <Box
                              sx={theme => ({
                                'borderRadius': '5px',
                                'border': `0.5px solid ${theme.palette.text.borderColorLight}`,
                                'boxShadow': '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                                // 'padding': '0px 8px',
                                'display': 'flex',
                                'justifyContent': 'center',
                                'alignItems': 'center',
                                'width': { xs: '35px', lg: '55px' },
                                'height': { xs: '35px', lg: '55px' },
                                '& svg': {
                                  width: { xs: '25px', lg: '35px' },
                                  height: { xs: '25px', lg: '35px' },
                                },
                              })}
                            >
                              {amcLogo?.icon && <amcLogo.icon />}
                            </Box>
                          </Stack>

                          <Stack
                            direction='row'
                            gap='5px'
                            sx={{
                              'gap': '5px',
                              'flexDirection': 'row',
                              '& svg': {
                                width: '30px',
                                height: '30px',
                              },
                            }}
                          >
                            <IconButton onClick={() => setEditIndex(index)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={async () => handleItemDelete(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </TransactionFactory>
                    )
                  )}
                </Fragment>
              );
            })
          ) : (
            <Typography sx={{ mb: 2, fontSize: '14px', fontWeight: 500 }}>
              No Items in Cart
            </Typography>
          )}
          {cartItems.length < 5 && editIndex === -1 && (
            <StepperAddButton
              sx={{
                fontSize: { xs: '14px', xl: '16px' },
                color: 'text.navyBlue',
                fontWeight: 600,
                border: '1px solid',
                borderColor: 'text.mediumDarkBlue',
                WebkitTextFillColor: 'unset',
                background: 'unset',
                minWidth: '220px',
                padding: { xs: '8px 20px', md: '11px 20px' },
              }}
              startIcon={<AddIcon />}
              onClick={() => {
                const upperCaseTransactionType = transactionType?.toUpperCase();
                const route =
                  upperCaseTransactionType &&
                  upperCaseTransactionType in TransactionTypeRoute
                    ? TransactionTypeRoute[
                        upperCaseTransactionType as keyof typeof TransactionTypeRoute
                      ]
                    : '';

                if (route) {
                  navigate(`../${route}`, {
                    // TODO - use isFromCart
                    state: {
                      ...state,
                      cartItem: cartItems,
                      isFormCart: true,
                      isFromCart: true,
                    },
                    replace: true,
                  });
                } else {
                  // Optionally navigate to a default route
                  navigate('../transact', {
                    replace: true,
                    state: {
                      ...state,
                    },
                  });
                }
              }}
              // disabled={cartItems.length >= 5}
            >
              Add More Funds
            </StepperAddButton>
          )}
        </Investorcard>

        {/* PaymentModesForm for ALP*/}
        {showPaymentsForm && editIndex === -1 && cartItems.length >= 1 && (
          <Investorcard
            sx={theme => ({
              padding: '30px',
              [theme.breakpoints.down('sm')]: {
                border: 'unset',
                boxShadow: 'unset',
                padding: '0px',
                minWidth: '100%',
              },
            })}
          >
            <PaymentModesForm
              formikRef={formikRef}
              aplPaymentsModePayload={aplPaymentsModePayload}
              selectedMandate={selectedMandate}
              setSelectedMandate={setSelectedMandate}
            />
          </Investorcard>
        )}
      </Layout>
      {openDialogBox && (
        <CustomDialog
          isVisible={true}
          firstButtonHandler={() => {}}
          secondButtonHandler={() => {
            navigate('../', { replace: true });
          }}
          firstButtonTitle=''
          secondButtonTitle='OK'
          handleClose={() => {}}
          showSecondButton
          showFirstButton={false}
        >
          <Box sx={{ textAlign: 'center', pt: 2 }}>
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <MainHeader sx={{ fontWeight: 500, mb: 2 }}>
              Transaction submitted successfully with batch number {bacthId}
            </MainHeader>
          </Box>
        </CustomDialog>
      )}
    </>
  );
}
