import { Box, Divider, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import { freqType, getEnumValueFromString } from '../../../hooks/useSip';
import useSipNew from '../../../hooks/useSipData';
import { calculateDifference } from '../../../utils';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import CustomLoader from '../../common/CustomLoader';
import { useRootContext } from '../../data/root.context';
import FormCheckbox from '../../forms/FormCheckbox';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { arnRegex } from '../common';
import SubmitCartButtons from '../common/SubmitCartButtons';
import EuinsFields from '../euinsComponent';
import { useSchemeByfundMutation } from '../mfs/newInvestorOnboarding/slice';
import { dateToDDMMYYY } from '../nps/investorOnBoarding/utils';
import {
  useGetEUINSQuery,
  useGetSchemaDetailsMutation,
  useListOfSchemesMutation,
  useModifyItemInCartMutation,
} from '../slice';
import { AlpModeType, Investment, ModeTypeToTrtType } from '../types';
import { getPerpetualSipEndDate, maxInstallmentBasedOnFrequency } from '../utils';
import { areAllTheValuesEmpty } from './common';
import {
  ExistingAndNewFoliosType,
  SchemaData,
  SchemesByFund,
  SelectedScheme,
} from './types';

interface FormValues {
  selectedAmc: string;
  schemeName: string;
  folioNumber: string;
  modeType: string;
  schemeType: string;
  modeOfRegistration: string;
  frequency: string;
  installments: number;
  sipDay: string;
  startDate: string | null;
  endDate: string | null;
  installmentAmount: string;
  amount: number;
  category: string;
  perpetual: boolean;
  euin: string;
}
const validationSchema = Yup.object().shape({
  arnCode: Yup.string().matches(arnRegex, 'Invalid ARN Code'),
  subArnCode: Yup.string().matches(arnRegex, 'Invalid Sub ARN Code'),
  frequency: Yup.string().when(['modeType', 'installmentAmount'], {
    is: (modeType: string, installmentAmount: string) =>
      modeType !== 'Lumpsum' && installmentAmount,
    then: Yup.string().required('Frequency is required'),
  }),
  sipDay: Yup.string().when(['modeType', 'installmentAmount'], {
    is: (modeType: string, installmentAmount: string) =>
      modeType !== 'Lumpsum' && installmentAmount,
    then: Yup.string().required('SIP Day is required'),
  }),
  installments: Yup.string().when(['modeType', 'installmentAmount'], {
    is: (modeType: string, installmentAmount: string) =>
      modeType !== 'Lumpsum' && installmentAmount,
    then: Yup.string().required('No. of Installments is required'),
  }),
  folioNumber: Yup.string().required('Folio Number is required'),
  schemeType: Yup.string().required('Scheme Type is required'),
  schemeName: Yup.string().required('Scheme Name is required'),
  category: Yup.string().when('schemeType', {
    is: 'new',
    then: Yup.string().required('Category is required'),
  }),
  amount: Yup.string().when('modeType', {
    is: (modeType: string) => modeType === 'Lumpsum' || modeType === 'LumpsumAndSIP',
    then: Yup.string()
      .required('Lumpsum amount is required')
      .matches(/^\d+(\.\d{1,2})?$/, 'Invalid amount'),
    otherwise: Yup.string().notRequired(), // No validation error for 'SIP'
  }),
  installmentAmount: Yup.string().when('modeType', {
    is: (modeType: string) => modeType === 'SIP' || modeType === 'LumpsumAndSIP',
    then: Yup.string()
      .required('Investment Amount is required')
      .min(1, 'Minimum Investment amount should not be 0')
      .matches(/^[0-9]+$/, 'Investment Amount must contain only numbers'),
  }),
});
interface InvestmentDetailsFormProps {
  data: any;
  handleCancelModifyingCart?: () => void;
}

const EditTransactionAPL: React.FC<InvestmentDetailsFormProps> = ({
  data,
  handleCancelModifyingCart,
}) => {
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const [investorSchemes, setInvestorSchemes] = useState<Investment[]>([]);
  const [investorDetailsList] = useListOfSchemesMutation();
  const [selectedScheme, setSelectedScheme] = useState<SelectedScheme | {}>({});
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const navigate = useNavigate();
  const [schemesByFund, setSchemesByFund] = useState<SchemesByFund>({
    allSchemes: [],
    categories: [],
    selectedCategory: data?.category, // initializing with selected Category
  });
  const [schemaData, setSchemaData] = useState<SchemaData | {}>({});
  const [frequencyType, setFrequencyType] = useState(
    freqType[data?.sipFrequency as keyof typeof freqType],
  );

  const [getSchemesByFund] = useSchemeByfundMutation();
  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const { showToast } = useRootContext();
  const [sipDay, setSipDay] = useState<string>(String(data?.sipDay || 1));
  const firstRender = useRef<boolean>(false);
  const [installments, setInstallments] = useState<number>(data?.noOfInstallments || 0);
  const [modifyItemInCart] = useModifyItemInCartMutation(); // Modify items in cart
  const [isLoading, setIsLoading] = useState<boolean>();
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const initialInvestorSchemes = useRef<Investment[]>([]);
  const isSchemeChanged = useRef<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormValues>({
    selectedAmc: '',
    schemeName: '',
    folioNumber: '', // folios mapped.
    modeType: '',
    schemeType: '',
    modeOfRegistration: '',
    frequency: '',
    installments: 0,
    sipDay: sipDay,
    startDate: null,
    endDate: null,
    installmentAmount: '',
    amount: 0, // Lumpsum amount
    category: '',
    perpetual: false,
    euin: '',
  });
  const [isPerpetual, setIsPerpetual] = useState(false);
  const [existingAndNewFolios, setExistingAndNewFolios] =
    useState<ExistingAndNewFoliosType>({
      existingFolios: [],
      newFolios: [],
    });
  const { data: allEUINs } = useGetEUINSQuery([]);
  const isExistingScheme = data?.purchaseType === 'EXISTING';

  const getFolioDropDownOptions = (schemesArr: any[]) => {
    return schemesArr
      ?.filter(inv => inv.fund === data?.fund)
      .map(item => ({ label: item.folio, value: item.folio }));
  };

  const frequencyModes: string[] = useMemo(() => {
    const modes = (schemaData as SchemaData)?.frequency?.map((f: any) => f.mode) || [];
    return Array.from(new Set(modes));
  }, [(schemaData as SchemaData)?.frequency]);

  const [aplDates, minInstallments, minAmountAllowed, tatTime]: [
    string[],
    number,
    number,
    number,
  ] = useMemo(() => {
    const frequency = (schemaData as SchemaData)?.frequency?.find(
      (f: any) => f.mode === frequencyType,
    );
    const cycle = frequency?.cycle;
    const dates =
      !cycle || !/\d/.test(cycle)
        ? Array.from({ length: 28 }, (_, index) => `${index + 1}`)
        : cycle.split(',');
    const tatTime = (schemaData as SchemaData)?.frequency?.find(
      (f: any) => f.mode === frequencyType,
    )?.coolingPeriod;
    return [dates, frequency?.minInstallments, frequency?.minTransactionAmount, tatTime];
  }, [(schemaData as SchemaData)?.frequency, frequencyType]);

  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      const payload = {
        pan: pan,
      };
      try {
        const response = await investorDetailsList(payload).unwrap();
        const filteredSchemes = response?.filter(
          (item: { additionalPurAllow: boolean }) => item.additionalPurAllow,
        );
        setInvestorSchemes(filteredSchemes);
        initialInvestorSchemes.current = response;
        if (isExistingScheme) {
          const [scheme] =
            filteredSchemes?.filter(
              (inv: Investment) => inv.fund === data?.fund && inv.folio === data?.folio,
            ) || [];
          setSelectedScheme(scheme); // initialize the selected scheme
        }
        setExistingAndNewFolios({
          ...existingAndNewFolios,
          existingFolios: getFolioDropDownOptions(filteredSchemes),
          newFolios: getFolioDropDownOptions(initialInvestorSchemes?.current),
        });
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    }

    getInvestorDetailsSchemes();
  }, []);
  useEffect(() => {
    const fetchSchemesByFund = async () => {
      const resFund = await getSchemesByFund({
        funds: [data?.fund],
        investorPan: '',
        start: 0,
        end: 20000,
      }).unwrap();
      const filteredData = resFund.filter((row: any) => row.additionalPurAllow === true);
      const uniqueCategories = [
        ...new Set(filteredData.map((item: any) => item.category)),
      ];

      if (!isExistingScheme) {
        const [scheme] =
          filteredData?.filter(
            (inv: any) =>
              inv.fund === data?.fund &&
              inv.category === data?.category &&
              inv.scheme === data?.scheme &&
              inv.option === data?.option &&
              inv.plan === data?.plan,
          ) || [];
        setSelectedScheme(scheme);
      }

      setSchemesByFund({
        ...schemesByFund,
        allSchemes: filteredData,
        categories: uniqueCategories as string[],
      });
      firstRender.current = true;
    };
    if (data?.fund && !firstRender.current) {
      fetchSchemesByFund();
    }
  }, [data]);
  const isValidDate = (dateString: string | undefined): boolean => {
    const date = new Date(dateString as string);
    return date instanceof Date && !isNaN(date.getTime());
  };

  useEffect(() => {
    const scheme = (selectedScheme as SelectedScheme)?.scheme;

    const fetchSchemaDetails = async () => {
      const payload = {
        fund: data?.fund,
        plan: (selectedScheme as SelectedScheme)?.plan,
        option: (selectedScheme as SelectedScheme)?.option,
        trType: data?.transactionType !== 'APL' ? 'SIP' : 'APL',
        scheme,
      };

      const result = await getSchemaDetails(payload).unwrap();

      if (result.error) {
        throw new Error(result.error.data.message);
      }

      setSchemaData(result);
      // set initial values here.
      if (!isSchemeChanged.current) {
        // for setting the initial page.
        const schemeDesc = (selectedScheme as SelectedScheme)?.schemeDesc;
        const planDesc = (selectedScheme as SelectedScheme)?.planDesc;
        setInitialValues({
          ...initialValues,
          selectedAmc: data?.fundName || '',
          schemeName: !isExistingScheme ? `${schemeDesc} - ${planDesc}` : scheme,
          folioNumber: data?.folio, // folios mapped.
          modeType: AlpModeType[data?.transactionType as keyof typeof AlpModeType],
          schemeType: isExistingScheme ? 'existing' : 'new',
          modeOfRegistration: data?.modeOfRegistration || '',
          frequency: frequencyType,
          installments: installments,
          sipDay: sipDay,
          startDate: isValidDate(data?.sipStartDate)
            ? format(new Date(data.sipStartDate), 'eee do MMMM yyyy')
            : null,
          endDate: isValidDate(data?.sipEndDate)
            ? format(new Date(data.sipEndDate), 'eee do MMMM yyyy')
            : null,
          installmentAmount: data?.sipAmount || '',
          amount: data?.amount || 0, // Lumpsum amount
          category: !isExistingScheme ? schemesByFund?.selectedCategory : '',
          perpetual: data?.perpetual || false,
          euin: data?.euin || '',
        });
      }
    };

    if (data?.fund && scheme) {
      fetchSchemaDetails();
    }
  }, [selectedScheme]);

  function getInvestment(fund?: string, folio?: number): any[] {
    if (fund && folio) {
      return investorSchemes?.filter(inv => inv.fund === fund && inv.folio === folio);
    } else if (fund) {
      const data =
        formikRef?.current?.values?.schemeType == 'new'
          ? initialInvestorSchemes?.current?.filter(inv => inv.fund === fund)
          : investorSchemes?.filter(inv => inv.fund === fund);
      return data;
    } else {
      return investorSchemes;
    }
  }
  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);

      const {
        fundName,
        mobile,
        email,
        pan,
        fund,
        investorName,
        TRACEID,
        folioBanks,
        cartId,
        cartItemId,
        modeOfRegistration,
        invCategory,
      } = data;
      const { folioNumber, amount, installments, installmentAmount, sipDay } = values;

      const scheme = (selectedScheme as SelectedScheme)?.scheme;
      const option = (selectedScheme as SelectedScheme)?.option;
      const plan = (selectedScheme as SelectedScheme)?.plan;
      const schemeName = (selectedScheme as SelectedScheme)?.schemeName;
      const category = (selectedScheme as SelectedScheme)?.category;

      let payload;
      const commonPayload = {
        investorName,
        pan,
        fund,
        fundName,
        scheme,
        plan,
        option,
        modeOfRegistration,
        schemeName,
        category,
        sipDay,
        mobile,
        email,
        TRACEID,
        folioBanks,
        cartId,
        cartItemId,
        invCategory,
        purchaseType: values?.schemeType === 'existing' ? 'EXISTING' : 'NEW',
      };
      const sipDates = {
        sipFrequency: getEnumValueFromString(values?.frequency),
        sipStartDate: format(startDate as Date, 'MM/dd/yy'),
        sipEndDate: format(endDate as Date, 'MM/dd/yy'),
        noOfInstallments: installments,
      };
      switch (values.modeType) {
        case 'Lumpsum':
          payload = {
            ...commonPayload,
            folio: folioNumber,
            amount,
            transactionType: ModeTypeToTrtType.Lumpsum,
            type: values?.schemeType === 'existing' ? 'EXISTING' : 'NEW',
          };
          break;

        case 'SIP':
          payload = {
            ...commonPayload,
            folio: folioNumber,
            ...sipDates,
            remarks: '0',
            transactionType: ModeTypeToTrtType.SIP,
            installmentAmount,
            amount: 0,
          };
          break;
        case 'LumpsumAndSIP':
          payload = {
            ...commonPayload,
            remarks: '0',
            installmentAmount,
            amount,
            folio: folioNumber,
            ...sipDates,
            transactionType: ModeTypeToTrtType.LumpsumAndSIP,
          };
          break;
        default:
          break;
      }
      await modifyItemInCart(payload).unwrap();
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      setIsLoading(false);
      if (handleCancelModifyingCart) {
        handleCancelModifyingCart();
      }
    }
  };

  const clearAllValues = (setFieldValue: (field: string, value: any) => void) => {
    setFieldValue('category', '');
    setFieldValue('schemeName', '');
    setFieldValue('folioNumber', '');
    setFieldValue('frequency', '');
    setFieldValue('sipDay', '');
    setFieldValue('installments', '');
    setFieldValue('installmentAmount', '');
    setFieldValue('amount', '');
    setFieldValue('startDate', null);
    setFieldValue('endDate', null);
  };
  const [startDate, endDate, errors] = useSipNew({
    fund: data?.fund,
    scheme: data?.scheme,
    frequency: frequencyType || '',
    installments: Number(installments),
    trtype:
      ModeTypeToTrtType[
        formikRef?.current?.values?.modeType as keyof typeof ModeTypeToTrtType
      ],
    day: sipDay ? Number(sipDay) : 1,
    isPerpetual: isPerpetual,
  });
  const isFrequencySelected = frequencyType && installments > 0 && sipDay;
  useEffect(() => {
    if (!!errors && errors?.showError && isFrequencySelected) {
      showToast(errors?.errorMessage, 'error');
    }
  }, [errors]);
  const handlePerpetualChange = (
    checked: boolean,
    setFieldValue: (field: string, value: any) => void,
    values: FormValues,
  ) => {
    if (checked) {
      const end_date = getPerpetualSipEndDate(
        values?.modeOfRegistration as string,
        startDate as Date,
      );
      const selectedInstallments = calculateDifference(
        startDate as Date,
        end_date,
        frequencyType as string,
      );
      setFieldValue('endDate', format(new Date(end_date), 'eee do MMMM yyyy'));
      setFieldValue('installments', selectedInstallments);
      setInstallments(selectedInstallments as number);
    } else {
      // clearing the values
      setFieldValue('sipDay', '');
      setFieldValue('installments', '');
      setFieldValue('startDate', null);
      setFieldValue('endDate', null);
    }
    setIsPerpetual(checked);
  };
  const handleValidation = async (values: Record<string, any>) => {
    let errors: Record<string, any> = {};
    const minTransactionAmount =
      (data?.transactionType === 'APL'
        ? (schemaData as SchemaData)?.minTransactionAmount
        : minAmountAllowed) || 0;
    if (Number(values.amount) < minTransactionAmount && values?.modeType !== 'SIP') {
      // Lumpsum amount
      errors.amount = `Min Lumpsum amount allowed ${minTransactionAmount}`;
    }
    if (minInstallments > values.installments) {
      errors.installments = `Min installments allowed ${minInstallments}`;
    }
    if (
      Number(values.installmentAmount) < minTransactionAmount &&
      values?.modeType !== 'Lumpsum'
    ) {
      errors.installmentAmount = `Min installment amount allowed ${minTransactionAmount}`;
    }
    return errors;
  };

  useEffect(() => {
    if (startDate && endDate) {
      formikRef.current?.setFieldValue(
        'startDate',
        format(new Date(startDate), 'eee do MMMM yyyy'),
      );
      formikRef.current?.setFieldValue(
        'endDate',
        format(new Date(endDate), 'eee do MMMM yyyy'),
      );
    }
  }, [startDate, endDate]);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formikRef}
      enableReinitialize
      validate={async values => handleValidation(values)}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Grid
              container
              spacing={2}
              sx={{ mb: 2 }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='arnCode'
                  label='ARN Code'
                  disabled
                  value={data?.arnCode}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='subArnCode'
                  label='Sub ARN Code'
                  required={false}
                  disabled
                  value={data?.subArn}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='subBrokerCode'
                  label='Sub Broker Code'
                  required={false}
                  disabled
                  value={data?.subArnCode}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <EuinsFields
                  name='euin'
                  allEUINs={allEUINs}
                  initialValue={data?.euin !== '' ? 'yes' : 'no'}
                  disabled
                  defaultValue={data?.euin || ''}
                />
              </Grid>
            </Grid>
            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              sx={{
                border: '1px dashed',
                borderColor: 'text.borderColorDark',
                my: 3,
              }}
            />
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.inputLabelText',
                    mb: 1,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Selected AMC
                </Typography>
                <FormTextInput
                  name='selectedAmc'
                  label='selectedAmc'
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.inputLabelText',
                    mb: 2,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Scheme Type
                </Typography>
                <FormStyledRadioButton
                  name='schemeType'
                  options={[
                    { label: 'Existing', value: 'existing' },
                    { label: 'New', value: 'new' },
                  ]}
                  handleChange={() => {
                    isSchemeChanged.current = true;
                    clearAllValues(setFieldValue);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.inputLabelText',
                    mb: 1,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Folio
                </Typography>

                <FormSelectInput
                  name='folioNumber'
                  label='folioNumber' // folios for data fund.
                  options={
                    values?.schemeType === 'existing'
                      ? existingAndNewFolios.existingFolios
                      : existingAndNewFolios?.newFolios
                  }
                  defaultValue={data?.folio}
                />
              </Grid>
              {values.schemeType === 'new' && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography
                    sx={{
                      color: 'text.inputLabelText',
                      mb: 1,
                      fontWeight: 500,
                      fontSize: { xs: '14px', xl: '16px' },
                    }}
                  >
                    Category
                  </Typography>

                  <FormSelectInput
                    name='category'
                    label='Category'
                    options={schemesByFund.categories.map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(e: any) =>
                      setSchemesByFund({
                        ...schemesByFund,
                        selectedCategory: e.target.value,
                      })
                    }
                  />
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={6}
                sx={{ mb: 1.25 }}
              >
                <Typography
                  sx={{
                    color: 'text.inputLabelText',
                    mb: 1,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Scheme
                </Typography>

                <FormSelectInput
                  name='schemeName'
                  label='Select Scheme'
                  options={
                    values?.category && values?.schemeType === 'new'
                      ? schemesByFund.allSchemes
                          .filter((item: any) => item.category === values?.category)
                          .map(data => ({
                            label: `${data.schemeDesc} - ${data.planDesc}`,
                            value: `${data.schemeDesc} - ${data.planDesc}`,
                          }))
                      : getInvestment(data?.fund, (values as any)?.folioNumber).map(
                          data => ({
                            value: data.scheme.toString(),
                            label: data.schemeName.toString(),
                          }),
                        )
                  }
                  defaultValue={values.schemeName}
                  onChange={(e: any) => {
                    let selectedScheme: any = {};

                    if (values?.category && values?.schemeType === 'new') {
                      selectedScheme = schemesByFund.allSchemes.find(
                        (item: any) =>
                          `${item.schemeDesc} - ${item.planDesc}` === e.target.value,
                      );
                    } else {
                      selectedScheme = getInvestment(
                        data?.fund,
                        (values as any)?.folioNumber,
                      ).find(item => item.scheme === e.target.value);
                    }
                    setSelectedScheme(selectedScheme);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
            >
              {data?.transactionType !== 'APL' && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormTextInput
                      name='modeOfRegistration'
                      label={'Mode of Registration'}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormSelectInput
                      name='frequency'
                      label='Frequency'
                      defaultValue={''}
                      options={
                        frequencyModes?.map((mode: string) => ({
                          label: mode,
                          value: mode,
                        })) || []
                      }
                      onChange={(e: any) => {
                        setFrequencyType(e.target.value);
                        setInstallments(0);
                        setFieldValue('installments', '');
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormSelectInput
                      name='sipDay'
                      defaultValue={values?.sipDay}
                      label='SIP Day'
                      options={aplDates.map(date => ({ label: date, value: date }))}
                      onChange={e => {
                        setSipDay(e.target.value as string);
                        //TODO: Need to change the logic based on the actual api response.
                        const actualTime = new Date(endDate as Date).getTime();
                        const end_date = getPerpetualSipEndDate(
                          values.modeOfRegistration,
                          startDate as Date,
                        );
                        const requiredTime = new Date(end_date).getTime();
                        if (
                          values.modeOfRegistration === 'ISIP' &&
                          actualTime > requiredTime
                        ) {
                          showToast(
                            'Number of installments needs to reduced so that end date is not greater than 12/2099',
                            'error',
                          );
                          setFieldValue('installments', '');
                        } else if (
                          values.modeOfRegistration === 'kotm' &&
                          actualTime > requiredTime
                        ) {
                          showToast(
                            `SIP End date cannot exceed ${dateToDDMMYYY(end_date)}`,
                            'error',
                          );
                          setFieldValue('installments', '');
                          setFieldValue('sipDay', '');
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='installments'
                      label='No. of Installments'
                      disabled={values.perpetual}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('startDate', null);
                        setFieldValue('endDate', null);
                        setInstallments(Number(event.target.value));
                        const end_date = getPerpetualSipEndDate(
                          values.modeOfRegistration,
                          startDate as Date,
                        );
                        const maxInstallments = maxInstallmentBasedOnFrequency(
                          values.frequency,
                          values.modeOfRegistration,
                        );
                        if (
                          values.modeOfRegistration === 'ISIP' &&
                          Number(event.target.value) > maxInstallments
                        ) {
                          showToast(
                            'Number of installments needs to reduced so that end date is not greater than 12/2099',
                            'error',
                          );
                          setFieldValue('installments', '');
                        } else if (
                          values.modeOfRegistration === 'kotm' &&
                          Number(event.target.value) > maxInstallments
                        ) {
                          showToast(
                            `SIP End date cannot exceed ${dateToDDMMYYY(end_date)}`,
                            'error',
                          );
                          setFieldValue('installments', '');
                        }
                      }}
                    />
                    {values?.frequency && (
                      <Typography>
                        Minimum Number of installments:
                        {minInstallments}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormDateInput
                      name='startDate'
                      placeholder='SIP Start Date'
                      label='SIP Start Date'
                      value={areAllTheValuesEmpty(values) ? '' : values.startDate}
                      disabled={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormDateInput
                      name='endDate'
                      placeholder='SIP End Date'
                      label='SIP End Date'
                      disabled={true}
                      value={areAllTheValuesEmpty(values) ? '' : values.endDate}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormTextInput
                      name='installmentAmount'
                      placeholder='Installment Amount'
                      label='Enter Installment Amount'
                      value={values.installmentAmount}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        if (e.target.value.startsWith('0')) {
                          e.target.value = e.target.value.replace(/^0+/, '');
                        }
                        if (e.target.value.length > 18) {
                          e.target.value = e.target.value.slice(0, 18);
                        }
                        setFieldValue('installmentAmount', e.target.value);
                      }}
                    />
                  </Grid>
                </>
              )}

              {data?.transactionType !== 'ISIP' && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <FormTextInput
                    name='amount'
                    placeholder='Lumpsum Amount'
                    label='Lumpsum Amount'
                    value={values.amount}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      if (e.target.value.startsWith('0')) {
                        e.target.value = e.target.value.replace(/^0+/, '');
                      }
                      if (e.target.value.length > 18) {
                        e.target.value = e.target.value.slice(0, 18);
                      }
                      setFieldValue('amount', e.target.value);
                    }}
                  />
                </Grid>
              )}
              {values?.frequency &&
              values?.sipDay &&
              values.startDate &&
              values?.endDate ? (
                <Grid
                  item
                  xs={12}
                >
                  <FormCheckbox
                    name='perpetual'
                    label='Perpetual SIP'
                    margin='dense'
                    onChange={e =>
                      handlePerpetualChange(e.target.checked, setFieldValue, values)
                    }
                  />
                </Grid>
              ) : null}
            </Grid>

            {isLoading && <CustomLoader display={isLoading} />}

            <SubmitCartButtons
              onAddToCartClick={handleCancelModifyingCart}
              firstButtonTitle={'Got to cart'}
              secondButtonTitle={'Update'}
              disableCondition={!data}
            />
            {openDialogBox && (
              <CustomDialog
                isVisible={true}
                firstButtonHandler={() => navigate('../')}
                firstButtonTitle='OK'
                handleClose={() => {}}
              >
                <Box
                  sx={{
                    'textAlign': 'center',
                    'pt': 2,
                    '& .MuiTypography-root': {
                      'fontSize': '16px',
                      'color': 'text.grayishBlue',
                      'my': 2,
                      '& >*': {
                        fontWeight: '600',
                      },
                    },
                  }}
                >
                  <img
                    src={VerifiedIcon}
                    alt='verified-icon'
                  />

                  <Typography variant='body1'>
                    {'Details Updated Successfully!'}
                  </Typography>
                </Box>
              </CustomDialog>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
export default EditTransactionAPL;
