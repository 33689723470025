import { useNavigate } from 'react-router-dom';

import { SchemeFromAndTo } from '../common/SchemeFromAndTo';

const Stp = () => {
  const navigate = useNavigate();

  function handleContinueButton(data: { fromData: any; toData: any; extraData?: any }) {
    const { fromData, toData, extraData } = data;
    navigate('investment-details', {
      state: { stpOutData: fromData, stpInData: toData, extraData },
    });
  }
  return (
    <SchemeFromAndTo
      type='STP'
      onSubmit={handleContinueButton}
    />
  );
};

export default Stp;
