import { addMonths, format, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';

export const maskedValue = (value: string) => {
  const last4Digit = value.slice(-4);
  const maskedNumber = last4Digit.padStart(value.length, '*');
  return maskedNumber;
};

export const formatDateTime = (date?: string | Date | null) => {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleDateString('en-In', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};
export const extractDateOnly = (date?: string | Date | null) => {
  if (!date) {
    return '';
  }
  const dateObj = new Date(date);
  const adjustedDate = new Date(
    dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000,
  );
  const formattedDate = adjustedDate.toISOString().split('T')[0];
  return formattedDate;
};
export const calculateWithdrawalDates = (
  day: number,
  numOfWithdrawals: number,
  withdrawalFrequency: string,
) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  let nextDate = new Date(currentYear, currentMonth, currentDay);
  while (nextDate.getDate() !== day) {
    nextDate.setDate(nextDate.getDate() + 1);
  }

  const withdrawalDates = [];
  switch (withdrawalFrequency) {
    case 'Weekly':
      for (let i = 0; i < numOfWithdrawals; i++) {
        const newDate = new Date(nextDate);
        newDate.setDate(nextDate.getDate() + i * 7);
        withdrawalDates.push(newDate);
      }
      break;

    case 'Monthly':
      for (let i = 0; i < numOfWithdrawals; i++) {
        const newDate = new Date(nextDate.getFullYear(), nextDate.getMonth() + i, day);
        withdrawalDates.push(newDate);
      }
      break;

    case 'Quarterly':
      for (let i = 0; i < numOfWithdrawals; i++) {
        const newDate = new Date(
          nextDate.getFullYear(),
          nextDate.getMonth() + i * 3,
          day,
        );
        withdrawalDates.push(newDate);
      }
      break;

    default:
      break;
  }
  return withdrawalDates;
};
export const getDayFromDate = (fullDate?: string | Date | null) => {
  if (!fullDate) {
    return '';
  }
  const date = new Date(fullDate);
  return date.getDate() + (date.getHours() >= 18 ? 1 : 0);
};

export const getDateFromString = (dateString: string): Date | null => {
  try {
    if (!dateString) {
      throw new Error('Date string is empty');
    }
    const splitDate: string[] = dateString.split('/');
    const date = new Date(
      Number(splitDate[2]),
      Number(splitDate[1]) - 1,
      Number(splitDate[0]),
    );

    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }

    return date;
  } catch (e) {
    // TODO: handle error
    return null; // Return null instead of an empty string
  }
};
export type SIPDate = {
  label: string;
  value: Date;
};
/**
 * Function to calculate the SIP dates between the start date and end date.
 *
 * This function generates a list of dates (SIP dates) that fall on the same day of each month
 *
 * @param startDate - The start date of the SIP period
 * @param endDate - The end date of the SIP period
 * @returns An array of objects, each containing a label (formatted date) and a value (Date object)
 */
export function calculateSIPDates(startDate: Date, endDate: Date): SIPDate[] {
  const today = startOfDay(new Date());
  const sipDates = [];
  let currentDate = startDate;

  // Iterate over each month until endDate
  while (isBefore(currentDate, endDate) || isSameDay(currentDate, endDate)) {
    if (
      (isAfter(currentDate, startDate) || isSameDay(currentDate, startDate)) &&
      (isAfter(currentDate, today) || isSameDay(currentDate, today))
    ) {
      sipDates.push({
        label: format(currentDate, 'dd/MM/yyyy'),
        value: currentDate,
      });
    }
    // Move to the next month
    currentDate = addMonths(currentDate, 1);
  }
  return sipDates;
}
