import { Box, CircularProgress, Fade } from '@mui/material';

interface CustomLoaderProps {
  display: boolean;
}

const CustomLoader: React.FC<CustomLoaderProps> = ({ display }) => {
  return (
    <Fade in={display}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          //   backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: 999,
          justifyContent: 'center',
        }}
      >
        <CircularProgress
          size={50}
          disableShrink
        />
      </Box>
    </Fade>
  );
};

export default CustomLoader;
