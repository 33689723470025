import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Tab, Theme, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as AddIcon } from '../../assets/add-icon-rounded.svg';
import MutualFunds from '../../assets/nodataDashboard/kc-mf.png';
import { ReactComponent as KnowledgeNPS } from '../../assets/nodataDashboard/kc-nps.svg';
import KnowledgeVideos from '../../assets/nodataDashboard/kc-videos.png';
import { ReactComponent as Earnings } from '../../assets/OnboardingDashboard/earnings.svg';
import DashboardBG from '../../assets/OnboardingDashboard/onboarding-dashboard.png';
import { useGetAssetsQuery } from '../auth/signup/slices';
import { startLoading, stopLoading } from '../common/Loader/loaderSlice';
// import { startLoading, stopLoading } from '../common/Loader/loaderSlice';
import { useRootContext } from '../data/root.context';
import { LoginResponse, updateAssets } from '../features/login/loginSlice';
import { StepperNextButton } from '../onboarding/styles/styledComponents';
import { MF } from './MF';
import { NPS } from './NPS';
import { useGetMfAumDetailsQuery, useLazyGetSnapshotsQuery } from './slice';
export interface SnapshotData {
  [key: string]: number | string | null | undefined;
}

export function DashBoard() {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState('1');
  const { name } = useSelector((state: { login: LoginResponse }) => state.login);
  const [snapshotsData, setSnapshotData] = useState<SnapshotData | null>(null);
  const {
    data: allAumDetails,
    isLoading: isAumLoading,
    isFetching: isAumFetching,
    isSuccess: isAumSuccess,
  } = useGetMfAumDetailsQuery([]);
  const [callGetSnapshots, getSnapshotsResponse] = useLazyGetSnapshotsQuery();

  const { data, error } = useGetAssetsQuery('');
  const { showToast } = useRootContext();
  const dispatch = useDispatch();
  /*TODO: hardcode data*/
  const KC_DATA = [
    {
      img: KnowledgeVideos,
      title: 'Videos',
      description:
        'Know more about mutual funds in detail. Mutual Fund Basics will inform you about the basics of investing in mutual funds.',
    },
    {
      img: <KnowledgeNPS />,
      title: 'NPS',
      description:
        'Know more about mutual funds in detail. Mutual Fund Basics will inform you about the basics of investing in mutual funds.',
    },
    {
      img: MutualFunds,
      title: 'Mutual Fund Basics',
      description:
        'Know more about mutual funds in detail. Mutual Fund Basics will inform you about the basics of investing in mutual funds.',
    },
  ];
  React.useEffect(() => {
    if (data) {
      dispatch(updateAssets(data));
    } else if (error) {
      const errorMessage =
        (error as { message: string }).message || 'Unknown error occurred.';
      showToast(errorMessage, 'error');
    }
  }, [data, dispatch, error]);

  useEffect(() => {
    // ADD AUM API loader

    const snapshotsResponseData =
      (getSnapshotsResponse.status === 'fulfilled' ||
        getSnapshotsResponse.status === 'rejected') &&
      (!getSnapshotsResponse.isLoading || !getSnapshotsResponse.isFetching);

    if (snapshotsResponseData) {
      dispatch(stopLoading());
    }

    if (getSnapshotsResponse.isSuccess) {
      setSnapshotData(getSnapshotsResponse.data);
    }
  }, [isAumSuccess, isAumFetching, getSnapshotsResponse]);

  useEffect(() => {
    dispatch(startLoading());
    callGetSnapshots('');
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  return (
    <>
      <Typography
        variant='h4'
        sx={{ mb: 2 }}
      >
        Dashboard
      </Typography>
      <Box
        sx={(theme: Theme) => ({
          background: {
            // xs: `url(${DashboardBG})`,
            xs: `url(${DashboardBG}), ${theme.palette.background.primaryLinearGradient3}`,
          },
          borderRadius: '15px',
          backgroundRepeat: 'no-repeat',
          padding: { xs: '30px 30px', sm: '35px 35px' },
          backgroundSize: { xs: 'cover' },
          mb: 4,
          backgroundPosition: { xs: 'left', sm: 'unset' },
        })}
      >
        <Typography
          sx={{
            color: 'common.white',
            fontSize: { xs: '20px', lg: '24px', xl: '26px' },
            fontWeight: 700,
            mb: 1.5,
          }}
        >
          Hi, {name} {'\u{1F44B}'}
        </Typography>
        <Typography
          sx={{
            color: 'common.white',
            maxWidth: '600px',
            fontSize: { xs: '14px', sm: '16px' },
            mb: { xs: 2, md: 4 },
            lineHeight: '32px',
          }}
        >
          Discover your financial narrative with &apos;Earnings&apos;, where numbers
          become the strokes of accomplishment.
        </Typography>
        <Button
          sx={{
            'borderRadius': '49px',
            // maxWidth: 'fit-content',
            'padding': { xs: '8px 20px', sm: '12px 20px' },
            'minWidth': '175px',
            'justifyContent': 'flex-start',
            'fontSize': { xs: '14px' },
            'fontWeight': 600,
            'color': 'primary.main',
            '&,&:hover': {
              bgcolor: 'common.white',
            },
          }}
          endIcon={<Earnings />}
          onClick={() => navigate('./view-my-earnings')}
        >
          View My Earnings
        </Button>
      </Box>

      <TabContext value={tabValue}>
        <Box
          sx={{
            'width': '100%',
            'borderBottom': '1px solid',
            'borderColor': 'text.borderColorLight',
            'mb': 4,
            '& .MuiTabs-root': {
              '& .MuiTabs-flexContainer': {
                'pl': '0px',
                'columnGap': '25px',
                '& .MuiButtonBase-root.MuiTab-root': {
                  'pb': '20px',
                  'fontSize': { xl: '18px', lg: '16px', xs: '14px' },
                  'fontWeight': 500,
                  '&.Mui-disabled': {
                    color: 'rgba(108, 115, 127, 0.50)',
                  },
                },
              },
              '& .MuiTabs-indicator': {
                height: '5px',
                borderRadius: '100px 100px 0px 0px',
              },
            },
            '& .MuiTabPanel-root': {
              padding: '50px 50px',
            },
          }}
        >
          <TabList
            onChange={handleTabChange}
            aria-label='lab API tabs example'
          >
            <Tab
              label='Mutual Funds'
              value='1'
            />
            <Tab
              label='NPS'
              value='2'
            />
          </TabList>
        </Box>
        <TabPanel
          sx={{ p: 0, mb: 5 }}
          value='1'
        >
          <MF
            snapshotsData={snapshotsData}
            allAumDetails={allAumDetails}
          />
        </TabPanel>
        <TabPanel
          sx={{ p: 0 }}
          value='2'
        >
          <NPS />
        </TabPanel>
      </TabContext>
      <StepperNextButton
        sx={{
          borderRadius: '50px',
          position: 'fixed',
          bottom: '30px',
          right: '30px',
          zIndex: '10',
          minWidth: '150px',
        }}
        startIcon={<AddIcon />}
        onClick={() => navigate('../nfo')}
      >
        Create NFO
      </StepperNextButton>
    </>
  );
}
