import { Box, Grid, Typography } from '@mui/material';

import FormDateInput from '../../../forms/FormDateInput';
import { FormSelectInput } from '../../../forms/FormSelect';
import FormTextInput from '../../../forms/FormTextInput';
import { dateToDDMMYYYY } from '../../mfs/newInvestorOnboarding/utils';
import {
  getAddressType,
  getIncomeValue,
  getOccupation,
  getSourceOfWealth,
} from './utils';

export default function KycFields({ values }: any) {
  return (
    <>
      <Box
        sx={{
          borderTop: '0.5px dashed',
          borderColor: 'text.borderColorLight',
          width: '100%',
          my: 4,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'text.sliderColor',
            borderRadius: '7px',
            padding: '10px 15px',
            mt: 3,
          }}
        >
          <Typography
            variant='subtitle1'
            sx={{ fontWeight: 700 }}
          >
            KYC Details
          </Typography>
        </Box>
        <Grid
          container
          sx={{ my: 1 }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormTextInput
              name='fatherName'
              label='Father Name'
              typeOfInput='singleSpace'
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormTextInput
              name='spouseName'
              label='Spouse Name'
              typeOfInput='singleSpace'
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ my: 1 }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormSelectInput
              name='pepFlag'
              label='PEP Status'
              defaultValue={''}
              options={[
                {
                  label: 'Yes',
                  value: 'Y',
                },
                {
                  label: 'No',
                  value: 'N',
                },
              ]}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormSelectInput
              name='annualIncomeSlab'
              label='Annual Income'
              defaultValue={''}
              options={Object.keys(getIncomeValue).map(key => ({
                value: key,
                label: getIncomeValue[key],
              }))}
              required
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ my: 1 }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormSelectInput
              name='occupation'
              label='Occupation'
              defaultValue={''}
              options={Object.keys(getOccupation).map(key => ({
                value: key,
                label: getOccupation[key],
              }))}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormSelectInput
              name='addressType'
              label='Address Type'
              defaultValue={''}
              options={Object.keys(getAddressType).map(key => ({
                value: key,
                label: getAddressType[key],
              }))}
              required
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ my: 1 }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormSelectInput
              name='sourceOfWealth'
              label='Source of wealth'
              defaultValue={''}
              options={Object.keys(getSourceOfWealth).map(key => ({
                value: key,
                label: getSourceOfWealth[key],
              }))}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormTextInput
              name='netWorth'
              label='Net Worth'
              typeOfInput='number'
              onInput={(e: any) => {
                if (e.target.value.length > 18) {
                  e.target.value = e.target.value.slice(0, 18);
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ my: 1 }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormDateInput
              name='netWorthDate'
              placeholder='Net Worth Date'
              label='Net Worth Date'
              disableFuture
              required
              value={
                values.netWorthDate !== null
                  ? dateToDDMMYYYY(values.netWorthDate)
                  : dateToDDMMYYYY(new Date())
              }
              defaultValue={
                values.netWorthDate !== null
                  ? dateToDDMMYYYY(values.netWorthDate)
                  : dateToDDMMYYYY(new Date())
              }
              disabled
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
