import apiSlice from '../../service/api';

const onBoardingSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getInvestors: builder.query({
      query: () => ({
        url: '/user/investors',
        method: 'GET',
      }),
    }),
    getInvestorTransactions: builder.query({
      query: () => ({
        url: '/user/investors/get-available-transactions',
        method: 'GET',
      }),
    }),
    getIndividualSchemeDetails: builder.mutation({
      query: payload => ({
        url: '/user/transactions/get-investor-scheme-details',
        method: 'POST',
        body: payload,
      }),
    }),
    redeemption: builder.mutation({
      query: payload => ({
        url: '/user/transactions/redemption/direct',
        method: 'POST',
        body: payload,
      }),
    }),
    addReedemedSchemeInCart: builder.mutation({
      query: payload => ({
        url: '/user/transactions/redemption/add-to-cart',
        method: 'POST',
        body: payload,
      }),
    }),
    listOfSchemes: builder.mutation({
      query: payload => ({
        url: '/user/transactions/get-investor-scheme-list',
        method: 'POST',
        body: payload,
      }),
    }),
    getInvestorData: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/getInvestorDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    addItemInSwp: builder.mutation({
      query: payload => ({
        url: '/user/transactions/swp/direct',
        method: 'POST',
        body: payload,
      }),
    }),
    createUpdateEmailRequest: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/createUpdateEmailRequest',
        method: 'POST',
        body: payload,
      }),
    }),
    createUpdateMobileRequest: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/createUpdateMobileRequest',
        method: 'POST',
        body: payload,
      }),
    }),
    saveNominees: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/saveNominee',
        method: 'POST',
        body: payload,
      }),
    }),
    getCartItems: builder.mutation({
      query: payload => ({
        url: '/user/transactions/get-list-dit-cart',
        method: 'POST',
        body: payload,
      }),
    }),
    removeItemsFromCart: builder.mutation({
      query: payload => ({
        url: '/user/transactions/remove-cart-item',
        method: 'POST',
        body: payload,
      }),
    }),
    modifyReedemedItems: builder.mutation({
      query: payload => ({
        url: '/user/transactions/redemption/modify-cart',
        method: 'POST',
        body: payload,
      }),
    }),
    addSwpItemsInCart: builder.mutation({
      query: payload => ({
        url: '/user/transactions/swp/add-to-cart',
        method: 'POST',
        body: payload,
      }),
    }),
    getSchemeDetails: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/getSchemeDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    createUpdateIdcwOptionRequest: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/createUpdateIdcwOptionRequest',
        method: 'POST',
        body: payload,
      }),
    }),
    modifySwpCartItems: builder.mutation({
      query: payload => ({
        url: '/user/transactions/swp/modify-cart',
        method: 'POST',
        body: payload,
      }),
    }),
    getIfscDetails: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/getIfscDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    createUpdateIfscRequest: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/createUpdateIfscRequest',
        method: 'POST',
        body: payload,
      }),
    }),
    createBankDetailsChangeRequest: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/createBankDetailsChangeRequest',
        method: 'POST',
        body: payload,
      }),
    }),
    createMultiBankRequest: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/createMultiBankRequest',
        method: 'POST',
        body: payload,
      }),
    }),
    getSchemaDetails: builder.mutation({
      query: payload => ({
        url: '/user/transactions/get-schema-details',
        method: 'POST',
        body: payload,
      }),
    }),
    getSchemeInstallmentCycle: builder.mutation({
      query: payload => ({
        url: '/user/transactions/get-schema-installment-cycle',
        method: 'POST',
        body: payload,
      }),
    }),
    // /user/transactions/get-schema-installment-cycle
    getFatcaDetails: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/getFatcaDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    createUpdateFatcaRequest: builder.mutation({
      query: payload => ({
        url: '/user/transactions/nct/createUpdateFatcaRequest',
        method: 'POST',
        body: payload,
      }),
    }),
    addItemToCart: builder.mutation({
      query: payload => ({
        url: '/user/transactions/add-cart-item',
        method: 'POST',
        body: payload,
      }),
    }),
    modifyItemInCart: builder.mutation({
      query: payload => ({
        url: '/user/transactions/modify-cart-item',
        method: 'POST',
        body: payload,
      }),
    }),
    getAgentDetails: builder.mutation({
      query: payload => ({
        url: '/user/nps/contribution/subsequentContribution/agentDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    portfolio: builder.mutation({
      query: payload => ({
        url: '/user/nps/investor/subscriberJourney/portfolio',
        method: 'POST',
        body: payload,
      }),
    }),
    makeDirectAdditionalPurchase: builder.mutation({
      query: payload => ({
        url: '/user/transactions/additional-purchase/direct',
        method: 'POST',
        body: payload,
      }),
    }),
    makeSIPAdditionalPurchase: builder.mutation({
      query: payload => ({
        url: '/user/transactions/additional-purchase/sip',
        method: 'POST',
        body: payload,
      }),
    }),
    makeSipAndLumpsumAdditionalPurchase: builder.mutation({
      query: payload => ({
        url: '/user/transactions/additional-purchase/direct-sip',
        method: 'POST',
        body: payload,
      }),
    }),
    addItemToSwitch: builder.mutation({
      query: payload => ({
        url: '/user/transactions/switch/direct',
        method: 'POST',
        body: payload,
      }),
    }),
    getPaymentModesByFund: builder.mutation({
      query: payload => ({
        url: '/user/transactions/get-available-payment-modes',
        method: 'POST',
        body: payload,
      }),
    }),
    getInvestorMandates: builder.mutation({
      query: payload => ({
        url: '/user/investors/get-investor-mandates',
        method: 'POST',
        body: payload,
      }),
    }),
    getInvestorSystematicPlanData: builder.mutation({
      query: payload => ({
        url: '/user/transactions/pause-cancel/get-investor-systematic-plan-data',
        method: 'POST',
        body: payload,
      }),
    }),
    // TODO: Get Query to be removed from other components. (No longer a get query)
    getEUINS: builder.query({
      query: () => ({
        url: '/user/get-euins',
        method: 'POST',
      }),
    }),
    getEUINSBySubArn: builder.mutation({
      query: payload => ({
        url: '/user/get-euins',
        method: 'POST',
        body: payload,
      }),
    }),
    stpTransaction: builder.mutation({
      query: payload => ({
        url: '/user/transactions/stp/direct',
        method: 'POST',
        body: payload,
      }),
    }),
    pauseSip: builder.mutation({
      query: payload => ({
        url: '/user/transactions/pause-cancel/sip-pause',
        method: 'POST',
        body: payload,
      }),
    }),
    cancelSip: builder.mutation({
      query: payload => ({
        url: '/user/transactions/pause-cancel/sip-cancel',
        method: 'POST',
        body: payload,
      }),
    }),
    cancelStp: builder.mutation({
      query: payload => ({
        url: '/user/transactions/pause-cancel/stp-cancel',
        method: 'POST',
        body: payload,
      }),
    }),
    cancelSwp: builder.mutation({
      query: payload => ({
        url: '/user/transactions/pause-cancel/swp-cancel',
        method: 'POST',
        body: payload,
      }),
    }),
    getPauseSipDates: builder.mutation({
      query: payload => ({
        url: '/user/transactions/pause-cancel/get-sip-pause-dates',
        method: 'POST',
        body: payload,
      }),
    }),
    getCancelSipDates: builder.mutation({
      query: payload => ({
        url: '/user/transactions/pause-cancel/get-sip-pause-end-date',
        method: 'POST',
        body: payload,
      }),
    }),

    getInvestorDetails: builder.mutation({
      query: payload => ({
        url: '/user/investors/get-investor-details',
        method: 'POST',
        body: payload,
      }),
    }),
    createEnachMandate: builder.mutation({
      query: payload => ({
        url: '/user/transactions/enach/create-enach-mandate',
        method: 'POST',
        body: payload,
      }),
    }),
    aadharPanSeeding: builder.mutation({
      query: payload => ({
        url: '/user/transactions/new-purchase/pan-aadhar-seeding',
        method: 'POST',
        body: payload,
      }),
    }),
    getEnachAotmDetails: builder.mutation({
      query: payload => ({
        url: '/user/transactions/get-enach-aotm-details',
        method: 'POST',
        body: payload,
      }),
    }),
    validateDates: builder.mutation({
      query: payload => ({
        url: '/user/transactions/validate-dates',
        method: 'POST',
        body: payload,
      }),
    }),
    getPossibleStartDates: builder.mutation({
      query: payload => ({
        url: '/user/transactions/get-schema-installment-cycle',
        method: 'POST',
        body: payload,
      }),
    }),
    getSipStartandEndDates: builder.mutation({
      query: payload => ({
        url: '/user/transactions/get-start-end-dates',
        method: 'POST',
        body: payload,
      }),
    }),
    enachEnabledAmcList: builder.query({
      query: () => ({
        url: '/user/transactions/enach/get-enach-enabled-funds',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetInvestorsQuery,
  useLazyGetInvestorTransactionsQuery,
  useGetIndividualSchemeDetailsMutation,
  useRedeemptionMutation,
  useListOfSchemesMutation,
  useGetInvestorSystematicPlanDataMutation,
  useGetInvestorDataMutation,
  useAddItemInSwpMutation,
  useAddReedemedSchemeInCartMutation,
  useCreateUpdateEmailRequestMutation,
  useCreateUpdateMobileRequestMutation,
  useSaveNomineesMutation,
  useGetCartItemsMutation,
  useRemoveItemsFromCartMutation,
  useAddSwpItemsInCartMutation,
  useGetSchemeDetailsMutation,
  useCreateUpdateIdcwOptionRequestMutation,
  useGetIfscDetailsMutation,
  useCreateUpdateIfscRequestMutation,
  useModifyReedemedItemsMutation,
  useModifySwpCartItemsMutation,
  useGetSchemaDetailsMutation,
  useGetSchemeInstallmentCycleMutation,
  useGetFatcaDetailsMutation,
  useCreateUpdateFatcaRequestMutation,
  useAddItemToCartMutation,
  useModifyItemInCartMutation,
  useGetAgentDetailsMutation,
  usePortfolioMutation,
  useCreateBankDetailsChangeRequestMutation,
  useCreateMultiBankRequestMutation,
  useMakeDirectAdditionalPurchaseMutation,
  useMakeSIPAdditionalPurchaseMutation,
  useMakeSipAndLumpsumAdditionalPurchaseMutation,
  useAddItemToSwitchMutation,
  useGetPaymentModesByFundMutation,
  useGetInvestorMandatesMutation,
  useGetEUINSQuery,
  useStpTransactionMutation,
  // All Pause and cancels
  useCancelSipMutation,
  useGetEUINSBySubArnMutation,
  useCancelStpMutation,
  useCancelSwpMutation,
  usePauseSipMutation,
  useGetInvestorDetailsMutation,
  useCreateEnachMandateMutation,
  useAadharPanSeedingMutation,
  useGetPauseSipDatesMutation,
  useGetCancelSipDatesMutation,
  // get AOTM details
  useGetEnachAotmDetailsMutation,
  useValidateDatesMutation,
  useGetPossibleStartDatesMutation,
  useGetSipStartandEndDatesMutation,
  useLazyEnachEnabledAmcListQuery,
} = onBoardingSlice;
