import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
} from 'date-fns';

import { freqType, getEnumValueFromString } from '../hooks/useSip';
export function capitalizeFirstLetter(str: string) {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}
export function calculateTatDate(frequencyCoolingPeriod: number) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const tatDate = new Date(today);
  tatDate.setDate(today.getDate() + frequencyCoolingPeriod);
  tatDate.setHours(0, 0, 0, 0);

  if (tatDate.getDay() === 0) {
    // If tatDate is Sunday
    tatDate.setDate(tatDate.getDate() + 1);
  } else if (tatDate.getDay() === 6) {
    // If tatDate is Saturday
    tatDate.setDate(tatDate.getDate() + 2);
  }

  return tatDate;
}
export const disableDatesOnStartDate = (
  date: Date,
  datesArr: string[],
  calculatedTatDate: Date,
) => {
  const currentDate = new Date(date);
  currentDate.setHours(0, 0, 0, 0); // Removing Timezone for better comparison
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const dayOfMonth = currentDate.getDate();
  const dayOfWeek = currentDate.getDay(); // for checking weekends.
  if (date < today) {
    return true;
  }
  if (
    currentDate > calculatedTatDate &&
    datesArr.includes(String(dayOfMonth)) &&
    !(dayOfWeek === 0 || dayOfWeek === 6) // for sunday & sat
  ) {
    return false;
  }
  return true;
};
export const disableDatesOnEndDate = (
  date: Date,
  frequency: string,
  startDate: Date,
  minInstallments: number,
) => {
  const currentDate = new Date(date);
  currentDate.setHours(0, 0, 0, 0);
  let today = startDate ? new Date(startDate) : new Date();
  today.setHours(0, 0, 0, 0);

  let nextEnabledDate = new Date(today);
  let enable = false;
  let count = 0;
  let freqString =
    frequency === 'Annually' || frequency === 'Annual' ? 'Annual' : frequency;
  const frequencyKey = getEnumValueFromString(freqString as string);
  while (nextEnabledDate <= currentDate) {
    count++;
    if (nextEnabledDate.getTime() === currentDate.getTime() && count >= minInstallments) {
      enable = true;
      break;
    }
    incrementDate[frequencyKey](nextEnabledDate);
  }

  return !enable;
};
export const incrementDate: { [key: string]: (date: Date) => void } = {
  W: (date: Date) => date.setDate(date.getDate() + 7),
  M: (date: Date) => date.setMonth(date.getMonth() + 1),
  Q: (date: Date) => date.setMonth(date.getMonth() + 3),
  H: (date: Date) => date.setMonth(date.getMonth() + 6),
  A: (date: Date) => date.setFullYear(date.getFullYear() + 1),
  F: (date: Date) => date.setDate(date.getDate() + 14),
  D: (date: Date) => date.setDate(date.getDate() + 1),
  DZ: (date: Date) => date.setDate(date.getDate() + 1),
};

export function calculateNextInstallmentDate(
  startDate: Date,
  frequencyType: string,
  minInstallments: number,
) {
  let currentDate = new Date(startDate);

  let freqString =
    frequencyType === 'Annually' || frequencyType === 'Annual' ? 'Annual' : frequencyType;
  const frequencyKey = getEnumValueFromString(freqString as string);

  for (let i = 1; i < minInstallments; i++) {
    incrementDate[frequencyKey](currentDate);
  }
  return currentDate;
}
export const calculateDifference = (
  startDate: Date,
  endDate: Date,
  frequency: string,
) => {
  switch (frequency) {
    case freqType.W:
      return differenceInWeeks(endDate, startDate) + 1;

    case freqType.M:
      return differenceInMonths(endDate, startDate) + 1;

    case freqType.D:
      return differenceInDays(endDate, startDate) + 1;

    case freqType.DZ:
      return differenceInDays(endDate, startDate) + 1;

    case freqType.Q:
      return Math.floor(differenceInMonths(endDate, startDate) / 3) + 1;

    case freqType.H:
      return Math.floor(differenceInMonths(endDate, startDate) / 6) + 1;

    case freqType.A:
      return differenceInYears(endDate, startDate) + 1;

    case freqType.F: // Fortnightly
      return Math.floor(differenceInDays(endDate, startDate) / 14) + 1;

    default:
      return null;
  }
};
export function formatCurrencyInINR(value: number): string {
  const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
}

export const formatToMMDDYYYYByUTC = (date: Date) => {
  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(utcDate.getUTCDate()).padStart(2, '0');
  const year = utcDate.getUTCFullYear();

  return `${month}/${day}/${year}`;
};
export const toUTCDate = (date: Date) => {
  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return utcDate;
};
export function calculatePercentage(value: number, total: number): string {
  if (total === 0) return '0.00';
  const percent = (value / total) * 100;
  return percent.toFixed(2);
}
