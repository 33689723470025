import { Box, Divider, Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { FieldSubHeader3, MainHeader } from '../../onboarding/styles/styledComponents';
import SubmitCartButtons from '../common/SubmitCartButtons';
import EuinsFields from '../euinsComponent';
import { useSchemeByfundMutation } from '../mfs/newInvestorOnboarding/slice';
import {
  useAddItemToCartMutation,
  useGetEUINSQuery,
  useListOfSchemesMutation,
  useModifyItemInCartMutation,
} from '../slice';

interface InitialValues {
  type: string;
  mode: string;
  value: number;
  euin: 'Y' | 'N';
  euinValue: string;
  subArn: string;
  subBroker: string;
  amc: string;
  category: string;
  folioNumber: number;
  switchOutScheme: string;
  isExistingScheme: 'E' | 'N';
}

interface InvestmentDetailsFormProps {
  data: any;
  isEditMode: boolean;
  handleCancelModifyingCart?: () => void;
}

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Switch type is required'),
  mode: Yup.string().required('Switch mode is required'),
  value: Yup.number()
    .required('Value is required')
    .typeError('Enter valid value')
    .positive('Switch Value should be a positive number'),
  euin: Yup.string().required().oneOf(['Y', 'N']),
});
const InvestmentDetailsForm: React.FC<InvestmentDetailsFormProps> = ({
  data,
  isEditMode,
  handleCancelModifyingCart,
}) => {
  const [addSwitchItemToCart] = useAddItemToCartMutation();
  const { showToast } = useRootContext();
  const [schemeList, setSchemeList] = useState<any>([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<InitialValues>>(null);
  const { data: allEUINs } = useGetEUINSQuery([]);
  const [listOfSchemes] = useListOfSchemesMutation();
  const [modifyItemInCart] = useModifyItemInCartMutation();
  const [selectedCategory, setSelectedCategory] = useState(data.selectedCategory || '');
  const [getSchemesByFund] = useSchemeByfundMutation();
  const [schemesByFund, setSchemesByFund] = useState<any>([]);
  const { arn, subArn, subbrokerCode, euin } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const [selectedSwitchOutScheme, setSelectedSwitchOutScheme] = useState<any>({});
  const [selectedSwitchInScheme, setSelectedSwitchInScheme] = useState<any>({});

  const isFirstChipCall = useRef<boolean>(false);
  const { pan } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );

  const availableUnits = isEditMode
    ? selectedSwitchOutScheme?.units
    : selectedSwitchOutScheme?.balanceUnits || 0;
  const handleAddToCartClick = async () => {
    try {
      dispatch(startLoading());
      const { values } = formikRef.current || {};
      const { mode, value, euinValue, type, subBroker, subArn, amc } = values || {};
      const isAmountMode = mode === 'A';
      const isUnitsMode = mode === 'U';
      const isPartial = type === 'P';

      const payload = {
        amount: isAmountMode ? Number(value) : 0,
        units: isUnitsMode ? Number(value) : 0,
        category: selectedSwitchOutScheme?.category,
        email: selectedSwitchOutScheme?.email,
        euinDeclaration: euinValue !== '' ? 'N' : 'Y',
        folio: selectedSwitchOutScheme?.folio,
        fund: selectedSwitchOutScheme?.fund,
        investorName: selectedSwitchOutScheme?.investorName,
        mobile: selectedSwitchOutScheme?.mobile,
        option: selectedSwitchOutScheme?.option,
        pan,
        partial: isPartial,
        plan: selectedSwitchOutScheme?.plan,
        scheme: selectedSwitchOutScheme?.scheme,
        subBroker: subBroker || '',
        switchInSchemeName: selectedSwitchInScheme?.switchInSchemeName,
        switchOutSchemeName: selectedSwitchOutScheme?.switchOutSchemeName,
        taxSaverFlag: String(selectedSwitchOutScheme?.taxSaverFlag),
        toOption: selectedSwitchInScheme.toOption,
        toPlan: selectedSwitchInScheme.toPlan,
        toScheme: selectedSwitchInScheme.toScheme,
        transactionType: 'SWITCH',
        fundName: amc,
        ...(data.extraData ? data.extraData : {}),
      };
      await addSwitchItemToCart(payload).unwrap();
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleSwitchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSwitchType = event.target.value;
    formikRef.current?.setFieldValue('type', selectedSwitchType);
    if (selectedSwitchType === 'F') {
      formikRef.current?.setFieldValue('mode', 'U');
      formikRef.current?.setFieldValue('value', availableUnits);
    } else formikRef.current?.setFieldValue('value', '');
  };

  async function handleSubmit(values: InitialValues) {
    try {
      const commonPayload = {
        amount: values.mode === 'A' ? Number(values.value) : 0,
        category: selectedSwitchOutScheme?.category,
        email: selectedSwitchOutScheme?.email,
        euinDeclaration: values.euinValue !== '' ? 'N' : 'Y',
        folio: String(selectedSwitchOutScheme?.folio),
        fund: selectedSwitchOutScheme?.fund,
        investorName: selectedSwitchOutScheme?.investorName,
        mobile: selectedSwitchOutScheme?.mobile,
        option: selectedSwitchOutScheme?.option,
        pan,
        partial: values.type === 'P',
        plan: selectedSwitchOutScheme?.plan,
        scheme: selectedSwitchOutScheme?.scheme,
        subBroker: values.subBroker || '',
        euin: values.euinValue || '',
        subArn: values.subArn || '',
        switchInSchemeName: selectedSwitchInScheme?.switchInSchemeName,
        switchOutSchemeName: selectedSwitchOutScheme?.switchOutSchemeName,
        taxSaverFlag: String(selectedSwitchOutScheme?.taxSaverFlag),
        toOption: selectedSwitchInScheme.toOption,
        toPlan: selectedSwitchInScheme.toPlan,
        toScheme: selectedSwitchInScheme.toScheme,
        units: values.mode === 'U' ? Number(values.value) : 0,
        isExistingScheme: values.isExistingScheme,
        transactionType: 'SWITCH',
        fundName: values.amc,
      };

      let payload = isEditMode
        ? {
            ...commonPayload,
            cartId: data.cartId,
            cartItemId: data.cartItemId,
          }
        : {
            ...commonPayload,
            ...(data.extraData ? data.extraData : {}),
          };

      if (isEditMode) {
        await modifyItemInCart(payload).unwrap();
        showToast(`Item updated successfully`, 'success');
        handleCancelModifyingCart?.();
      } else {
        navigate('/transaction-dashboard/confirmation', {
          state: { payload, isEditMode: false },
        });
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  }

  const handleGoToCart = () => {
    navigate('../../cart');
  };

  const handleAddAnotherTransaction = () => {
    navigate('../');
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listOfSchemes({ pan: pan.toUpperCase() }).unwrap();
        const filteredData = response.filter(
          (scheme: any) => scheme.switchAllow && scheme.fund === data.fund,
        );
        setSchemeList(filteredData);
      } catch (error: any) {
        showToast((error as { message: string }).message, 'error');
      }
    }
    if (isEditMode) fetchData();
  }, []);
  useEffect(() => {
    if (data) {
      setSelectedSwitchOutScheme({ ...data });
      setSelectedSwitchInScheme({ ...data });
    }
  }, [data]);

  useEffect(() => {
    async function fetchSchemesByFund() {
      try {
        const schemeByFundBody = {
          funds: [data.fund],
          ...(selectedCategory && { category: [selectedCategory] }),
          investorPan: '',
          start: 0,
          end: 20000, //TODO: add valid pagination
        };
        const resFund = await getSchemesByFund(schemeByFundBody).unwrap();
        setSchemesByFund(resFund);
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    }
    if (isEditMode) fetchSchemesByFund();
  }, [selectedCategory]);

  const getSwitchInSchemes = (isExistingScheme: 'E' | 'N', folioNumber: number) => {
    if (isExistingScheme === 'E') {
      return schemeList.filter(
        (scheme: any) =>
          scheme.folio === folioNumber &&
          `${scheme.scheme}_${scheme.schemePlan}_${scheme.schemeOption}` !==
            `${formikRef.current?.values.switchOutScheme}`,
      );
    } else {
      return schemesByFund;
    }
  };

  const handleValidation = async (values: any) => {
    const errors: Record<string, any> = {};

    if (values.mode === 'A' && Number(values.value) > data?.availableAmount) {
      errors.value = `Switch amount should not exceed ${data?.availableAmount}`;
    }

    if (values.mode === 'U' && Number(values.value) > data?.balanceUnits) {
      errors.value = `Switch Units should not exceed ${data?.balanceUnits}`;
    }

    if (
      values.value &&
      (isNaN(parseFloat(values.value)) ||
        Number(values.value) <= 0 ||
        /^0\d+/.test(values.value))
    ) {
      errors.value = `Enter valid value`;
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{
        type: 'P',
        mode: 'amount' in data && data.amount !== 0 ? 'A' : 'U',
        value: isEditMode ? data.amount || data.units : '',
        euin: 'Y',
        euinValue: euin,
        subArn: subArn,
        subBroker: subbrokerCode,
        amc: data?.fundName,
        category: data.selectedCategory,
        folioNumber: data.folio,
        switchOutScheme: `${data.scheme}_${data.plan}_${data.option}`,
        isExistingScheme: data.isExistingScheme,
        switchInScheme: `${data.toScheme}_${data.toPlan}_${data.toOption}`,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formikRef}
      validate={async values => handleValidation(values)}
    >
      {({ values, setValues, setFieldValue }) => {
        return (
          <Form>
            {isEditMode && (
              <Grid
                container
                spacing={2}
                sx={{
                  'paddingBottom': '25px',
                  '& .MuiButtonBase-root': {
                    '&.MuiRadio-root': {
                      '&.Mui-checked': {
                        color: 'primary.main',
                      },
                    },
                  },
                }}
              >
                <Grid
                  item
                  sm={6}
                >
                  <FormTextInput
                    name='amc'
                    label='AMC'
                    defaultValue={values.amc}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='folioNumber'
                    label='folioNumber'
                    options={schemeList.map((scheme: any) => ({
                      label: scheme.folio,
                      value: scheme.folio,
                    }))}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='switchOutScheme'
                    label='Switch Out Scheme'
                    options={schemeList
                      .filter((scheme: any) => scheme.folio === values.folioNumber)
                      .map((scheme: any) => ({
                        label: `${scheme.schemeName}-${scheme.planDesc}`,
                        value: `${scheme.scheme}_${scheme.plan}_${scheme.option}`,
                      }))}
                    onChange={(e: any) => {
                      const [scheme, plan, option] = e.target.value.split('_');

                      const filteredResult = schemeList.filter(
                        (schemes: any) =>
                          schemes.scheme === scheme &&
                          schemes.plan === plan &&
                          schemes.option === option,
                      );
                      setSelectedSwitchOutScheme(filteredResult?.[0]);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  mb={2}
                >
                  <Box sx={{ bgcolor: 'text.boxColor', padding: '20px 15px' }}>
                    <Grid
                      container
                      spacing={2}
                      alignItems={'center'}
                    >
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Balance Units:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {selectedSwitchOutScheme?.units || 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Current Value:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {(
                              (selectedSwitchOutScheme?.units || 0) *
                              (selectedSwitchOutScheme?.nav || 0)
                            ).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            NAV as on Date:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {selectedSwitchOutScheme?.nav || 0}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={12}
                >
                  <FormStyledRadioButton
                    options={[
                      { label: 'Existing Scheme & Plan', value: 'E' },
                      { label: 'New Scheme & Plan', value: 'N' },
                    ]}
                    name='isExistingScheme'
                  />
                </Grid>
                {values.isExistingScheme === 'N' && (
                  <Grid
                    item
                    sm={6}
                  >
                    <FormSelectInput
                      name='category'
                      options={data?.categories?.map((category: string) => ({
                        label: category,
                        value: category,
                      }))}
                      onChange={async (e: SelectChangeEvent<any>) => (
                        setValues(values => ({
                          ...values,
                          category: e.target.value,
                          switchInScheme: '',
                        })),
                        setSelectedCategory(e.target.value)
                      )}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='switchInScheme'
                    label='Switch In Scheme'
                    options={getSwitchInSchemes(
                      values.isExistingScheme,
                      values.folioNumber,
                    ).map((scheme: any) => ({
                      label: `${scheme.schemeName || scheme.schemeDesc}-${
                        scheme.planDesc
                      }`,
                      value: `${scheme.scheme}_${scheme.plan}_${scheme.option}`,
                    }))}
                    onChange={(e: any) => {
                      const [scheme, plan, option] = e.target.value.split('_');

                      const filteredResult = getSwitchInSchemes(
                        values.isExistingScheme,
                        values.folioNumber,
                      ).filter(
                        (schemes: any) =>
                          schemes.scheme === scheme &&
                          schemes.plan === plan &&
                          schemes.option === option,
                      );

                      setSelectedSwitchInScheme(filteredResult?.[0]);
                    }}
                  />
                </Grid>
              </Grid>
            )}

            <Grid
              container
              spacing={2}
              mb={'24px'}
            >
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 1,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Switch Type
                </Typography>
                <FormStyledRadioButton
                  options={[
                    { label: 'Partial', value: 'P' },
                    { label: 'Full', value: 'F' },
                  ]}
                  name='type'
                  handleChange={handleSwitchTypeChange}
                />
              </Grid>
              <Grid
                item
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 1,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Switch Mode
                </Typography>
                <FormStyledRadioButton
                  options={[
                    { label: 'Units', value: 'U' },
                    { label: 'Amount', value: 'A' },
                  ]}
                  name='mode'
                  disabled={values.type === 'F'}
                  handleChange={async () => setFieldValue('value', '')}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 1,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Switch value (INR/UNITS)
                </Typography>
                <FormTextInput
                  required={false}
                  name='value'
                  placeholder='Value'
                  typeOfInput='number'
                  disabled={values.type === 'F'}
                  onInput={(e: any) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, '')
                      .replace(/^(\.)/, '')
                      .replace(/(\..*?)\..*/g, '$1')
                      .replace(/(\.\d{2})\d+/g, '$1');
                    if (e.target.value.length > 8) {
                      e.target.value = e.target.value.slice(0, 8);
                    }
                    setFieldValue('value', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              sx={{
                border: '1px dashed',
                borderColor: 'text.borderColorLight',
                my: 3,
              }}
            />
            <Typography
              variant='subtitle1'
              sx={{
                color: { xs: 'text.valueColor', sm: 'primary.main' },
                mb: 2,
              }}
            >
              Distributor Details
            </Typography>
            <Grid
              container
              spacing={2}
              mb={'24px'}
            >
              <Grid
                item
                sm={6}
                xs={12}
              >
                <FormTextInput
                  required={false}
                  name='arn'
                  label={'ARN Code'}
                  defaultValue={arn}
                  disabled
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <FormTextInput
                  required={false}
                  name='subArn'
                  label={'Sub ARN Code'}
                  defaultValue={subArn}
                  disabled //TODO: Need to change subArn and subBroker disabled condtion based on user login.
                  typeOfInput='alphanumeric'
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <FormTextInput
                  required={false}
                  name='subBroker'
                  label={'Sub Broker Code'}
                  defaultValue={subbrokerCode}
                  disabled
                  typeOfInput='alphanumeric'
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <EuinsFields
                  name='euinValue'
                  allEUINs={allEUINs}
                  defaultValue={values?.euinValue}
                />
              </Grid>
            </Grid>

            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              sx={{
                border: '1px dashed',
                borderColor: 'text.borderColorLight',
                mt: 3,
              }}
            />
            <SubmitCartButtons
              onAddToCartClick={
                !isEditMode ? handleAddToCartClick : handleCancelModifyingCart
              }
              firstButtonTitle={!isEditMode ? 'Add to Cart' : 'Go to Cart'}
              secondButtonTitle={!isEditMode ? 'Save & Proceed' : 'Update'}
            />

            {openDialogBox && (
              <CustomDialog
                isVisible={true}
                firstButtonHandler={handleGoToCart}
                secondButtonHandler={handleAddAnotherTransaction}
                firstButtonTitle='Go to cart'
                secondButtonTitle='Add another Transaction'
                handleClose={() => {}}
                showSecondButton={true}
              >
                <Box sx={{ textAlign: 'center', pt: 2 }}>
                  <img
                    src={VerifiedIcon}
                    alt='verified-icon'
                  />
                  <MainHeader sx={{ fontWeight: 500, mb: 2 }}>Added To Cart</MainHeader>
                  <FieldSubHeader3 sx={{ fontSize: '16px' }}>
                    Scheme is added to investor cart
                  </FieldSubHeader3>
                </Box>
              </CustomDialog>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default InvestmentDetailsForm;
