import {
  Box,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { useField } from 'formik';

import Checkbox from '../../common/checkbox';

export type FormCheckboxProps = CheckboxProps & {
  name: string;
  label: React.ReactNode;
  margin?: 'dense' | 'none';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function FormCheckbox({
  margin = 'dense',
  label,
  name,
  onChange,
  ...props
}: FormCheckboxProps): JSX.Element {
  const [field, meta] = useField(name);
  const { value: checked } = field;
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event); // Calling Formik's onChange
    if (onChange) {
      onChange(event); // Calling the passed onChange function if it exists
    }
  };
  return (
    <Box
      component='span'
      sx={{ display: 'inline-block' }}
    >
      <FormControl
        margin={margin}
        error={hasError}
        sx={{ display: 'flex' }}
      >
        <FormControlLabel
          sx={{
            'marginRight': '5px',
            'alignItems': 'center',
            '& .MuiFormControlLabel-label': {
              fontSize: { xs: '14px', xl: '16px' },
              fontWeight: 500,
              color: 'text.inputLabelText',
            },
          }}
          control={
            <Checkbox
              sx={theme => ({
                'fontSize': '16px',
                'color': theme.palette.primary.main,
                '& input:hover, & input:disabled ~ *': {
                  backgroundColor: `${theme.palette.primary.main}!important`,
                },
              })}
              {...field}
              checked={checked}
              onChange={handleChange}
              {...props}
            />
          }
          label={label}
        />
        <FormHelperText
          sx={{
            color: 'error.main',
            fontSize: { xs: '10px', sm: '13px', xl: '14px' },
            fontWeight: '400',
          }}
        >
          {errorText}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
