import { Box, Button, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as KeyBoardRightArrowIcon } from '../../../assets/right-arrow-mobile.svg';
import { ToggleSwitch } from '../../auth/login/styles/styledComponents';
import { SelectedRow } from '../types';
interface ResponsiveEmployeeSetupProps {
  employeeData: SelectedRow[];
  toggleHandler: (row: { [key: string]: any }) => void;
  actionHandler: (row: { [key: string]: any }) => void;
}
const ResponsiveEmployeeSetup: React.FC<ResponsiveEmployeeSetupProps> = ({
  employeeData,
  toggleHandler,
  actionHandler,
}) => {
  return (
    <Box>
      {Boolean(employeeData) &&
        employeeData?.length > 0 &&
        employeeData?.map((employee, index) => (
          <Box
            key={index}
            sx={{
              borderBottom: '1px solid',
              borderColor: 'text.borderColorLight',
              py: 2.75,
              display: { md: 'none' },
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex' }}>
                <KeyBoardRightArrowIcon onClick={() => actionHandler?.(employee)} />
                <Stack sx={{ ml: 1 }}>
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 500, color: 'text.labelColor' }}
                  >
                    Name
                  </Typography>
                  <Typography
                    sx={{ fontSize: '14px', fontWeight: 500, color: 'text.valueColor' }}
                  >
                    {employee.Name}
                  </Typography>
                </Stack>
              </Box>
              <Button
                onClick={() => toggleHandler?.(employee)}
                sx={{ p: 0 }}
              >
                <FormControlLabel
                  sx={{ m: 0, columnGap: '10px' }}
                  control={
                    <>
                      <ToggleSwitch
                        sx={{
                          'width': { xs: '27.5px' },
                          'height': { xs: '15px' },
                          '& .MuiButtonBase-root.MuiSwitch-switchBase': {
                            'top': '1px',
                            'left': '-1px',
                            '&.Mui-checked': {
                              transform: 'translateX(12px)',
                            },
                            '& .MuiSwitch-thumb': {
                              width: '8px',
                              height: '8px',
                            },
                          },
                        }}
                        checked={employee.additionalData?.active}
                      />
                      <Typography
                        sx={{
                          fontWeight: 400,
                          color: employee.additionalData?.active
                            ? 'success.dark'
                            : 'text.crimson',
                        }}
                      >
                        {employee.additionalData?.active ? 'Enabled' : 'Disabled'}
                      </Typography>
                    </>
                  }
                  label=''
                />{' '}
              </Button>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ ml: '9px', mt: 1.5 }}
            >
              <Grid
                item
                xs={8}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 400, color: 'text.labelColor' }}
                >
                  Role
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, color: 'text.valueColor' }}
                >
                  {employee?.Role}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 400, color: 'text.labelColor' }}
                >
                  ID
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, color: 'text.valueColor' }}
                >
                  {employee.ID || 'N/A'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 400, color: 'text.labelColor' }}
                >
                  Status
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, color: 'text.valueColor' }}
                >
                  {employee.Status}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 400, color: 'text.labelColor' }}
                >
                  Mobile Number
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, color: 'text.valueColor' }}
                >
                  {employee?.['Mobile Number']}
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 400, color: 'text.labelColor' }}
                >
                  Email
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, color: 'text.valueColor' }}
                >
                  {employee?.['Email']}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
    </Box>
  );
};

export default ResponsiveEmployeeSetup;
