import { useEffect, useMemo, useState } from 'react';

import { useGetSipStartandEndDatesMutation } from '../components/transactions/slice';

interface SipParams {
  fund: string;
  scheme: string;
  frequency: string;
  installments: number;
  trtype: string;
  day: number;
  isPerpetual: boolean;
}

interface ErrorState {
  showError: boolean;
  errorMessage: string;
}

// Custom hook
const useSipNew = ({
  fund,
  scheme,
  frequency,
  installments,
  trtype,
  day,
  isPerpetual = false,
}: SipParams): [Date | null, Date | null, ErrorState] => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [errors, setErrors] = useState<ErrorState>({
    showError: false,
    errorMessage: '',
  });
  const [getSipStartandEndDates] = useGetSipStartandEndDatesMutation();

  // Memoize the API call parameters to avoid unnecessary calls
  const apiParams = useMemo(() => {
    return { fund, scheme, frequency, installments, trtype, day, isPerpetual };
  }, [fund, scheme, frequency, installments, trtype, day, isPerpetual]);

  useEffect(() => {
    const fetchAndProcessData = async () => {
      if (
        fund &&
        scheme &&
        frequency !== '' &&
        installments > 0 &&
        trtype &&
        day &&
        !isPerpetual
      ) {
        if (trtype !== 'APL') {
          try {
            // Call the API with parameters
            const response = await getSipStartandEndDates({
              fund: apiParams.fund,
              tenant: 'IRIS',
              scheme: apiParams.scheme,
              installments: apiParams.installments,
              frequency: apiParams.frequency,
              trtype: apiParams.trtype,
              day: apiParams.day,
            }).unwrap();

            setStartDate(new Date(response.startDate));
            setEndDate(new Date(response.endDate));
            setErrors({
              showError: false,
              errorMessage: '',
            });
          } catch (err: any) {
            setStartDate(null);
            setEndDate(null);
            setErrors({
              showError: true,
              errorMessage: err.data.message,
            });
          }
        } else {
          // If trtype is 'APL', do not fetch data
          setStartDate(null);
          setEndDate(null);
          setErrors({
            showError: false,
            errorMessage: '',
          });
        }
      }
    };

    fetchAndProcessData();
  }, [apiParams, getSipStartandEndDates]); // Use memoized params in the dependency array

  return [startDate, endDate, errors];
};

export default useSipNew;
