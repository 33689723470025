// Loader.js
import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

import DashBoardLogo from '../../../assets/loader-logo.svg';
import { LoadingResponse } from '../../common/Loader/loaderSlice';

const CommonLoader = () => {
  const { loading } = useSelector((state: { loading: LoadingResponse }) => state.loading);

  if (!loading) return null;

  return (
    <>
      {/* Overlay to darken the background */}
      <Box
        sx={{
          'position': 'fixed',
          'display': 'flex',
          'alignItems': 'center',
          'justifyContent': 'center',
          'inset': 0,
          'backgroundColor': 'rgba(0, 0, 0, 0.5)',
          'zIndex': 9998, // Should be below the white background
          '-webkit-tap-highlight-color': 'transparent',
        }}
      />

      {/* White blurred background with loader content */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999, // Ensure this is on top
        }}
      >
        <Box sx={{ position: 'relative' }}>
          {/* Circular Progress */}
          <CircularProgress
            size={120} // Adjust size as needed
            thickness={1} // Adjust thickness as needed
            color='primary' // Change color if desired
          />

          <img
            src={DashBoardLogo}
            alt='Dashboard-logo'
            style={{
              position: 'absolute',
              top: '45%',
              left: '55%',
              transform: 'translate(-50%, -50%)', // Center the image
              width: '55%', // Adjust size as needed
              height: 'auto', // Maintain aspect ratio
              zIndex: 999, // Ensure image appears on top
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default CommonLoader;
