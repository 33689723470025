import { Box, Grid, IconButton, Stack, Theme, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackIcon_Mobile } from '../../assets/back-icon.svg';
import { ReactComponent as BackIcon } from '../../assets/back-icon-large.svg';
import { StepperNextButton } from '../auth/login/styles/styledComponents';
import InvestorProfile from '../common/InvestorProfile';
import PanDetailsBox from '../common/PanDetails';
import { TransactionResponse } from '../features/transactions/transactionSlice';

export default function Layout({
  title,
  subtitle,
  children,
  onBackPress,
  rightBottomComponent,
  rightBottomComponentDivider,
  isNctTransaction,
}: {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  onBackPress?: () => void;
  rightBottomComponent?: React.ReactNode;
  rightBottomComponentDivider?: boolean;
  isNctTransaction?: boolean;
}) {
  const navigate = useNavigate();
  const { pan, name } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  return (
    <>
      <PanDetailsBox
        name={name}
        value={pan}
      />
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          [theme.breakpoints.up(767)]: {
            mb: '20px',
          },
          [theme.breakpoints.between(768, 900)]: {
            mt: '35px',
          },
          [theme.breakpoints.down(767)]: {
            mt: '30px',
            mb: '20px',
          },
        })}
      >
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', mb: title ? 0 : 2 }}>
          <IconButton
            onClick={() => (onBackPress ? onBackPress() : navigate(-1))}
            sx={{
              display: { xs: 'none', sm: 'inline-flex' },
            }}
          >
            <BackIcon />
          </IconButton>
          <IconButton
            onClick={() => (onBackPress ? onBackPress() : navigate(-1))}
            sx={{
              display: { xs: 'unset', sm: 'none' },
            }}
          >
            <BackIcon_Mobile />
          </IconButton>
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '20px', lg: '24px', xl: '26px' },
              fontWeight: 500,
              ml: { xs: '10px', md: '0' },
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Box sx={{ display: { md: 'none' } }}>
          <StepperNextButton
            sx={{
              minWidth: { xs: '115px' },
              padding: { xs: '6px 20px', sm: '10px 20px' },
            }}
          >
            Update
          </StepperNextButton>
        </Box>
      </Box>

      {subtitle && (
        <Typography
          sx={{
            color: 'primary.main',
            mb: 2,
            fontSize: { xs: '14px', sm: '16px', xl: '18px', fontWeight: 500 },
          }}
        >
          {subtitle}
        </Typography>
      )}
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={9}
        >
          {children}
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
        >
          {rightBottomComponentDivider ? (
            <InvestorProfile bottomComponent={rightBottomComponent} />
          ) : (
            <InvestorProfile isNctTransaction={isNctTransaction}>
              {rightBottomComponent}
            </InvestorProfile>
          )}
        </Grid>
      </Grid>
    </>
  );
}
