import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  // FormLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
  TextField,
} from '@mui/material';
import { useField } from 'formik';
import { useMemo, useState } from 'react';

import { ReactComponent as SearchIcon } from '../../../assets/search-icon.svg';
import { ReactComponent as ArrowDropDown } from '../../../assets/select-dropdown-icon.svg';

export type SelectOption = {
  label: string;
  value: any;
};

export type FormSelectProps = SelectProps & {
  name: string;
  options?: SelectOption[];
  searchable?: boolean;
  searchPlaceholder?: string;
};

export default function FormSelect({
  name,
  fullWidth = true,
  label,
  // margin = 'dense',
  options = [],
  required,
  ...rest
}: FormSelectProps): JSX.Element {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;
  return (
    <FormControl
      variant='filled'
      margin='dense'
      sx={{
        '& .MuiFormLabel-root.MuiInputLabel-root': {
          fontSize: '14px',
          fontWeight: 500,
          color: 'text.labelColor',
          top: '2px',
        },
        '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
          color: 'text.labelColor',
          fontSize: '14px',
        },
        '& .MuiInputBase-root': {
          'fontSize': '14px',
          'color': 'text.labelColor',
          'fontWeight': 500,
          '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.Mui-disabled': {
            backgroundColor: 'text.lightGray',
            color: 'text.primary',
            WebkitTextFillColor: 'unset',
          },
          '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input': {
            '&:focus': {
              backgroundColor: 'unset',
              borderRadius: '8px',
            },
            'color': 'text.primary',
            'WebkitTextFillColor': 'unset',
          },
        },
        '& .MuiInputBase-root, & .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root': {
          'border': 'none',
          'backgroundColor': 'unset !important',
          '&:before, &:after, &:hover': {
            borderBottom: 'none !important',
          },
          '&.Mui-focused': {
            backgroundColor: 'unset',
          },
        },
        '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root.Mui-focused': {
          '&:hover': {
            '&:before': {
              border: 'none',
            },
          },
        },
        '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:hover': {
          'backgroundColor': 'unset',
          '&:before': {
            borderBottom: 'none',
          },
        },
        '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.MuiSelect-select': {
          height: '20px',
        },
        '& .MuiSelect-select ,&:focus': {
          borderRadius: '8px',
          border: '1px solid',
          borderColor: 'text.borderColorLight',
          backgroundColor: 'common.white',
          paddingBottom: '4px',
        },
      }}
      fullWidth={fullWidth}
      error={hasError}
      // size="small"
    >
      <InputLabel
        id='demo-simple-select-filled-label'
        sx={{
          'color': 'text.labelColor',
          'fontSize': '14px',
          'fontWeight': '500',
          'top': '2px',
          'left': '6px',
          '&.Mui-focused': {
            color: 'inherit',
            fontSize: '12px',
            fontWeight: '500',
          },
        }}
      >
        {label}
        {required && <>&nbsp;*</>}
      </InputLabel>
      <Select
        labelId='demo-simple-select-filled-label'
        id='demo-simple-select-filled'
        fullWidth
        sx={{ background: 'unset' }}
        {...field}
        {...rest}
        onChange={e => {
          field.onChange(e);
          if (rest.onChange) {
            rest.onChange(e, field.value);
          }
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {' '}
        {errorText && (
          <InfoOutlinedIcon
            sx={{ fontSize: '16px', verticalAlign: 'middle', mr: 0.5 }}
            color='error'
          />
        )}
        {errorText}
      </FormHelperText>
    </FormControl>
  );
}
export function FormSelectInput({
  name,
  fullWidth = true,
  label,
  options = [],
  required,
  disabled,
  defaultValue,
  searchable = false,
  searchPlaceholder = 'Search..',
  ...rest
}: FormSelectProps): JSX.Element {
  const [field, meta, helpers] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;
  const [searchText, setSearchText] = useState('');

  const containsText = (text: string | number, searchText: string) => {
    const normalizedText = typeof text === 'number' ? text.toString() : text;
    return normalizedText?.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  };

  const uniqueOptions = useMemo(() => {
    const seenValues: Record<string, boolean> = {};
    return options.filter(option => {
      if (seenValues[option.value]) {
        return false;
      } else {
        seenValues[option.value] = true;
        return true;
      }
    });
  }, [options]);

  const filteredOptions = useMemo(() => {
    if (!uniqueOptions || uniqueOptions.length === 0) {
      return [];
    }
    return uniqueOptions.filter(option => containsText(option.label, searchText));
  }, [uniqueOptions, searchText]);

  return (
    <FormControl
      margin='dense'
      variant='filled'
      fullWidth={fullWidth}
      error={hasError}
      disabled={disabled}
      sx={{
        '& .MuiFormLabel-root.MuiInputLabel-root': {
          fontSize: '14px',
          fontWeight: 500,
          color: 'text.labelColor',
          top: '2px',
        },
        '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
          color: 'text.labelColor',
          fontSize: '14px',
        },
        '& .MuiInputBase-root': {
          'fontSize': '13px',
          'color': 'text.labelColor',
          'fontWeight': 500,
          '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.Mui-disabled': {
            backgroundColor: 'text.lightGray',
            color: 'text.primary',
            WebkitTextFillColor: 'unset',
          },
          '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input': {
            '&:focus': {
              backgroundColor: 'unset',
              borderRadius: '8px',
            },
            'color': 'text.primary',
            'WebkitTextFillColor': 'unset',
          },
        },
        '& .MuiInputBase-root, & .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root': {
          'border': 'none',
          'backgroundColor': 'unset !important',
          '&:before, &:after, &:hover': {
            borderBottom: 'none !important',
          },
          '&.Mui-focused': {
            backgroundColor: 'unset',
          },
        },
        '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root.Mui-focused': {
          '&:hover': {
            '&:before': {
              border: 'none',
            },
          },
        },
        '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:hover': {
          'backgroundColor': 'unset',
          '&:before': {
            borderBottom: 'none',
          },
        },
        '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.MuiSelect-select': {
          height: '20px',
        },

        '& .MuiSelect-select ,&:focus': {
          borderRadius: '8px',
          border: '1px solid',
          borderColor: 'text.borderColorLight',
          backgroundColor: 'common.white',
          paddingBottom: '4px',
        },
      }}
    >
      <InputLabel id={`${name}-label`}>
        {label}
        {required && <>&nbsp;*</>}
      </InputLabel>
      <Select
        IconComponent={ArrowDropDown}
        sx={{ backgroundColor: 'unset' }}
        {...field}
        {...rest}
        value={field.value || defaultValue}
        onChange={e => {
          field.onChange(e);
          if (rest.onChange) {
            rest.onChange(e, field.value);
          }
        }}
        onBlur={e => {
          if (field.onBlur) {
            field.onBlur(e);
          }
          // marking as touched
          helpers.setTouched(true);
        }}
        onClose={() => setSearchText('')}
        renderValue={() =>
          uniqueOptions?.find(({ value }: { value: string }) => value === field.value)
            ?.label || ''
        }
        MenuProps={{ autoFocus: false }}
      >
        {searchable && (
          <ListSubheader
            sx={{
              '& .MuiInputBase-input': {
                border: 'unset',
              },
              '& svg': {
                width: 16,
                height: 16,
              },
            }}
          >
            <TextField
              size='small'
              placeholder={searchPlaceholder}
              disabled={uniqueOptions.length <= 0}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={e => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
      <FormHelperText>
        {' '}
        {errorText && (
          <InfoOutlinedIcon
            sx={{ fontSize: '16px', verticalAlign: 'middle', mr: 0.5 }}
            color='error'
          />
        )}
        {errorText}
      </FormHelperText>
    </FormControl>
  );
}
