import apiSlice from '../../../service/api';

const reportSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    soapReport: builder.mutation({
      query: payload => ({
        url: 'user/investors/soap-report',
        method: 'POST',
        body: payload,
      }),
    }),

    elssReport: builder.mutation({
      query: payload => ({
        url: 'user/investors/elss-report',
        method: 'POST',
        body: payload,
      }),
    }),
    netWorthReport: builder.mutation({
      query: payload => ({
        url: 'user/investors/networth-report',
        method: 'POST',
        body: payload,
      }),
    }),
    casReport: builder.mutation({
      query: payload => ({
        url: 'user/investors/cas-report',
        method: 'POST',
        body: payload,
      }),
    }),
    ccgsReport: builder.mutation({
      query: payload => ({
        url: 'user/investors/ccgs-report',
        method: 'POST',
        body: payload,
      }),
    }),
    cglReport: builder.mutation({
      query: payload => ({
        url: 'user/investors/cgl-report',
        method: 'POST',
        body: payload,
      }),
    }),
    distributorMailBackList: builder.mutation({
      query: payload => ({
        url: 'user/distributor-mailback-list',
        method: 'POST',
        body: payload,
      }),
    }),
    distributorReports: builder.mutation({
      query: payload => ({
        url: 'user/distributor-report',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useSoapReportMutation,
  useElssReportMutation,
  useNetWorthReportMutation,
  useCasReportMutation,
  useCcgsReportMutation,
  useCglReportMutation,
  useDistributorMailBackListMutation,
  useDistributorReportsMutation,
} = reportSlice;
