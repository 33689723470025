import { Box, Divider, Grid, Stack, Theme, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { AnyAction } from 'redux';

import GainLoss from '../../../assets/gain-loss.svg';
import InvestedValue from '../../../assets/invested-value.svg';
import MarketValue from '../../../assets/market-value.svg';
import Currency from '../../../assets/pie-chart.svg';
import PieImg from '../../../assets/rounded-currency.svg';
import UpwardIcon from '../../../assets/upwards.svg';
import {
  StepperAddButton,
  StepperNextButton,
} from '../../onboarding/styles/styledComponents';
import { Investor, InvestorPortfolio } from './investorTable';

interface EmpanelmentTabProps {
  investorPortfolio?: InvestorPortfolio;
  value: string;
  investor: Investor;
  transactHandler?: (
    investor: any,
    dispatch: React.Dispatch<AnyAction>,
    navigate: NavigateFunction,
    empaneledStatus: string,
    empaneledItems: { MFS: boolean; NPS: boolean },
  ) => void;
  empaneledStatus?: string;
  empaneledItems?: { MFS: boolean; NPS: boolean };
  type: string;
  portfolioSummary?: any;
}

const EmpanelmentTab: React.FC<EmpanelmentTabProps> = ({
  investorPortfolio,
  value,
  investor,
  transactHandler,
  empaneledStatus,
  empaneledItems,
  type,
  portfolioSummary,
}) => {
  const [screenWidth, setScreenWidth] = React.useState(window.screen.width);
  const navigate = useNavigate();
  const maxWidth = 950;
  const dispatch = useDispatch();

  const data =
    type === 'MFS'
      ? portfolioSummary
        ? [
            { name: 'equity', value: portfolioSummary.equityClassAmount ?? 0 },
            { name: 'debt', value: portfolioSummary.debtClassAmount ?? 0 },
            { name: 'liquid', value: portfolioSummary.liquidClassAmount ?? 0 },
          ]
        : [{ name: 'No Data', value: 1 }]
      : [
          { name: 'Tier 1', value: 25 },
          { name: 'Tier 2', value: 25 },
        ];

  //TODO - remove static data

  const COLORS =
    type === 'MFS' ? ['#ADD8E6', '#D8BFD8', '#FFD700'] : ['#ADD8E6', '#D8BFD8']; // TODO - remove static data

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function calculateGainLoss(type: any, portfolio: any) {
    let totalCurrentValuation = 0;
    let totalContributionAmount = 0;
    if (type === 'MFS') {
      totalCurrentValuation = Number(portfolio?.marketValue ?? 0);
      totalContributionAmount = Number(portfolio?.totalInvestedValue ?? 0);
    } else if (type === 'NPS') {
      totalCurrentValuation = Number(investorPortfolio?.totalCurrentValuation ?? 0);
      totalContributionAmount = Number(investorPortfolio?.totalContributionAmount ?? 0);
    }

    // Calculate gain/loss
    const difference = totalCurrentValuation - totalContributionAmount;
    const percentageGainLoss =
      totalContributionAmount !== 0 ? (difference / totalContributionAmount) * 100 : 0;

    return { validDifference: difference, percentageGainLoss };
  }
  const { validDifference, percentageGainLoss } = calculateGainLoss(
    type,
    type === 'MFS' ? portfolioSummary : investorPortfolio,
  );

  return (
    <Grid container>
      <Grid
        item
        md={4}
        xs={12}
      >
        <Box
          sx={(theme: Theme) => ({
            display: 'flex',
            alignItems: 'center',
            columnGap: '45px',
            [theme.breakpoints.down(1300)]: {
              columnGap: '0px',
            },
          })}
        >
          <Box
            sx={(theme: Theme) => ({
              [theme.breakpoints.down(1024)]: {
                flex: 1,
              },
            })}
          >
            <PieChart
              style={{ width: '200px', height: '200px', margin: '0 auto' }}
              width={200}
              height={200}
            >
              <Pie
                dataKey='value'
                data={data}
                cx='50%'
                cy='50%'
                startAngle={90}
                endAngle={-270}
                innerRadius={60} // Adjust inner radius to create space in the middle
                outerRadius={80}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value, name) => [`${name} - ${value} %`, '']} />
            </PieChart>
          </Box>
          <Box
            sx={(theme: Theme) => ({
              [theme.breakpoints.down(1200)]: {
                flex: 1,
              },

              [theme.breakpoints.down(1300)]: {
                ml: 2,
              },
            })}
          >
            {data.map((entry, index: number) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: COLORS[index as number] || 'red',
                    height: '10px',
                    width: '10px',
                    borderRadius: '50%',
                  }}
                />
                <Typography
                  component={'span'}
                  sx={{ ml: 1 }}
                >
                  {entry?.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        md={8}
        xs={12}
      >
        <Box
          sx={(theme: Theme) => ({
            borderLeft: {
              xs: 'unset',
              md: `1px solid ${theme.palette.text.borderColorLight}`,
            },
            pl: { xs: '0px', md: '30px' },
            pt: '30px',
          })}
        >
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            sx={{
              border: '1px dashed',
              borderColor: 'text.borderColorDark',
              mb: 3,
              display: { xs: 'block', md: 'none' },
            }}
          />
          <Grid
            container
            spacing={2}
            sx={{ mb: { xs: '0px', md: '35px' } }}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Box
                sx={{
                  display: 'flex',
                  columnGap: { md: '16px' },
                  alignItems: 'center',
                  justifyContent: { xs: 'space-between', md: 'unset' },
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={screenWidth <= maxWidth ? Currency : InvestedValue}
                    alt='invested-value'
                  />
                  <Typography
                    sx={{
                      color: 'text.labelColor',
                      display: { xs: 'block', md: 'none' },
                      ml: { xs: '20px', md: '0px' },
                    }}
                  >
                    Total Invested value:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: 'primary.main',
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  ₹
                  {type === 'MFS'
                    ? portfolioSummary?.totalInvestedValue?.toFixed(2) || 0
                    : Number(investorPortfolio?.totalContributionAmount)?.toFixed(2) || 0}
                </Typography>

                <Stack sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Typography
                    sx={{ fontSize: { lg: '20px', xl: '22px' }, fontWeight: 600 }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: '20px', xl: '22px' },
                        fontWeight: 400,
                        color: 'text.labelColor',
                      }}
                      component={'span'}
                    >
                      ₹
                    </Typography>
                    {type === 'MFS'
                      ? portfolioSummary?.totalInvestedValue?.toFixed(2) || 0
                      : Number(investorPortfolio?.totalContributionAmount)?.toFixed(2) ||
                        0}
                  </Typography>
                  <Typography
                    sx={{ fontSize: '14px', fontWeight: 500, color: 'text.labelColor' }}
                  >
                    Invested Value
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Box
                sx={{
                  display: 'flex',
                  columnGap: { md: '16px' },
                  alignItems: 'center',
                  justifyContent: { xs: 'space-between', md: 'unset' },
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={screenWidth <= maxWidth ? PieImg : MarketValue}
                    alt='marked-value'
                  />
                  <Typography
                    sx={{
                      color: 'text.labelColor',
                      display: { xs: 'block', md: 'none' },
                      ml: { xs: '20px', md: '0px' },
                    }}
                  >
                    Total Market value:
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: 'primary.main',
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  ₹
                  {type === 'MFS'
                    ? portfolioSummary?.marketValue?.toFixed(2) || 0
                    : Number(investorPortfolio?.totalCurrentValuation)?.toFixed(2) || 0}
                </Typography>
                <Stack sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Typography
                    sx={{ fontSize: { lg: '20px', xl: '22px' }, fontWeight: 600 }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: '20px', xl: '22px' },
                        fontWeight: 400,
                        color: 'text.labelColor',
                      }}
                      component={'span'}
                    >
                      ₹
                    </Typography>
                    {type === 'MFS'
                      ? portfolioSummary?.marketValue?.toFixed(2) || 0
                      : Number(investorPortfolio?.totalCurrentValuation)?.toFixed(2) || 0}
                  </Typography>
                  <Typography
                    sx={{ fontSize: '14px', fontWeight: 500, color: 'text.labelColor' }}
                  >
                    Market Value
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Box
                sx={{
                  display: 'flex',
                  columnGap: { md: '16px' },
                  alignItems: 'baseline',
                  justifyContent: { xs: 'space-between', md: 'unset' },
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={screenWidth <= maxWidth ? Currency : GainLoss}
                    alt='gain-loss'
                  />
                  <Typography
                    sx={{
                      color: 'text.labelColor',
                      display: { xs: 'block', md: 'none' },
                      ml: { xs: '20px', md: '0px' },
                    }}
                  >
                    Total Gain/loss:
                  </Typography>
                </Box>
                <Stack
                  sx={{ display: { xs: 'flex', alignItems: 'flex-end', md: 'none' } }}
                >
                  <Typography
                    sx={{
                      mb: 0.75,
                      fontSize: '16px',
                      fontWeight: 500,
                      color: 'primary.main',
                    }}
                  >
                    ₹{validDifference.toFixed(2)}
                  </Typography>
                  <Stack sx={{ flexDirection: 'row', gap: '8px' }}>
                    <img
                      src={UpwardIcon}
                      alt='upward-icon'
                    />
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        color: 'text.greenShade',
                      }}
                    >
                      {percentageGainLoss.toFixed(2)}%
                    </Typography>
                  </Stack>
                </Stack>

                <Stack sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Typography
                    sx={{
                      fontSize: { lg: '20px', xl: '22px' },
                      fontWeight: 600,
                      color: 'text.greenShade',
                    }}
                  >
                    {Number(validDifference) > 0 ? '+' : '-'} ₹
                    {String(validDifference.toFixed(2))?.replace('-', '')}
                  </Typography>
                  <Typography
                    sx={{ fontSize: '14px', fontWeight: 500, color: 'text.labelColor' }}
                  >
                    Gain/Loss
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            sx={{
              border: '1px dashed',
              borderColor: 'text.borderColorDark',
              mb: 3,
              display: { xs: 'block', md: 'none' },
            }}
          />

          <Typography sx={{ color: 'text.labelColor', mb: { xs: 2, md: 0 } }}>
            Change from previous trade date:
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                color: 'text.greenShade',
                ml: '5px',
              }}
              component={'span'}
            >
              {validDifference.toFixed(2)}
            </Typography>
          </Typography>
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            sx={{
              border: '1px dashed',
              borderColor: 'text.borderColorLight',
              my: 3,
              display: { xs: 'none', md: 'block' },
            }}
          />
          <Box
            sx={(theme: Theme) => ({
              'display': 'flex',
              'gap': '10px',
              'justifyContent': 'flex-start',
              'flexDirection': { xs: 'row-reverse', md: 'row' },
              '& .MuiButtonBase-root': {
                minWidth: '175px',
              },
            })}
          >
            <StepperNextButton
              sx={(theme: Theme) => ({
                padding: '8px 10px',
                [theme.breakpoints.down('sm')]: {
                  flex: 1,
                },
              })}
              onClick={() => {
                transactHandler &&
                  empaneledStatus &&
                  empaneledItems &&
                  transactHandler(
                    investor,
                    dispatch,
                    navigate,
                    empaneledStatus,
                    empaneledItems,
                  );
              }}
            >
              Transact in {type}
            </StepperNextButton>
            <StepperAddButton
              sx={(theme: Theme) => ({
                backgroundImage: 'unset',
                padding: '8px 10px',
                backgroundClip: 'unset',
                color: 'primary.main',
                WebkitTextFillColor: 'unset',
                [theme.breakpoints.down('sm')]: {
                  flex: 1,
                },
              })}
              onClick={() => {
                // if (investor?.pran && investorPortfolio)
                navigate('investor-portfolio', {
                  state: {
                    investor,
                    investorPortfolio,
                    portfolioSummary,
                    type,
                    validDifference,
                    percentageGainLoss,
                  },
                });
              }}
            >
              View More
            </StepperAddButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EmpanelmentTab;
