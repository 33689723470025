export const familyDescriptionOptions = [
  {
    label: 'Self',
    value: 'SE',
  },
  {
    label: 'Spouse',
    value: 'SP',
  },
  {
    label: 'Dependent Children',
    value: 'DC',
  },
  {
    label: 'Dependent Siblings',
    value: 'DS',
  },
  {
    label: 'Dependent Parents',
    value: 'DP',
  },
  {
    label: 'Guardian',
    value: 'GD',
  },
];

export const guardianRelationWithM = [
  {
    label: 'Brother',
    value: 'Brother',
  },
  {
    label: 'Daughter',
    value: 'Daughter',
  },
  {
    label: 'Father',
    value: 'Father',
  },
  {
    label: 'Mother',
    value: 'Mother',
  },
  {
    label: 'Sister',
    value: 'Sister',
  },
  {
    label: 'Son',
    value: 'Son',
  },
  {
    label: 'Wife',
    value: 'Wife',
  },
];

export const sipFrequencyOptions = [
  {
    label: 'Monthly',
    value: 'Monthly',
  },
  {
    label: 'Yearly',
    value: 'Yearly',
  },
];

export const sourceOfWealthOptions = [
  { label: 'Salary', value: '1' },
  { label: 'Business Income', value: '2' },
  { label: 'Gift', value: '3' },
  { label: 'Ancestral property', value: '4' },
  { label: 'Rental Income', value: '5' },
  { label: 'Prize Money', value: '6' },
  { label: 'Royalty', value: '7' },
  { label: 'Other', value: '8' },
];

export const relationshipsOptions = [
  { label: 'Aunt', value: 'Aunt' },
  { label: 'Brother', value: 'Brother' },
  { label: 'Daughter', value: 'Daughter' },
  { label: 'Daughter In Law', value: 'Daughter In Law' },
  { label: 'Father', value: 'Father' },
  { label: 'Friend', value: 'Friend' },
  { label: 'Grand Daughter', value: 'Grand Daughter' },
  { label: 'Grand Father', value: 'Grand Father' },
  { label: 'Grand Mother', value: 'Grand Mother' },
  { label: 'Grand Son', value: 'Grand Son' },
  { label: 'Husband', value: 'Husband' },
  { label: 'Mother', value: 'Mother' },
  { label: 'Nephew', value: 'Nephew' },
  { label: 'Niece', value: 'Niece' },
  { label: 'Other', value: 'Other' },
  { label: 'Sister', value: 'Sister' },
  { label: 'Son', value: 'Son' },
  { label: 'Son In Law', value: 'Son In Law' },
  { label: 'Uncle', value: 'Uncle' },
  { label: 'Wife', value: 'Wife' },
];

export const Occupations_options = [
  { label: 'Service', value: '1' },
  { label: 'Business', value: '2' },
  { label: 'Student', value: '3' },
  { label: 'House Wife', value: '4' },
  { label: 'Professional', value: '5' },
  { label: 'Retired', value: '7' },
  { label: 'Others', value: '8' },
];
export const Gross_Annual_income_options = [
  { label: 'Below 1 Lakh', value: 'Below 1 Lakh' },
  { label: '1-5 Lakhs', value: '1-5 Lakhs' },
  { label: '5-10 Lakhs', value: '5-10 Lakhs' },
  { label: '10-25 Lakhs', value: '10-25 Lakhs' },
  { label: '25 Lakhs-1 Crore', value: '25 Lakhs-1 Crore' },
  { label: '1 Crore and above', value: '1 Crore and above' },
];

export const steps = [
  {
    index: 1,
    title: 'Select Schemes First',
    subtitle: 'Choose schemes before onboarding the user to ensure a seamless experiance',
    background: '#111927',
  },
  {
    index: 2,
    title: 'Investor Folio Creation',
    subtitle: `Complete Investor onboarding: Fill Investor Details , FATCA ,
      nominee and Bank Details for seamless folio creation.`,
    background: '#d9d9d9',
  },
];

export const ResidentIndiaAccTypeOption = [
  { label: 'Saving', value: 'Saving' },
  { label: 'Current', value: 'Current' },
];

export const NreActypeOption = [
  { label: 'NRE', value: 'nre' },
  { label: 'NRO', value: 'nro' },
];
export const DEFAULT_MIN_TAT = 7;
