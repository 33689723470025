import {
  Box,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as NoDataIcon } from '../../assets/no-data-table.svg';
import AUM from '../../assets/OnboardingDashboard/aum.svg';
import { ReactComponent as DirectionIcon } from '../../assets/OnboardingDashboard/direction-icon.svg';
// import { ReactComponent as EditFilled } from '../../assets/OnboardingDashboard/edit-filled.svg';
import { ReactComponent as ExpiredFilled } from '../../assets/OnboardingDashboard/expired-filled.svg';
import SIPDashboard from '../../assets/OnboardingDashboard/mfs-sip-bg.png';
import { ReactComponent as ProcessingFilled } from '../../assets/OnboardingDashboard/processing-filled.svg';
import { ReactComponent as SIPCoin } from '../../assets/OnboardingDashboard/sip-coin.svg';
import Investors from '../../assets/OnboardingDashboard/total-investors.svg';
import Schemes from '../../assets/OnboardingDashboard/total-schemes.svg';
import { ReactComponent as WhatsappFilled } from '../../assets/OnboardingDashboard/whatsapp-filled.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-icon.svg';
import { Investorcard, StepperNextButton } from '../auth/login/styles/styledComponents';

interface Props {
  snapshotsData: SnapshotData | null; // Define the type of snapshotsData prop
  allAumDetails: AllAumDetails | null;
}
import { TabContext, TabList } from '@mui/lab';
import { format } from 'date-fns';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import {
  calculatePercentage,
  capitalizeFirstLetter,
  formatCurrencyInINR,
} from '../../utils';
import { SnapshotData } from '.';
import AumSummary from './AumSummary';
import { createHeadCells } from './tableUtilities';
import { AllAumDetails, AmcWiseBreakup, COLUMNS_AMC_WISE } from './types';

export const MF: React.FC<Props> = ({ snapshotsData, allAumDetails }) => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState('1');

  const reports = [
    'Transaction Report',
    'Net AUM Report',
    'Client-wise AUM Report',
    'Brokerage Report',
    'Investor Master Information',
    'NAV Report',
    'Transaction wise Investor Master',
    'SIP/STP Report',
  ];
  // Pie Chart Data

  const total = Object.values(allAumDetails?.assetWiseBreakup || {}).reduce(
    (acc, value) => acc + value,
    0,
  );
  const pieChartData =
    total > 0
      ? Object.entries(allAumDetails?.assetWiseBreakup || {}).map(([type, value]) => ({
          name: capitalizeFirstLetter(type),
          value: allAumDetails?.aum ? (Number(value) / allAumDetails.aum) * 100 : 0,
        }))
      : [{ name: 'No Data', value: 1 }];

  const createRows = (
    dataArr: AmcWiseBreakup[],
  ): { fundName: string; percentage: number; aum: string }[] => {
    return dataArr.map(item => ({
      fundName: item.fundName,
      percentage: item.percentage || 0, // TODO: when no & is available.
      aum: (item?.aum as number) > 0 ? formatCurrencyInINR(item.aum as number) : '0',
    }));
  };
  const allInvestorData = [
    {
      name: 'AUM(INR)',

      rupees:
        Number(allAumDetails?.aum) > 0
          ? formatCurrencyInINR(allAumDetails?.aum as number)
          : '0',

      icon: AUM,
    },

    {
      name: 'Total Schemes',

      rupees: allAumDetails?.numberOfSchemes || '0',

      icon: Schemes,
    },

    {
      name: 'Total Investors',

      rupees: allAumDetails?.totalInvestors || '0',

      icon: Investors,
    },
  ];

  const assetWiseBreakupHeaders = [
    { label: '', xs: 4, sm: 4 },

    { label: 'Percentage(%)', xs: 4, sm: 4 },

    { label: 'INR', xs: 4, sm: 4 },
  ];
  const COLORS = ['#ADD8E6', '#D8BFD8'];
  const handleSnapshot = (type: string) => {
    const state = { authorizationType: type };
    navigate('transaction-summary', { state });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  return (
    <>
      <Grid
        container
        my={'20px'}
        spacing={2}
      >
        {allInvestorData.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'position': 'relative',
                'borderRadius': '5px 10px 10px 5px',
                'border': '0.1px solid',
                'borderColor': 'text.borderColorLight',
                'bgcolor': 'common.white',
                'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                'padding': '25px',
                'display': 'flex',
                'alignItems': 'center',
                '&:before': {
                  content: `' '`,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '5px',
                  height: '100%',
                  background: theme.palette.background.primaryLinearGradient,
                  borderRadius: '15px 0px 0px 15px',
                },
              })}
            >
              <CardMedia
                component='img'
                image={item.icon}
                alt='image'
                sx={{ width: '70px', height: '70px', mr: 4 }}
              />
              <Stack>
                <Typography sx={{ color: 'text.labelColor', mb: 1 }}>
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    fontWeight: 700,
                    fontSize: { xs: '14px', lg: '18px', xl: '22px' },
                  }}
                >
                  {item.rupees}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Typography
        variant='subtitle2'
        sx={{ my: 2, color: 'text.labelColor' }}
      >
        As on: {format(new Date(), 'd MMMM, yyyy')}
      </Typography>
      <Grid
        container
        spacing={3}
        sx={{ mt: '10px', mb: 5 }}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Investorcard sx={{ padding: { xs: '16px', md: '30px' } }}>
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                color: 'primary.main',
                fontWeight: 500,
                mb: 2,
              }}
            >
              Asset Class-Wise Investment Breakup
            </Typography>

            <Typography
              variant='subtitle2'
              sx={{ color: 'text.labelColor' }}
            >
              As on: {format(new Date(), 'd MMMM, yyyy')}
            </Typography>

            {/* pieChart UI Missing*/}
            <Box>
              <PieChart
                style={{ width: '200px', height: '200px', margin: '0 auto' }}
                width={200}
                height={200}
              >
                <Pie
                  dataKey='value'
                  data={pieChartData}
                  cx='50%'
                  cy='50%'
                  startAngle={90}
                  endAngle={-270}
                  innerRadius={60} // Adjust inner radius to create space in the middle
                  outerRadius={80}
                  fill='#8884d8'
                >
                  {!!pieChartData &&
                    pieChartData?.length > 0 &&
                    pieChartData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                </Pie>
                <Tooltip
                  formatter={(value, name) => [
                    `${name} - ${Number(value).toFixed(2)} %`,
                    '',
                  ]}
                />
              </PieChart>
              <Box>
                {!!pieChartData &&
                  total <= 0 &&
                  pieChartData?.map((entry, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '10px',
                        my: 2,
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: COLORS[index as number] || 'red',
                          height: '10px',
                          width: '10px',
                          borderRadius: '50%',
                        }}
                      />
                      <Typography component={'span'}>{entry?.name}</Typography>
                    </Box>
                  ))}
              </Box>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-root ': {
                        padding: { xs: '16px 8px', sm: '16px' },
                      },
                    }}
                  >
                    {/* <TableCell colSpan={1} /> */}
                    {allAumDetails?.assetWiseBreakup &&
                      Object.keys(allAumDetails?.assetWiseBreakup)?.length > 0 &&
                      assetWiseBreakupHeaders?.map((header, index) => (
                        <TableCell key={index}>
                          <Typography
                            variant='subtitle2'
                            sx={{
                              color: 'text.valueColor',
                              fontWeight: 600,
                            }}
                          >
                            {header.label}
                          </Typography>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    '& .MuiTableRow-root:nth-of-type(odd)': {
                      bgcolor: 'common.white',
                    },
                    '& .MuiTableCell-root ': {
                      padding: { xs: '16px 8px', sm: '16px' },
                    },
                  }}
                >
                  {allAumDetails?.assetWiseBreakup &&
                    Object.keys(allAumDetails?.assetWiseBreakup)?.length > 0 &&
                    Object.entries(allAumDetails?.assetWiseBreakup).map(
                      ([type, value], index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Stack
                              direction='row'
                              alignItems='center'
                              columnGap='20px'
                            >
                              <Box
                                sx={{
                                  bgcolor: 'text.paleAqua',
                                  width: '11px',
                                  height: '11px',
                                  borderRadius: '50px',
                                }}
                              />
                              <Typography sx={{ color: 'text.valueColor' }}>
                                {capitalizeFirstLetter(type)}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ color: 'text.valueColor' }}>
                              {value
                                ? `${calculatePercentage(value, allAumDetails?.aum)}%`
                                : 0}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ color: 'text.valueColor' }}>
                              {value ? formatCurrencyInINR(value) : 0}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Investorcard>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Investorcard sx={{ padding: { xs: '20px', sm: 'unset' } }}>
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                color: 'primary.main',
                fontWeight: 500,
                margin: { sm: '10px 10px', md: '30px 25px 16px' },
              }}
            >
              AMC Wise Breakup
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Grid
                container
                sx={{
                  bgcolor: 'text.tableBgColor',
                  padding: { xs: '8px 20px', md: '16px 25px' },
                  position: 'sticky',
                  justifyContent: 'space-between',
                }}
              >
                {allAumDetails?.amcWiseBreakup &&
                  allAumDetails?.amcWiseBreakup?.length > 0 &&
                  createHeadCells(allAumDetails?.amcWiseBreakup, COLUMNS_AMC_WISE).map(
                    (item, index) => {
                      return (
                        <Grid
                          item
                          xs={4}
                          key={index}
                        >
                          <Typography
                            sx={{
                              fontSize: { sm: '14px', xl: '15px' },
                              fontWeight: 500,
                            }}
                          >
                            {item.label}
                          </Typography>
                        </Grid>
                      );
                    },
                  )}
              </Grid>
              <Box
                sx={{
                  'maxHeight': '350px',
                  'overflowY': 'auto',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '214px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    height: '6px',
                    border: '2px solid rgba(0, 0, 0, 0)',
                    backgroundClip: 'padding-box',
                    backgroundColor: 'text.stepperLine',
                    borderRadius: '33px',
                    boxShadow:
                      'inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05)',
                  },
                  '&::-webkit-scrollbar-button': {
                    display: 'none',
                    width: 0,
                    height: 0,
                  },
                  '&::-webkit-scrollbar-corner': {
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-track': {
                    borderRadius: '33px',
                    background: 'rgba(255, 255, 255, 0.8)',
                  },
                }}
              >
                <Box>
                  {allAumDetails?.amcWiseBreakup &&
                  Object.keys(allAumDetails.amcWiseBreakup).length > 0 ? (
                    createRows(allAumDetails.amcWiseBreakup).map((data, index) => {
                      return (
                        <Grid
                          container
                          sx={{
                            'padding': { xs: '8px 20px', md: '30px 25px' },
                            'justifyContent': 'space-between',
                            '&:not(:last-child)': {
                              borderBottom: '1px solid',
                              borderColor: 'text.borderColorDark',
                            },
                            '& .MuiTypography-root': {
                              whiteSpace: 'wrap',
                            },
                          }}
                          key={index}
                        >
                          <Grid
                            item
                            xs={4}
                            md={6}
                          >
                            <Typography sx={{ fontSize: { sm: '14px', xl: '16px' } }}>
                              {data.fundName}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={3.5}
                          >
                            <Typography sx={{ fontSize: { sm: '14px', xl: '16px' } }}>
                              {data.percentage > 0 ? `${data.percentage.toFixed(2)}%` : 0}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={2.5}
                          >
                            <Typography sx={{ fontSize: { sm: '14px', xl: '16px' } }}>
                              {data.aum}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxWidth: '400px',
                        margin: '5% auto',
                      }}
                    >
                      <NoDataIcon />
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontWeight: 500,
                          color: 'text.labelColor',
                          my: 4,
                        }}
                      >
                        {'No Data found'}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            {/*Mobile UI*/}
            <Box
              sx={{
                display: { xs: 'block', sm: 'none' },
                mt: 2,
              }}
            >
              {allAumDetails?.amcWiseBreakup.map((amc, index) => (
                <Box
                  key={index}
                  sx={{
                    borderBottom: '1px dashed',
                    borderColor: 'text.borderColorLight',
                    pb: 2.5,
                    mb: 2.5,
                  }}
                >
                  <Typography sx={{ fontWeight: 500, mb: 2 }}>{amc?.fundName}</Typography>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Stack>
                      <Typography
                        sx={{ color: 'text.labelColor', fontSize: '12px', mb: 1 }}
                      >
                        Percent(%)
                      </Typography>
                      <Typography
                        sx={{
                          color: 'text.valueColor',
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {amc?.percentage || 0}
                      </Typography>
                    </Stack>
                    <Stack sx={{ textAlign: 'end' }}>
                      <Typography
                        sx={{ color: 'text.labelColor', fontSize: '12px', mb: 1 }}
                      >
                        INR
                      </Typography>
                      <Typography
                        sx={{
                          color: 'text.valueColor',
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {amc?.aum || 0}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </Box>
            {/*Mobile UI*/}
          </Investorcard>
        </Grid>
      </Grid>
      <Investorcard sx={{ padding: { xs: '20px 20px', sm: '30px 30px' } }}>
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '16px', xl: '18px' },
            color: 'primary.main',
            fontWeight: 500,
            mb: 3,
          }}
        >
          Transaction Snapshots
        </Typography>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'bgcolor': 'text.lightShadeGreen',
                'padding': '10px 15px 15px 35px',
                'borderRadius': '10px',
                'display': 'flex',
                'justifyContent': 'space-between',
                'alignItems': 'center',
                'cursor': 'pointer',

                '&:hover': {
                  '& .MuiBox-root': {
                    '&:before': {
                      left: 0,
                      bottom: 0,
                      top: 0,
                    },
                    '& svg path': {
                      fill: theme.palette.text.greenShade, // Change fill color of paths within SVG on hoverZ
                      transition: 'fill 0.5s',
                    },
                  },
                },
              })}
              onClick={() => handleSnapshot('Approved')}
            >
              <Stack>
                <Typography
                  sx={{
                    color: 'text.greenShade',
                    fontSize: { xs: '26px', sm: '28px', xl: '32px' },
                    fontWeight: 900,
                  }}
                >
                  {snapshotsData?.approved ?? 'NA'}
                </Typography>
                <Typography
                  sx={{
                    color: 'text.labelColor',
                    fontSize: { xs: '12px', sm: '16px', xl: '18px' },
                    fontWeight: 500,
                  }}
                >
                  Approved
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon onClick={() => handleSnapshot('Approved')} />
                </IconButton>

                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': { xs: '35px', sm: '50px' },
                    'height': { xs: '35px', sm: '50px' },
                    'bgcolor': 'text.greenShade',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,
                    'transition': 'background-color 0.5s', // Add transition for smooth effect
                    '& svg': {
                      zIndex: '2',
                      width: { xs: '18px', sm: 'unset' },
                      height: { xs: '18px', sm: 'unset' },
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <WhatsappFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'bgcolor': 'text.lightShadeAmber',
                'padding': '10px 15px 15px 35px',
                'borderRadius': '10px',
                'display': 'flex',
                'justifyContent': 'space-between',
                'cursor': 'pointer',
                'alignItems': 'center',
                '&:hover': {
                  '& .MuiBox-root': {
                    '&:before': {
                      left: 0,
                      bottom: 0,
                      top: 0,
                    },
                    '& svg path': {
                      fill: theme.palette.text.amber, // Change fill color of paths within SVG on hoverZ
                      transition: 'fill 0.5s',
                    },
                  },
                },
              })}
              onClick={() => handleSnapshot('Pending')}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: { xs: '26px', sm: '28px', xl: '32px' },
                    color: 'text.amber',
                    fontWeight: 900,
                  }}
                >
                  {snapshotsData?.pending ?? 'NA'}
                </Typography>
                <Typography
                  sx={{
                    color: 'text.labelColor',
                    fontSize: { xs: '12px', sm: '16px', xl: '18px' },
                    fontWeight: 500,
                  }}
                >
                  Pending
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon onClick={() => handleSnapshot('Pending')} />
                </IconButton>
                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': { xs: '35px', sm: '50px' },
                    'height': { xs: '35px', sm: '50px' },
                    'bgcolor': 'text.amber',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,

                    'transition': 'background-color 0.5s', // Add transition for smooth effect

                    '& svg': {
                      zIndex: '2',
                      width: { xs: '18px', sm: 'unset' },
                      height: { xs: '18px', sm: 'unset' },
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <ProcessingFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'bgcolor': 'text.coral',
                'padding': '10px 15px 15px 35px',
                'borderRadius': '10px',
                'display': 'flex',
                'justifyContent': 'space-between',
                'cursor': 'pointer',
                'alignItems': 'center',
                '&:hover': {
                  '& .MuiBox-root': {
                    '&:before': {
                      left: 0,
                      bottom: 0,
                      top: 0,
                    },
                    '& svg path': {
                      fill: theme.palette.text.clearText, // Change fill color of paths within SVG on hoverZ
                      transition: 'fill 0.5s',
                    },
                  },
                },
              })}
              onClick={() => handleSnapshot('Expired')}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: { xs: '26px', sm: '28px', xl: '32px' },
                    color: 'text.clearText',
                    fontWeight: 900,
                  }}
                >
                  {snapshotsData?.expired ?? 'NA'}
                </Typography>
                <Typography
                  sx={{
                    color: 'text.labelColor',
                    fontSize: { xs: '12px', sm: '16px', xl: '18px' },
                    fontWeight: 500,
                  }}
                >
                  Expired
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon onClick={() => handleSnapshot('Expired')} />
                </IconButton>
                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': { xs: '35px', sm: '50px' },
                    'height': { xs: '35px', sm: '50px' },
                    'bgcolor': 'text.clearText',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,
                    'transition': 'background-color 0.5s', // Add transition for smooth effect

                    '& svg': {
                      zIndex: '2',
                      width: { xs: '18px', sm: 'unset' },
                      height: { xs: '18px', sm: 'unset' },
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <ExpiredFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'bgcolor': 'text.lightBlue',
                'padding': '10px 15px 15px 35px',
                'borderRadius': '10px',
                'display': 'flex',
                'justifyContent': 'space-between',
                'cursor': 'pointer',
                'alignItems': 'center',
                '&:hover': {
                  '& .MuiBox-root': {
                    '&:before': {
                      left: 0,
                      bottom: 0,
                      top: 0,
                    },
                    '& svg path': {
                      fill: theme.palette.text.turquoiseBlue, // Change fill color of paths within SVG on hoverZ
                      transition: 'fill 0.5s',
                    },
                  },
                },
              })}
            >
              <Stack>
                <Typography
                  sx={{
                    color: 'text.lightShadeBlue',
                    fontWeight: 900,
                    fontSize: { xs: '26px', sm: '28px', xl: '32px' },
                  }}
                >
                  {snapshotsData?.PhysicalTransactions ?? 'NA'}
                </Typography>
                <Typography
                  sx={{
                    color: 'text.labelColor',
                    fontSize: { xs: '12px', sm: '16px', xl: '18px' },
                    fontWeight: 500,
                  }}
                >
                  Phygital Transactions
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon />
                </IconButton>

                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': { xs: '35px', sm: '50px' },
                    'height': { xs: '35px', sm: '50px' },
                    'bgcolor': 'text.denimBlue',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,

                    'transition': 'background-color 0.5s', // Add transition for smooth effect

                    '& svg': {
                      zIndex: '2',
                      width: { xs: '18px', sm: 'unset' },
                      height: { xs: '18px', sm: 'unset' },
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <EditFilled />
                </Box>
              </Stack>
            </Box>
          </Grid> */}
        </Grid>
      </Investorcard>
      <Box
        sx={(theme: Theme) => ({
          my: 3,
          backgroundSize: { xs: 'contain' },
          borderRadius: '15px',
          backgroundRepeat: 'no-repeat',
          background: `${theme.palette.text.lightBlue2} url(${SIPDashboard}) no-repeat right 10% center`,

          padding: '35px 35px',
          [theme.breakpoints.down('sm')]: {
            background: `${theme.palette.text.lightBlue2}`,
          },
        })}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          columnGap={'16px'}
        >
          <Typography
            variant='h3'
            fontWeight={'700'}
            sx={{ color: 'primary.main' }}
          >
            SIP Dashboard
          </Typography>
          <SIPCoin />
        </Stack>
        <Typography
          sx={{
            color: 'text.labelColor',
            maxWidth: { xs: '240px', sm: '400px', lg: '520px' },
            fontSize: { xs: '14px', xl: '16px' },
            lineHeight: '28px',
            my: 3,
          }}
        >
          Monitor active, paused, and cancelled SIPs in real time for efficient tracking
          and management of communication sessions.
        </Typography>
        <StepperNextButton
          onClick={() => navigate('./sip-dashboard')}
          sx={{ minWidth: '140px', padding: '8px 20px' }}
        >
          Explore SIPs
        </StepperNextButton>
      </Box>
      <Investorcard
        sx={(theme: Theme) => ({
          position: 'relative',
          padding: '30px 20px',
          zIndex: '1',
          transition: 'width 0.2ms',
          cursor: 'pointer',
          height: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          // justifyContent: 'space-between',
          flexDirection: 'column',
        })}
      >
        <TabContext value={tabValue}>
          <Box
            sx={{
              'width': '100%',
              'borderBottom': '1px solid',
              'borderColor': 'text.borderColorLight',
              'mb': 4,
              '& .MuiTabs-root': {
                '& .MuiTabs-flexContainer': {
                  'pl': '0px',
                  'columnGap': '25px',
                  '& .MuiButtonBase-root.MuiTab-root': {
                    'pb': '20px',
                    'fontSize': { xl: '18px', lg: '16px', xs: '14px' },
                    'fontWeight': 500,
                    '&.Mui-disabled': {
                      color: 'rgba(108, 115, 127, 0.50)',
                    },
                  },
                },
                '& .MuiTabs-indicator': {
                  height: '5px',
                  borderRadius: '100px 100px 0px 0px',
                },
              },
              '& .MuiTabPanel-root': {
                padding: '50px 50px',
              },
            }}
          >
            <TabList onChange={handleTabChange}>
              <Tab
                label='AUM Summary'
                value='1'
              />
              <Tab
                label='Frequent Reports '
                value='2'
              />
            </TabList>
          </Box>
        </TabContext>
        {tabValue === '1' ? (
          <AumSummary />
        ) : (
          <Grid
            container
            spacing={2}
          >
            {reports.map((report, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  alignItems={'stretch'}
                >
                  <Investorcard
                    sx={(theme: Theme) => ({
                      'position': 'relative',
                      'padding': '30px 20px',
                      'zIndex': '1',
                      'transition': 'width 0.2ms',
                      // 'cursor': 'pointer',
                      'height': '100%',
                      'display': 'flex',
                      'alignItems': 'center',
                      'justifyContent': 'space-between',
                      '&:before': {
                        content: `' '`,
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        width: '0%',
                        height: '100%',
                        backgroundColor: 'rgba(111, 229, 232, 0.251)',
                        transition: '.4s',
                        zIndex: '1',
                        borderRadius: '48px 15px 15px 48px',
                      },
                      '&:hover': {
                        '& .MuiTypography-root': {
                          color: 'primary.main',
                        },
                        '&:before': {
                          width: '33%',
                          [theme.breakpoints.up(1920)]: {
                            width: '25%',
                          },
                        },
                        '& .MuiButtonBase-root': {
                          'bgcolor': 'unset',
                          '&:before': {
                            left: '0px',
                            bottom: 0,
                            top: 0,
                            right: 0,
                            width: '100%',
                          },
                          '& path': {
                            stroke: 'white',
                            zIndex: 3,
                          },
                        },
                      },
                      '& .MuiTypography-root': {
                        whiteSpace: 'nowrap',
                        fontWeight: 500,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    })}
                    onClick={() =>
                      navigate('/transaction-dashboard/mis/mfs-reports', {
                        state: { report: report, isFromDashboard: true },
                        replace: true,
                      })
                    }
                  >
                    <Typography variant='subtitle2'>{report}</Typography>
                    <IconButton
                      sx={(theme: Theme) => ({
                        'display': 'flex',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'borderRadius': '38px',
                        'border': '1px solid',
                        'borderColor': 'text.borderColorLight',
                        'padding': '11.5px 26px',
                        'zIndex': '1',
                        'transition': 'width 0.2ms', // Add transition for smooth effect
                        '& svg': {
                          position: 'absolute',
                          zIndex: 3,
                        },
                        '&:before': {
                          content: `' '`,
                          position: 'absolute',
                          top: '0px',
                          left: '0px',
                          right: '0px',
                          bottom: '0px',
                          width: '0%',
                          height: '100%',
                          background: theme.palette.background.primaryLinearGradient2,
                          transition: '.4s',
                          zIndex: '1',
                          borderRadius: '38px',
                        },
                      })}
                    >
                      <RightArrowIcon />
                    </IconButton>
                  </Investorcard>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Investorcard>
    </>
  );
};
