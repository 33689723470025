import { AmcWiseBreakup, COLUMNS_AMC_WISE } from './types';

export const createHeadCells = (
  dataArr: AmcWiseBreakup[],
  columnsToShow: typeof COLUMNS_AMC_WISE,
) => {
  if (!dataArr.length) return [];

  const order = Object.keys(columnsToShow);

  const newArr = Object.keys(dataArr[0])
    .filter(item => columnsToShow[item as keyof typeof COLUMNS_AMC_WISE])
    .map(item => ({
      id: item,
      numeric: true,
      disablePadding: false,
      label: columnsToShow[item as keyof typeof COLUMNS_AMC_WISE],
    }))
    .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));

  return newArr;
};
