import { Box, CardMedia, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import AmountIcon from '../../../assets/amount-coin.svg';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import MobileInvestorProfile from '../../common/UI/MobileInvestorProfile';
import { useRootContext } from '../../data/root.context';
import Layout from '../Layout';
import { useGetIndividualSchemeDetailsMutation } from '../slice';
import { SchemeDetails } from '../types';
import InvestmentDetailsForm from './InvestmentDetailsForm';

export default function InvestmentDetails() {
  const { state } = useLocation();
  const [investorDetails, setInvestorDetails] = useState<SchemeDetails | null>(null);
  const [individualSchemeDetails] = useGetIndividualSchemeDetailsMutation();
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const { showToast } = useRootContext();

  const dispatch = useDispatch();

  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      const payload = {
        pan: pan,
        folio: state?.schemeDetails?.folioNo,
        fund: state?.schemeDetails?.fund,
        trtype: 'SWP',
        scheme: state?.schemeDetails?.scheme,
        option: state?.schemeDetails?.schemeOption,
        plan: state?.schemeDetails?.schemePlan,
      };

      try {
        dispatch(startLoading());

        const response = await individualSchemeDetails(payload).unwrap();
        setInvestorDetails(response);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }

    getInvestorDetailsSchemes();
  }, []);
  const RightBottomComp = ({ schemaData }: any) => {
    return (
      <Box
        sx={{
          padding: '30px 20px',
          borderTop: '1px solid',
          borderColor: 'text.borderColorDark',
        }}
      >
        <Stack
          direction='column'
          gap='16px'
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography
              variant='subtitle1'
              sx={{ color: 'primary.main' }}
            >
              SWP Scheme
            </Typography>
            <CardMedia
              sx={{
                '&.MuiCardMedia-root': {
                  width: '12%',
                },
              }}
              component='img'
              image={AmountIcon}
              alt='amount'
            />
          </Stack>
          {schemaData?.schemeDesc && (
            <Typography
              variant='subtitle2'
              sx={{ color: 'text.valueColor' }}
            >
              {schemaData?.schemeDesc || 'NA'}
            </Typography>
          )}
          {schemaData?.folio && (
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Folio Number</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {schemaData?.folio || 'NA'}
              </Typography>
            </Stack>
          )}
          <Stack gap='5px'>
            <Typography sx={{ color: 'text.labelColor' }}>Total Balance Units</Typography>
            <Typography
              variant='subtitle2'
              sx={{ color: 'text.valueColor' }}
            >
              {schemaData?.balanceUnits || '0'}
            </Typography>
          </Stack>
          {schemaData?.availableAmount && (
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>
                Total Available Amount
              </Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {schemaData?.availableAmount || 'NA'}
              </Typography>
            </Stack>
          )}
          {
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>
                Exist load by fund house
              </Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {schemaData?.exitLoad || 'Nil'}
              </Typography>
            </Stack>
          }
        </Stack>
      </Box>
    );
  };

  return (
    <Layout
      title='Investment Details'
      subtitle='Please enter the investment details'
      rightBottomComponent={<RightBottomComp schemaData={state?.schemeDetails} />}
    >
      {/* Mobile UI Investor Profile  */}
      <MobileInvestorProfile
        commercialTransactionTitle='SWP'
        data={investorDetails ?? {}}
      />
      {/* Mobile UI Investor Profile  */}
      <Typography
        variant='subtitle1'
        sx={{ color: 'primary.main', mb: 2, display: { xs: 'block', sm: 'none' } }}
      >
        Please enter the investment details
      </Typography>

      <Investorcard
        sx={theme => ({
          padding: '30px',
          [theme.breakpoints.down('md')]: {
            border: 'unset',
            boxShadow: 'unset',
            padding: '0px',
          },
        })}
      >
        <InvestmentDetailsForm
          data={investorDetails ?? []}
          isEditMode={false}
          isFromCart={state.isFromcart}
        />
      </Investorcard>
    </Layout>
  );
}
