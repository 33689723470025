import { ReactComponent as AxisMf } from '../mfcentral-logos/fav-logos/axis_MF.svg';
import { ReactComponent as BajajMf } from '../mfcentral-logos/fav-logos/bajaj_MF.svg';
import { ReactComponent as BnpMf } from '../mfcentral-logos/fav-logos/bnp_MF.svg';
import { ReactComponent as BoiMf } from '../mfcentral-logos/fav-logos/boi_MF.svg';
import { ReactComponent as CanaraMF } from '../mfcentral-logos/fav-logos/canara_MF.svg';
import { ReactComponent as defautSvg } from '../mfcentral-logos/fav-logos/default.svg';
import { ReactComponent as EdelweissMf } from '../mfcentral-logos/fav-logos/edelweiss_MF.svg';
import { ReactComponent as GrowwMf } from '../mfcentral-logos/fav-logos/groww_new.svg';
import { ReactComponent as InvescoMf } from '../mfcentral-logos/fav-logos/invesco_MF.svg';
import { ReactComponent as ItiMf } from '../mfcentral-logos/fav-logos/iti_MF.svg';
import { ReactComponent as JmMF } from '../mfcentral-logos/fav-logos/jm_MF.svg';
import { ReactComponent as LicMF } from '../mfcentral-logos/fav-logos/lic_MF.svg';
import { ReactComponent as MiraiMf } from '../mfcentral-logos/fav-logos/mirae_MF.svg';
import { ReactComponent as MotilalMf } from '../mfcentral-logos/fav-logos/motilal_MF.svg';
import { ReactComponent as NaviMf } from '../mfcentral-logos/fav-logos/navi_MF.svg';
import { ReactComponent as NipponMf } from '../mfcentral-logos/fav-logos/nippon_MF Central.svg';
import { ReactComponent as NjMf } from '../mfcentral-logos/fav-logos/NJ_MF.svg';
import { ReactComponent as OldBridgeMf } from '../mfcentral-logos/fav-logos/old_bridge_mf.svg';
import { ReactComponent as PgimMf } from '../mfcentral-logos/fav-logos/PGIM_India_MF.svg';
import { ReactComponent as QuantMf } from '../mfcentral-logos/fav-logos/quant_MF.svg';
import { ReactComponent as QuantumMf } from '../mfcentral-logos/fav-logos/quantum_MF.svg';
import { ReactComponent as SamcoMf } from '../mfcentral-logos/fav-logos/samco_MF.svg';
import { ReactComponent as SundaramIcon } from '../mfcentral-logos/fav-logos/sundaram_MF.svg';
import { ReactComponent as TaurasMF } from '../mfcentral-logos/fav-logos/tauras_MF.svg';
import { ReactComponent as TrustMf } from '../mfcentral-logos/fav-logos/trust_MF.svg';
import { ReactComponent as UtiMf } from '../mfcentral-logos/fav-logos/uti_MF.svg';

export const amcWithLogos = [
  {
    amc_code: 101,
    amc_name: 'Canara Robeco Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: CanaraMF,
  },
  {
    amc_code: 102,
    amc_name: 'LIC MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: LicMF,
  },
  {
    amc_code: 104,
    amc_name: 'TAURUS MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: TaurasMF,
  },
  {
    amc_code: 105,
    amc_name: 'JM Financial Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: JmMF,
  },
  {
    amc_code: 108,
    amc_name: 'UTI MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: UtiMf,
  },
  {
    amc_code: 116,
    amc_name: 'BANK OF INDIA MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: BoiMf,
  },
  {
    amc_code: 117,
    amc_name: 'Mirae Asset Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: MiraiMf,
  },
  {
    amc_code: 118,
    amc_name: 'Edelweiss Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: EdelweissMf,
  },
  {
    amc_code: 120,
    amc_name: 'INVESCO MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: InvescoMf,
  },
  {
    amc_code: 123,
    amc_name: 'QUANTUM MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: QuantumMf,
  },
  {
    amc_code: 125,
    amc_name: 'GROWW MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: GrowwMf,
  },
  {
    amc_code: 127,
    amc_name: 'MOTILAL OSWAL MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: MotilalMf,
  },
  {
    amc_code: 128,
    amc_name: 'AXIS MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: AxisMf,
  },
  {
    amc_code: 129,
    amc_name: 'PGIM INDIA MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: PgimMf,
  },
  {
    amc_code: 130,
    amc_name: 'Navi Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: NaviMf,
  },
  {
    amc_code: 139,
    amc_name: 'Old Bridge Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: OldBridgeMf,
  },
  {
    amc_code: 152,
    amc_name: 'ITI MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: ItiMf,
  },
  {
    amc_code: 166,
    amc_name: 'Quant MF',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: QuantMf,
  },
  {
    amc_code: 176,
    amc_name: 'SUNDARAM MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: SundaramIcon,
  },
  {
    amc_code: 178,
    amc_name: 'Baroda BNP Paribas Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: BnpMf,
  },
  {
    amc_code: 181,
    amc_name: 'CAPITALMIND MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: defautSvg, //not available
  },
  {
    amc_code: 185,
    amc_name: 'TRUST MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: TrustMf,
  },
  {
    amc_code: 187,
    amc_name: 'NJ MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-12-23 0:00:00',
    icon: NjMf,
  },
  {
    amc_code: 188,
    amc_name: 'SAMCO MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-12-23 0:00:00',
    icon: SamcoMf,
  },
  {
    amc_code: 189,
    amc_name: 'Bajaj Finserv Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2023-07-27 0:00:00',
    icon: BajajMf,
  },
  {
    amc_code: 'RMF',
    amc_name: 'NIPPON INDIA MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: NipponMf,
  },
];
